/* global google */
import React, { useEffect, useRef, useState, useTransition } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import Logo from "../images/logo.svg";
import UserIcon from "../images/usericon.svg";
import const_config from "../../utility/config/const_config";

const Header = () => {
  const currentpath = window.location.pathname;
  const Paths = const_config.show_header_footer_paths;
  const hideHeader = const_config.hideHeader;
  const location = useLocation();
  const [url, setUrl] = useState(null);
  const googleElm = useRef();
  const [isLogin, setIsLogin] = useState(false)

  useEffect(() => {
    isShowHeader()
    setUrl(location.pathname);
  }, [location]);

  useEffect(() => {
    // console.log("first")
    // window.google && document.getElementById('google_translate_element').innerText('')

    googleElm.current = "";
    const addGoogleTranslateScript = () => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      document.body.appendChild(script);
    };

    const initializeGoogleTranslate = () => {
      if (
        window.google &&
        document.getElementById("google_translate_element")
      ) {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            includedLanguages: "en,fr",
            layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
          },
          "google_translate_element"
        );
      }
    };

    window.googleTranslateElementInit = initializeGoogleTranslate;

    
    if (!window.google || !window.google.translate) {
      addGoogleTranslateScript();
    } else {
      initializeGoogleTranslate();
      
    }

    // Cleanup function to remove script on unmount
    return () => {
      const googleTranslateScript = document.querySelector(
        "script[src*='translate.google.com']"
      );
      if (googleTranslateScript) {
        document.body.removeChild(googleTranslateScript);
      }
    };
  }, []); // Empty dependency array ensures this runs only once

  const isShowHeader = async () => {
    // let data = await localStorage.getItem("userDetail")
    if(hideHeader.includes(currentpath)){
      setIsLogin(true)
    }else{
      setIsLogin(false)
    }
    console.log(hideHeader,"data",currentpath)
  };

  return (
    <Navbar collapseOnSelect expand="lg" id="home" className={isLogin ? "d-none" : "bg-primary"}>
      <Container>
        <Navbar.Brand as={Link} to="/">
          <Image src={Logo} width="160" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto newNav">
            <Nav.Link as={Link} to="/user_login">
              <Image src={UserIcon} width="20" /> Login
            </Nav.Link>
            {/* Google Translate Dropdown */}
            {/* <div id="google_translate_element" ref={googleElm}></div> */}
          </Nav>
          <Nav className="ms-auto">
            <Nav.Link className={url === "/" ? " active" : ""} as={Link} to="/">
              Home
            </Nav.Link>

            <Nav.Link
              className={url === "/solutions" ? " active" : ""}
              as={Link}
              to="/solutions"
            >
              Solutions
            </Nav.Link>

            <Nav.Link
              className={url === "/web_tracking" ? " active" : ""}
              as={Link}
              to="/web_tracking"
            >
              Tracking
            </Nav.Link>

            <Nav.Link
              className={url === "/contact" ? " active" : ""}
              as={Link}
              to="/contact"
            >
              Contact
            </Nav.Link>

            <div className="btnBox">
              <Button
                as={Link}
                to="/carriersignup"
                variant="primary"
                className="me-2 btnLight_Header"
              >
                Join as carrier
              </Button>
              <Button
                as={Link}
                className="btnPrimary_Header"
                to="/usersignup"
                variant="primary"
              >
                Join as a User
              </Button>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
