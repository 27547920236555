import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../images/logo.svg";
import {
  BiLogoLinkedin,
  BiLogoFacebook,
  BiLogoInstagramAlt,
} from "react-icons/bi";
import const_config from "../../utility/config/const_config";

const Footer = () => {
  // const currentpath = window.location.hash.split("#")[1]
  //   ? window.location.hash.split("#")[1].toString().trim()
  //   : "";
  const currentpath = window.location.pathname;
  const Paths = const_config.show_header_footer_paths;
  const isShowHeader = () => {
    const condition = Paths.includes(currentpath);
    return condition;
  };

  const navigate = useNavigate();
  return (
    <>
      {isShowHeader() && (
        <section className="footer">
          <Container>
            <Row>
              <Col lg={3} md={4} sm={4}>
                <div className="footertop_div">
                  <Image src={Logo} className="footerlogo" />
                  <ul className="footersocial mt-4">
                    <li>
                      <Link>
                        <BiLogoFacebook />
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <BiLogoInstagramAlt />
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <BiLogoLinkedin />
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={3} md={4} sm={4}>
                <h3>Contact</h3>
                <ul className="footerlink">
                  <li>
                    <Link to="tel:7 56 92 42 91">Tel: +33 7 56 92 42 91</Link>
                  </li>
                  <li>contact@mottruck.com</li>
                  <li>
                    3 rue d'Enghien <br /> 75010, Paris - France
                  </li>
                </ul>
              </Col>
              <Col lg={3} md={4} sm={4}>
                <h3>Solutions</h3>
                <ul className="footerlink">
                  <li>
                    <Link
                      to="javascript:void(0)"
                      onClick={() => {
                        navigate("/solutions", {
                          state: { targetId: "section2" },
                        });
                      }}
                    >
                      Clients
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="javascript:void(0)"
                      onClick={() => {
                        navigate("/solutions", {
                          state: { targetId: "section3" },
                        });
                      }}
                    >
                      User
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="javascript:void(0)"
                      onClick={() => {
                        navigate("/solutions", {
                          state: { targetId: "section4" },
                        });
                      }}
                    >
                      Carrier
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col lg={3} md={4} sm={4}>
                <h3>Resources</h3>
                <ul className="footerlink">
                  <li>
                    <Link to="">Faq</Link>
                  </li>
                  <li>
                    <Link to="">Blog</Link>
                  </li>
                  <li>
                    <Link to={"privacy"}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={"terms"}>Terms & Conditions</Link>
                  </li>
                </ul>
              </Col>
              <Col md={12}>
                <div className="copyright">
                  <p>
                    Copyright © 2024 Mottruck | All Rights Reserved 
                
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default Footer;
