import React, { useContext, useEffect, useState } from "react";
import { Image, NavLink, Badge } from "react-bootstrap";
import Logo from "../images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HiUser } from "react-icons/hi2";
import { QuotesDataContext } from "../services/QuotesData";
import { BASE_URL } from "../services/env";
import { AppStoreContext } from "../../store/AppStore";
import consoleService from "../../utility/services/consoleService";

const SideBar = () => {
  const navigate = useNavigate();
  const { userDetail, setUserDetails } = useContext(AppStoreContext);
  const {
    profile,
    accountOnwerLastName,
    accountOnwerFirstName,
    contact_first_name,
    contact_last_name,
    companyName,
    pic,
    firstName,
    lastName,
  } = userDetail;
  const userRole = profile;
  const [user_name, setUserName] = useState("");
  const [logoutUrl, setLogoutUrl] = useState("");
  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  const isRoleUser = userRole == "User";
  const isRoleCarrier = userRole == "Carrier";
  const isRoleClient = userRole == "Client";
  const isRoleAdmin = userRole == "Admin";
  const isRoleStaff = userRole == "Staff";

  const handleLogoutClick = () => {
    localStorage.removeItem("userDetail");
    localStorage.removeItem("access");
    // window.location.href = "/";
    setUserDetails({});
  };

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);
  useEffect(() => {
    setUserNameFun();
    setLogoutUrlFun();
  }, [userDetail]);

  function setLogoutUrlFun() {
    if (isRoleAdmin) {
      setLogoutUrl("/login");
    } else if (isRoleStaff) {
      setLogoutUrl("/staffLogin");
    } else {
      setLogoutUrl("/");
    }
  }

  function setUserNameFun() {
    if (isRoleUser) {
      setUserName(`${contact_first_name} ${contact_last_name} `);
    } else if (isRoleStaff) {
      setUserName(`${firstName} ${lastName}`);
    } else {
      setUserName(companyName);
    }
  }

  // const {accountDetail, setAccountDetail} = useContext(QuotesDataContext)
  // console.log("side bar accountDetail ", accountDetail.firstName)

  // console.log("userDeatildata, userDeatildata ===  >", userDeatildata);

  // console.log('INDIITPIC',_id)
  return (
    <div className="bg-colorsidebar" id="sidebar-wrapper">
      <div className="logo">
        <Image src={Logo} alt="" className="img-fluid" />
      </div>

      <div className="list-group">
        <NavLink
          className={`userInfo ${url === `/account` ? " active" : ""}`}
          as={Link}
          to={`/account`}
        >
          <span>
            {pic && pic.length > 0 ? (
              <Image
                src={BASE_URL + "server/" + pic[0]}
                alt=""
                className="img-fluid"
              />
            ) : (
              <HiUser size={32} />
            )}
          </span>
          <h4>{user_name}</h4>
        </NavLink>

        {isRoleUser || isRoleClient || isRoleCarrier ? (
          ""
        ) : (
          <h5 className="labelHeading">MANAGEMENT</h5>
        )}
        {isRoleUser || isRoleClient || isRoleCarrier ? (
          ""
        ) : (
          <NavLink
            className={url === `/dashboard` ? " active" : ""}
            as={Link}
            to={`/dashboard`}
          >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M6.58051 19.8543H2.87717C1.03467 19.8543 0.145508 19.0018 0.145508 17.2418V2.7585C0.145508 0.998496 1.04384 0.145996 2.87717 0.145996H6.58051C8.42301 0.145996 9.31217 0.998496 9.31217 2.7585V17.2418C9.31217 19.0018 8.41384 19.8543 6.58051 19.8543ZM2.87717 1.521C1.71301 1.521 1.52051 1.83266 1.52051 2.7585V17.2418C1.52051 18.1677 1.71301 18.4793 2.87717 18.4793H6.58051C7.74468 18.4793 7.93717 18.1677 7.93717 17.2418V2.7585C7.93717 1.83266 7.74468 1.521 6.58051 1.521H2.87717V1.521Z"
                fill="#D8DEF5"
              />
              <path
                d="M17.1225 11.6043H13.4192C11.5767 11.6043 10.6875 10.7518 10.6875 8.99183V2.7585C10.6875 0.998496 11.5858 0.145996 13.4192 0.145996H17.1225C18.965 0.145996 19.8542 0.998496 19.8542 2.7585V8.99183C19.8542 10.7518 18.9558 11.6043 17.1225 11.6043ZM13.4192 1.521C12.255 1.521 12.0625 1.83266 12.0625 2.7585V8.99183C12.0625 9.91766 12.255 10.2293 13.4192 10.2293H17.1225C18.2867 10.2293 18.4792 9.91766 18.4792 8.99183V2.7585C18.4792 1.83266 18.2867 1.521 17.1225 1.521H13.4192V1.521Z"
                fill="#D8DEF5"
              />
              <path
                d="M17.1225 19.854H13.4192C11.5767 19.854 10.6875 19.0015 10.6875 17.2415V15.5915C10.6875 13.8315 11.5858 12.979 13.4192 12.979H17.1225C18.965 12.979 19.8542 13.8315 19.8542 15.5915V17.2415C19.8542 19.0015 18.9558 19.854 17.1225 19.854ZM13.4192 14.354C12.255 14.354 12.0625 14.6657 12.0625 15.5915V17.2415C12.0625 18.1673 12.255 18.479 13.4192 18.479H17.1225C18.2867 18.479 18.4792 18.1673 18.4792 17.2415V15.5915C18.4792 14.6657 18.2867 14.354 17.1225 14.354H13.4192Z"
                fill="#D8DEF5"
              />
            </svg>

            <span>Dashboard</span>
          </NavLink>
        )}

        {isRoleAdmin && (
          <NavLink
            className={url === `/team` ? " active" : ""}
            as={Link}
            to={`/team`}
          >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M7.39635 9.65183C7.36885 9.65183 7.35052 9.65183 7.32302 9.65183C7.27719 9.64266 7.21302 9.64266 7.15802 9.65183C4.49969 9.56933 2.49219 7.47933 2.49219 4.9035C2.49219 2.28183 4.62802 0.145996 7.24969 0.145996C9.87135 0.145996 12.0072 2.28183 12.0072 4.9035C11.998 7.47933 9.98135 9.56933 7.42385 9.65183C7.41469 9.65183 7.40552 9.65183 7.39635 9.65183ZM7.24969 1.521C5.38885 1.521 3.86719 3.04266 3.86719 4.9035C3.86719 6.73683 5.29719 8.21266 7.12135 8.27683C7.17635 8.26766 7.29552 8.26766 7.41469 8.27683C9.21135 8.19433 10.623 6.7185 10.6322 4.9035C10.6322 3.04266 9.11052 1.521 7.24969 1.521Z"
                fill="#D8DEF5"
              />
              <path
                d="M14.1612 9.77067C14.1337 9.77067 14.1062 9.77067 14.0787 9.7615C13.7028 9.79817 13.3178 9.53234 13.2812 9.1565C13.2445 8.78067 13.4737 8.4415 13.8495 8.39567C13.9595 8.3865 14.0787 8.3865 14.1795 8.3865C15.5178 8.31317 16.5628 7.21317 16.5628 5.86567C16.5628 4.47234 15.4353 3.34484 14.042 3.34484C13.6662 3.354 13.3545 3.04234 13.3545 2.6665C13.3545 2.29067 13.6662 1.979 14.042 1.979C16.187 1.979 17.9378 3.72984 17.9378 5.87484C17.9378 7.98317 16.2878 9.68817 14.1887 9.77067C14.1795 9.77067 14.1703 9.77067 14.1612 9.77067Z"
                fill="#D8DEF5"
              />
              <path
                d="M7.40596 19.6707C5.6093 19.6707 3.80346 19.2123 2.43763 18.2957C1.16346 17.4523 0.466797 16.2973 0.466797 15.0415C0.466797 13.7857 1.16346 12.6215 2.43763 11.769C5.18763 9.94484 9.64263 9.94484 12.3743 11.769C13.6393 12.6123 14.3451 13.7673 14.3451 15.0232C14.3451 16.279 13.6485 17.4432 12.3743 18.2957C10.9993 19.2123 9.20263 19.6707 7.40596 19.6707ZM3.19846 12.924C2.31846 13.5107 1.8418 14.2623 1.8418 15.0507C1.8418 15.8298 2.32763 16.5815 3.19846 17.159C5.48096 18.6898 9.33096 18.6898 11.6135 17.159C12.4935 16.5723 12.9701 15.8207 12.9701 15.0323C12.9701 14.2532 12.4843 13.5015 11.6135 12.924C9.33096 11.4023 5.48096 11.4023 3.19846 12.924Z"
                fill="#D8DEF5"
              />
              <path
                d="M15.8113 18.0208C15.4905 18.0208 15.2063 17.8008 15.1422 17.4708C15.0688 17.095 15.3072 16.7375 15.6738 16.655C16.2513 16.5358 16.783 16.3067 17.1955 15.9859C17.718 15.5917 18.0022 15.0967 18.0022 14.5742C18.0022 14.0517 17.718 13.5567 17.2047 13.1717C16.8013 12.86 16.2972 12.64 15.7013 12.5025C15.3347 12.42 15.0963 12.0534 15.1788 11.6775C15.2613 11.3109 15.628 11.0725 16.0038 11.155C16.7922 11.3292 17.4797 11.6409 18.0388 12.0717C18.8913 12.7134 19.3772 13.6208 19.3772 14.5742C19.3772 15.5275 18.8822 16.435 18.0297 17.0858C17.4613 17.5258 16.7463 17.8467 15.958 18.0025C15.903 18.0208 15.8572 18.0208 15.8113 18.0208Z"
                fill="#D8DEF5"
              />
            </svg>

            <span>Team</span>
          </NavLink>
        )}

        {(isRoleStaff || isRoleUser) && (
          <NavLink
            className={url === `/quotes` ? " active" : ""}
            as={Link}
            to={`/quotes`}
          >
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none">
              <path
                d="M12.6666 19.8543H5.33329C2.56496 19.8543 0.979126 18.2685 0.979126 15.5002V6.56266C0.979126 3.67516 2.44579 2.2085 5.33329 2.2085C5.70913 2.2085 6.02079 2.52016 6.02079 2.896C6.02079 3.26266 6.16746 3.61099 6.42413 3.86766C6.6808 4.12433 7.02913 4.271 7.39579 4.271H10.6041C11.365 4.271 11.9791 3.65683 11.9791 2.896C11.9791 2.52016 12.2908 2.2085 12.6666 2.2085C15.5541 2.2085 17.0208 3.67516 17.0208 6.56266V15.5002C17.0208 18.2685 15.435 19.8543 12.6666 19.8543ZM4.73744 3.60183C3.2891 3.721 2.35413 4.37183 2.35413 6.56266V15.5002C2.35413 17.5352 3.29829 18.4793 5.33329 18.4793H12.6666C14.7016 18.4793 15.6458 17.5352 15.6458 15.5002V6.56266C15.6458 4.37183 14.7108 3.73017 13.2625 3.60183C12.9508 4.77517 11.8783 5.646 10.6041 5.646H7.39579C6.66246 5.646 5.97496 5.36182 5.45246 4.83932C5.10413 4.49099 4.86577 4.06933 4.73744 3.60183Z"
                fill="#1D244A"
              />
              <path
                d="M10.6042 5.646H7.39587C6.66254 5.646 5.97505 5.36183 5.45255 4.83933C4.93005 4.31683 4.64587 3.62933 4.64587 2.896C4.64587 1.3835 5.88337 0.145996 7.39587 0.145996H10.6042C11.3375 0.145996 12.025 0.430167 12.5475 0.952667C13.07 1.47517 13.3542 2.16266 13.3542 2.896C13.3542 4.4085 12.1167 5.646 10.6042 5.646ZM7.39587 1.521C6.63504 1.521 6.02087 2.13516 6.02087 2.896C6.02087 3.26266 6.16754 3.61099 6.42421 3.86766C6.68088 4.12433 7.02921 4.271 7.39587 4.271H10.6042C11.365 4.271 11.9792 3.65683 11.9792 2.896C11.9792 2.52933 11.8325 2.181 11.5759 1.92433C11.3192 1.66767 10.9709 1.521 10.6042 1.521H7.39587Z"
                fill="#1D244A"
              />
              <path
                d="M9.00004 11.604H5.33337C4.95754 11.604 4.64587 11.2923 4.64587 10.9165C4.64587 10.5407 4.95754 10.229 5.33337 10.229H9.00004C9.37587 10.229 9.68754 10.5407 9.68754 10.9165C9.68754 11.2923 9.37587 11.604 9.00004 11.604Z"
                fill="#1D244A"
              />
              <path
                d="M12.6667 15.271H5.33337C4.95754 15.271 4.64587 14.9593 4.64587 14.5835C4.64587 14.2077 4.95754 13.896 5.33337 13.896H12.6667C13.0425 13.896 13.3542 14.2077 13.3542 14.5835C13.3542 14.9593 13.0425 15.271 12.6667 15.271Z"
                fill="#1D244A"
              />
            </svg>

            <span>Quotes</span>
          </NavLink>
        )}
        {isRoleCarrier && (
          <NavLink
            className={url === `/carrier_quotes` ? " active" : ""}
            as={Link}
            to={`/carrier_quotes`}
          >
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none">
              <path
                d="M12.6666 19.8543H5.33329C2.56496 19.8543 0.979126 18.2685 0.979126 15.5002V6.56266C0.979126 3.67516 2.44579 2.2085 5.33329 2.2085C5.70913 2.2085 6.02079 2.52016 6.02079 2.896C6.02079 3.26266 6.16746 3.61099 6.42413 3.86766C6.6808 4.12433 7.02913 4.271 7.39579 4.271H10.6041C11.365 4.271 11.9791 3.65683 11.9791 2.896C11.9791 2.52016 12.2908 2.2085 12.6666 2.2085C15.5541 2.2085 17.0208 3.67516 17.0208 6.56266V15.5002C17.0208 18.2685 15.435 19.8543 12.6666 19.8543ZM4.73744 3.60183C3.2891 3.721 2.35413 4.37183 2.35413 6.56266V15.5002C2.35413 17.5352 3.29829 18.4793 5.33329 18.4793H12.6666C14.7016 18.4793 15.6458 17.5352 15.6458 15.5002V6.56266C15.6458 4.37183 14.7108 3.73017 13.2625 3.60183C12.9508 4.77517 11.8783 5.646 10.6041 5.646H7.39579C6.66246 5.646 5.97496 5.36182 5.45246 4.83932C5.10413 4.49099 4.86577 4.06933 4.73744 3.60183Z"
                fill="#1D244A"
              />
              <path
                d="M10.6042 5.646H7.39587C6.66254 5.646 5.97505 5.36183 5.45255 4.83933C4.93005 4.31683 4.64587 3.62933 4.64587 2.896C4.64587 1.3835 5.88337 0.145996 7.39587 0.145996H10.6042C11.3375 0.145996 12.025 0.430167 12.5475 0.952667C13.07 1.47517 13.3542 2.16266 13.3542 2.896C13.3542 4.4085 12.1167 5.646 10.6042 5.646ZM7.39587 1.521C6.63504 1.521 6.02087 2.13516 6.02087 2.896C6.02087 3.26266 6.16754 3.61099 6.42421 3.86766C6.68088 4.12433 7.02921 4.271 7.39587 4.271H10.6042C11.365 4.271 11.9792 3.65683 11.9792 2.896C11.9792 2.52933 11.8325 2.181 11.5759 1.92433C11.3192 1.66767 10.9709 1.521 10.6042 1.521H7.39587Z"
                fill="#1D244A"
              />
              <path
                d="M9.00004 11.604H5.33337C4.95754 11.604 4.64587 11.2923 4.64587 10.9165C4.64587 10.5407 4.95754 10.229 5.33337 10.229H9.00004C9.37587 10.229 9.68754 10.5407 9.68754 10.9165C9.68754 11.2923 9.37587 11.604 9.00004 11.604Z"
                fill="#1D244A"
              />
              <path
                d="M12.6667 15.271H5.33337C4.95754 15.271 4.64587 14.9593 4.64587 14.5835C4.64587 14.2077 4.95754 13.896 5.33337 13.896H12.6667C13.0425 13.896 13.3542 14.2077 13.3542 14.5835C13.3542 14.9593 13.0425 15.271 12.6667 15.271Z"
                fill="#1D244A"
              />
            </svg>
            <span>Quotes</span>
          </NavLink>
        )}

        {(isRoleStaff || isRoleUser || isRoleClient || isRoleCarrier) && (
          <NavLink
            className={url === `/orders` ? " active" : ""}
            as={Link}
            to={`/orders`}
          >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M9.99982 11.1916C9.88065 11.1916 9.76148 11.1641 9.65148 11.1L1.55732 6.41582C1.22732 6.22332 1.11731 5.80163 1.30981 5.47163C1.50231 5.14163 1.9148 5.03161 2.25397 5.22411L9.99982 9.70663L17.6998 5.25164C18.0298 5.05914 18.4515 5.17832 18.644 5.49916C18.8365 5.82916 18.7173 6.25079 18.3965 6.44329L10.3573 11.1C10.2382 11.155 10.119 11.1916 9.99982 11.1916Z"
                fill="#D8DEF5"
              />
              <path
                d="M10 19.4968C9.62417 19.4968 9.3125 19.1851 9.3125 18.8093V10.4951C9.3125 10.1193 9.62417 9.80762 10 9.80762C10.3758 9.80762 10.6875 10.1193 10.6875 10.4951V18.8093C10.6875 19.1851 10.3758 19.4968 10 19.4968Z"
                fill="#D8DEF5"
              />
              <path
                d="M10.0001 19.8543C9.1934 19.8543 8.3959 19.6801 7.7634 19.3318L2.86841 16.6093C1.53924 15.876 0.503418 14.1068 0.503418 12.5852V7.40597C0.503418 5.88431 1.53924 4.12435 2.86841 3.38185L7.7634 0.668496C9.01924 -0.0281706 10.9809 -0.0281706 12.2367 0.668496L17.1317 3.39097C18.4609 4.1243 19.4967 5.89348 19.4967 7.41515V12.5943C19.4967 14.116 18.4609 15.876 17.1317 16.6185L12.2367 19.3318C11.6042 19.6801 10.8067 19.8543 10.0001 19.8543ZM10.0001 1.52099C9.42257 1.52099 8.85425 1.64016 8.43258 1.86933L3.53758 4.5918C2.65758 5.0868 1.87842 6.39765 1.87842 7.41515V12.5943C1.87842 13.6027 2.65758 14.9226 3.53758 15.4176L8.43258 18.1402C9.26675 18.6077 10.7334 18.6077 11.5676 18.1402L16.4626 15.4176C17.3426 14.9226 18.1217 13.6118 18.1217 12.5943V7.41515C18.1217 6.40682 17.3426 5.0868 16.4626 4.5918L11.5676 1.86933C11.1459 1.64016 10.5776 1.52099 10.0001 1.52099Z"
                fill="#D8DEF5"
              />
              <path
                d="M14.5835 11.824C14.2076 11.824 13.896 11.5123 13.896 11.1365V8.18487L5.53596 3.36319C5.20596 3.17069 5.09594 2.74901 5.28844 2.42817C5.48094 2.09817 5.89346 1.98816 6.22346 2.18066L14.9226 7.20403C15.1335 7.32319 15.271 7.55233 15.271 7.79983V11.1549C15.271 11.5124 14.9593 11.824 14.5835 11.824Z"
                fill="#D8DEF5"
              />
            </svg>
            <span>Orders</span>
            {/* <Badge className="badgeCus">1</Badge> */}
          </NavLink>
        )}

        {(isRoleStaff || isRoleUser || isRoleClient || isRoleCarrier) && (
          <NavLink
            className={url === `/tracking` ? " active" : ""}
            as={Link}
            to={`/tracking`}
          >
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path
                d="M3.59163 4.41667C3.1333 4.41667 2.7583 4.04167 2.7583 3.58333C2.7583 3.125 3.12497 2.75 3.59163 2.75H3.59997C4.0583 2.75 4.4333 3.125 4.4333 3.58333C4.4333 4.04167 4.0583 4.41667 3.59163 4.41667Z"
                fill="#D8DEF5"
              />
              <path
                d="M14.425 15.2502C13.9667 15.2502 13.5917 14.8752 13.5917 14.4168C13.5917 13.9585 13.9583 13.5835 14.425 13.5835H14.4333C14.8917 13.5835 15.2667 13.9585 15.2667 14.4168C15.2667 14.8752 14.8917 15.2502 14.425 15.2502Z"
                fill="#D8DEF5"
              />
              <path
                d="M3.55839 7.12484C1.60839 7.12484 0.0167236 5.53317 0.0167236 3.58317C0.0167236 1.63317 1.60839 0.0415039 3.55839 0.0415039C5.50839 0.0415039 7.10006 1.63317 7.10006 3.58317C7.10006 5.53317 5.51672 7.12484 3.55839 7.12484ZM3.55839 1.2915C2.29172 1.2915 1.26672 2.3165 1.26672 3.58317C1.26672 4.84984 2.29172 5.87484 3.55839 5.87484C4.82506 5.87484 5.85006 4.84984 5.85006 3.58317C5.85006 2.3165 4.82506 1.2915 3.55839 1.2915Z"
                fill="#D8DEF5"
              />
              <path
                d="M15.6416 17.9583H13.1416C11.875 17.9583 10.85 16.9333 10.85 15.6667V13.1667C10.85 11.9 11.875 10.875 13.1416 10.875H15.6416C16.9083 10.875 17.9333 11.9 17.9333 13.1667V15.6667C17.9333 16.9333 16.9083 17.9583 15.6416 17.9583ZM13.1416 12.125C12.5666 12.125 12.1 12.5917 12.1 13.1667V15.6667C12.1 16.2417 12.5666 16.7083 13.1416 16.7083H15.6416C16.2166 16.7083 16.6833 16.2417 16.6833 15.6667V13.1667C16.6833 12.5917 16.2166 12.125 15.6416 12.125H13.1416Z"
                fill="#D8DEF5"
              />
              <path
                d="M9.00003 15.4582H6.7667C5.80003 15.4582 4.95837 14.8748 4.62503 13.9748C4.28337 13.0748 4.53337 12.0832 5.25837 11.4415L11.9167 5.6165C12.3167 5.2665 12.325 4.7915 12.2084 4.4665C12.0834 4.1415 11.7667 3.7915 11.2334 3.7915H9.00003C8.65837 3.7915 8.37503 3.50817 8.37503 3.1665C8.37503 2.82484 8.65837 2.5415 9.00003 2.5415H11.2334C12.2 2.5415 13.0417 3.12484 13.375 4.02484C13.7167 4.92484 13.4667 5.9165 12.7417 6.55817L6.08337 12.3832C5.68337 12.7332 5.67503 13.2082 5.7917 13.5332C5.9167 13.8582 6.23337 14.2082 6.7667 14.2082H9.00003C9.3417 14.2082 9.62503 14.4915 9.62503 14.8332C9.62503 15.1748 9.3417 15.4582 9.00003 15.4582Z"
                fill="#D8DEF5"
              />
            </svg>

            <span>Tracking</span>
          </NavLink>
        )}
        {isRoleCarrier && (
          <NavLink
            className={
              url === `${process.env.PUBLIC_URL}/availabilities`
                ? " active"
                : ""
            }
            as={Link}
            to={`/availabilities`}
          >
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path
                d="M7.33333 5.27148C6.9575 5.27148 6.64583 4.95982 6.64583 4.58398V1.83398C6.64583 1.45815 6.9575 1.14648 7.33333 1.14648C7.70916 1.14648 8.02083 1.45815 8.02083 1.83398V4.58398C8.02083 4.95982 7.70916 5.27148 7.33333 5.27148Z"
                fill="#D8DEF5"
              />
              <path
                d="M14.6667 5.27148C14.2908 5.27148 13.9792 4.95982 13.9792 4.58398V1.83398C13.9792 1.45815 14.2908 1.14648 14.6667 1.14648C15.0425 1.14648 15.3542 1.45815 15.3542 1.83398V4.58398C15.3542 4.95982 15.0425 5.27148 14.6667 5.27148Z"
                fill="#D8DEF5"
              />
              <path
                d="M7.79167 13.2911C7.6725 13.2911 7.55333 13.2637 7.44333 13.2178C7.32417 13.172 7.2325 13.1078 7.14083 13.0253C6.97583 12.8511 6.875 12.622 6.875 12.3745C6.875 12.2553 6.9025 12.1361 6.94833 12.0261C6.99417 11.9161 7.05833 11.8153 7.14083 11.7236C7.2325 11.6411 7.32417 11.577 7.44333 11.5311C7.77333 11.3936 8.18583 11.467 8.4425 11.7236C8.6075 11.8978 8.70833 12.1361 8.70833 12.3745C8.70833 12.4295 8.69917 12.4936 8.69 12.5578C8.68083 12.6128 8.6625 12.6678 8.635 12.7228C8.61666 12.7778 8.58917 12.8328 8.5525 12.8878C8.525 12.9336 8.47917 12.9795 8.4425 13.0253C8.26833 13.1903 8.03 13.2911 7.79167 13.2911Z"
                fill="#D8DEF5"
              />
              <path
                d="M11 13.2913C10.8808 13.2913 10.7617 13.2638 10.6517 13.218C10.5325 13.1722 10.4408 13.108 10.3492 13.0255C10.1842 12.8513 10.0833 12.6221 10.0833 12.3746C10.0833 12.2555 10.1108 12.1363 10.1567 12.0263C10.2025 11.9163 10.2667 11.8155 10.3492 11.7238C10.4408 11.6413 10.5325 11.5771 10.6517 11.5313C10.9817 11.3846 11.3942 11.4671 11.6508 11.7238C11.8158 11.898 11.9167 12.1363 11.9167 12.3746C11.9167 12.4296 11.9075 12.4938 11.8983 12.558C11.8892 12.613 11.8708 12.668 11.8433 12.723C11.825 12.778 11.7975 12.833 11.7608 12.888C11.7333 12.9338 11.6875 12.9796 11.6508 13.0255C11.4767 13.1905 11.2383 13.2913 11 13.2913Z"
                fill="#D8DEF5"
              />
              <path
                d="M14.2083 13.2913C14.0892 13.2913 13.97 13.2638 13.86 13.218C13.7408 13.1722 13.6492 13.108 13.5575 13.0255C13.5208 12.9796 13.4842 12.9338 13.4475 12.888C13.4108 12.833 13.3833 12.778 13.365 12.723C13.3375 12.668 13.3192 12.613 13.31 12.558C13.3008 12.4938 13.2917 12.4296 13.2917 12.3746C13.2917 12.1363 13.3925 11.898 13.5575 11.7238C13.6492 11.6413 13.7408 11.5771 13.86 11.5313C14.1992 11.3846 14.6025 11.4671 14.8592 11.7238C15.0242 11.898 15.125 12.1363 15.125 12.3746C15.125 12.4296 15.1158 12.4938 15.1067 12.558C15.0975 12.613 15.0792 12.668 15.0517 12.723C15.0333 12.778 15.0058 12.833 14.9692 12.888C14.9417 12.9338 14.8958 12.9796 14.8592 13.0255C14.685 13.1905 14.4467 13.2913 14.2083 13.2913Z"
                fill="#D8DEF5"
              />
              <path
                d="M7.79167 16.4996C7.6725 16.4996 7.55333 16.4721 7.44333 16.4263C7.33333 16.3804 7.2325 16.3163 7.14083 16.2338C6.97583 16.0596 6.875 15.8213 6.875 15.5829C6.875 15.4638 6.9025 15.3446 6.94833 15.2346C6.99417 15.1154 7.05833 15.0146 7.14083 14.9321C7.48 14.5929 8.10333 14.5929 8.4425 14.9321C8.6075 15.1063 8.70833 15.3446 8.70833 15.5829C8.70833 15.8213 8.6075 16.0596 8.4425 16.2338C8.26833 16.3988 8.03 16.4996 7.79167 16.4996Z"
                fill="#D8DEF5"
              />
              <path
                d="M11 16.4996C10.7617 16.4996 10.5233 16.3988 10.3492 16.2338C10.1842 16.0596 10.0833 15.8213 10.0833 15.5829C10.0833 15.4638 10.1108 15.3446 10.1567 15.2346C10.2025 15.1154 10.2667 15.0146 10.3492 14.9321C10.6883 14.5929 11.3117 14.5929 11.6508 14.9321C11.7333 15.0146 11.7975 15.1154 11.8433 15.2346C11.8892 15.3446 11.9167 15.4638 11.9167 15.5829C11.9167 15.8213 11.8158 16.0596 11.6508 16.2338C11.4767 16.3988 11.2383 16.4996 11 16.4996Z"
                fill="#D8DEF5"
              />
              <path
                d="M14.2083 16.5002C13.97 16.5002 13.7317 16.3993 13.5575 16.2343C13.475 16.1518 13.4108 16.051 13.365 15.9318C13.3192 15.8218 13.2917 15.7027 13.2917 15.5835C13.2917 15.4643 13.3192 15.3452 13.365 15.2352C13.4108 15.116 13.475 15.0152 13.5575 14.9327C13.7683 14.7218 14.0892 14.621 14.3825 14.6852C14.4467 14.6943 14.5017 14.7127 14.5567 14.7402C14.6117 14.7585 14.6667 14.786 14.7217 14.8227C14.7675 14.8502 14.8133 14.896 14.8592 14.9327C15.0242 15.1068 15.125 15.3452 15.125 15.5835C15.125 15.8218 15.0242 16.0602 14.8592 16.2343C14.685 16.3993 14.4467 16.5002 14.2083 16.5002Z"
                fill="#D8DEF5"
              />
              <path
                d="M18.7917 9.01953H3.20833C2.8325 9.01953 2.52083 8.70786 2.52083 8.33203C2.52083 7.9562 2.8325 7.64453 3.20833 7.64453H18.7917C19.1675 7.64453 19.4792 7.9562 19.4792 8.33203C19.4792 8.70786 19.1675 9.01953 18.7917 9.01953Z"
                fill="#D8DEF5"
              />
              <path
                d="M14.6667 20.8548H7.33333C3.9875 20.8548 2.0625 18.9298 2.0625 15.584V7.79232C2.0625 4.44648 3.9875 2.52148 7.33333 2.52148H14.6667C18.0125 2.52148 19.9375 4.44648 19.9375 7.79232V15.584C19.9375 18.9298 18.0125 20.8548 14.6667 20.8548ZM7.33333 3.89648C4.71167 3.89648 3.4375 5.17065 3.4375 7.79232V15.584C3.4375 18.2057 4.71167 19.4798 7.33333 19.4798H14.6667C17.2883 19.4798 18.5625 18.2057 18.5625 15.584V7.79232C18.5625 5.17065 17.2883 3.89648 14.6667 3.89648H7.33333Z"
                fill="#D8DEF5"
              />
            </svg>

            <span>Availabilities</span>
          </NavLink>
        )}

        {isRoleUser || isRoleClient || isRoleCarrier ? (
          ""
        ) : (
          <>
            <h5 className="labelHeading mt-4">PARTNERS</h5>
            <NavLink
              className={url === `/carriers` ? " active" : ""}
              as={Link}
              to={`/carriers`}
            >
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path
                  d="M11.9998 13.75H10.9998C10.5898 13.75 10.2498 13.41 10.2498 13C10.2498 12.59 10.5898 12.25 10.9998 12.25H11.9998C12.6898 12.25 13.2498 11.69 13.2498 11V1.75H4.99978C3.81978 1.75 2.73975 2.38998 2.15975 3.41998C1.95975 3.77998 1.49979 3.91002 1.13979 3.71002C0.779792 3.51002 0.649753 3.05 0.849753 2.69C1.68975 1.19 3.27978 0.25 4.99978 0.25H13.9998C14.4098 0.25 14.7498 0.59 14.7498 1V11C14.7498 12.52 13.5198 13.75 11.9998 13.75Z"
                  fill="#D8DEF5"
                />
                <path
                  d="M18 19.75H17C16.59 19.75 16.25 19.41 16.25 19C16.25 18.31 15.69 17.75 15 17.75C14.31 17.75 13.75 18.31 13.75 19C13.75 19.41 13.41 19.75 13 19.75H9C8.59 19.75 8.25 19.41 8.25 19C8.25 18.31 7.69 17.75 7 17.75C6.31 17.75 5.75 18.31 5.75 19C5.75 19.41 5.41 19.75 5 19.75H4C1.93 19.75 0.25 18.07 0.25 16C0.25 15.59 0.59 15.25 1 15.25C1.41 15.25 1.75 15.59 1.75 16C1.75 17.24 2.76 18.25 4 18.25H4.34998C4.67998 17.1 5.74 16.25 7 16.25C8.26 16.25 9.32002 17.1 9.65002 18.25H12.36C12.69 17.1 13.75 16.25 15.01 16.25C16.27 16.25 17.33 17.1 17.66 18.25H18C19.24 18.25 20.25 17.24 20.25 16V13.75H18C17.04 13.75 16.25 12.96 16.25 12V9C16.25 8.04 17.03 7.25 18 7.25L16.93 5.38C16.71 4.99 16.29 4.75 15.84 4.75H14.75V11C14.75 12.52 13.52 13.75 12 13.75H11C10.59 13.75 10.25 13.41 10.25 13C10.25 12.59 10.59 12.25 11 12.25H12C12.69 12.25 13.25 11.69 13.25 11V4C13.25 3.59 13.59 3.25 14 3.25H15.84C16.83 3.25 17.74 3.78001 18.23 4.64001L19.94 7.63C20.07 7.86 20.07 8.15 19.94 8.38C19.81 8.61 19.56 8.75 19.29 8.75H18C17.86 8.75 17.75 8.86 17.75 9V12C17.75 12.14 17.86 12.25 18 12.25H21C21.41 12.25 21.75 12.59 21.75 13V16C21.75 18.07 20.07 19.75 18 19.75Z"
                  fill="#D8DEF5"
                />
                <path
                  d="M7 21.75C5.48 21.75 4.25 20.52 4.25 19C4.25 17.48 5.48 16.25 7 16.25C8.52 16.25 9.75 17.48 9.75 19C9.75 20.52 8.52 21.75 7 21.75ZM7 17.75C6.31 17.75 5.75 18.31 5.75 19C5.75 19.69 6.31 20.25 7 20.25C7.69 20.25 8.25 19.69 8.25 19C8.25 18.31 7.69 17.75 7 17.75Z"
                  fill="#D8DEF5"
                />
                <path
                  d="M15 21.75C13.48 21.75 12.25 20.52 12.25 19C12.25 17.48 13.48 16.25 15 16.25C16.52 16.25 17.75 17.48 17.75 19C17.75 20.52 16.52 21.75 15 21.75ZM15 17.75C14.31 17.75 13.75 18.31 13.75 19C13.75 19.69 14.31 20.25 15 20.25C15.69 20.25 16.25 19.69 16.25 19C16.25 18.31 15.69 17.75 15 17.75Z"
                  fill="#D8DEF5"
                />
                <path
                  d="M21 13.75H18C17.04 13.75 16.25 12.96 16.25 12V9C16.25 8.04 17.04 7.25 18 7.25H19.29C19.56 7.25 19.81 7.39 19.94 7.63L21.65 10.63C21.71 10.74 21.75 10.87 21.75 11V13C21.75 13.41 21.41 13.75 21 13.75ZM18 8.75C17.86 8.75 17.75 8.86 17.75 9V12C17.75 12.14 17.86 12.25 18 12.25H20.25V11.2L18.85 8.75H18Z"
                  fill="#D8DEF5"
                />
                <path
                  d="M7 7.75H1C0.59 7.75 0.25 7.41 0.25 7C0.25 6.59 0.59 6.25 1 6.25H7C7.41 6.25 7.75 6.59 7.75 7C7.75 7.41 7.41 7.75 7 7.75Z"
                  fill="#D8DEF5"
                />
                <path
                  d="M5 10.75H1C0.59 10.75 0.25 10.41 0.25 10C0.25 9.59 0.59 9.25 1 9.25H5C5.41 9.25 5.75 9.59 5.75 10C5.75 10.41 5.41 10.75 5 10.75Z"
                  fill="#D8DEF5"
                />
                <path
                  d="M3 13.75H1C0.59 13.75 0.25 13.41 0.25 13C0.25 12.59 0.59 12.25 1 12.25H3C3.41 12.25 3.75 12.59 3.75 13C3.75 13.41 3.41 13.75 3 13.75Z"
                  fill="#D8DEF5"
                />
              </svg>

              <span>Carriers</span>
            </NavLink>

            <NavLink
              className={url === `/clients` ? " active" : ""}
              as={Link}
              to={`/clients`}
            >
              <svg width="22" height="25" viewBox="0 0 20 20" fill="none">
                <path
                  d="M10.0005 19.8543C9.3863 19.8543 8.76297 19.6984 8.21297 19.3776L2.76797 16.2334C1.66797 15.5917 0.980469 14.4092 0.980469 13.1351V6.86511C0.980469 5.59094 1.66797 4.40843 2.76797 3.76677L8.21297 0.622607C9.31297 -0.0190593 10.6788 -0.0190593 11.788 0.622607L17.233 3.76677C18.333 4.40843 19.0205 5.59094 19.0205 6.86511V13.1351C19.0205 14.4092 18.333 15.5917 17.233 16.2334L11.788 19.3776C11.238 19.6984 10.6146 19.8543 10.0005 19.8543ZM10.0005 1.52092C9.62464 1.52092 9.23964 1.62176 8.90047 1.81426L3.45547 4.95842C2.77714 5.35259 2.35547 6.07677 2.35547 6.86511V13.1351C2.35547 13.9142 2.77714 14.6476 3.45547 15.0418L8.90047 18.1859C9.5788 18.5801 10.4221 18.5801 11.1005 18.1859L16.5455 15.0418C17.2238 14.6476 17.6455 13.9234 17.6455 13.1351V6.86511C17.6455 6.08594 17.2238 5.35259 16.5455 4.95842L11.1005 1.81426C10.7613 1.62176 10.3763 1.52092 10.0005 1.52092Z"
                  fill="#D8DEF5"
                />
                <path
                  d="M10.0001 9.77091C8.44176 9.77091 7.17676 8.50589 7.17676 6.94756C7.17676 5.38923 8.44176 4.12427 10.0001 4.12427C11.5584 4.12427 12.8234 5.38923 12.8234 6.94756C12.8234 8.50589 11.5584 9.77091 10.0001 9.77091ZM10.0001 5.49927C9.20259 5.49927 8.55176 6.15006 8.55176 6.94756C8.55176 7.74506 9.20259 8.39591 10.0001 8.39591C10.7976 8.39591 11.4484 7.74506 11.4484 6.94756C11.4484 6.15006 10.7976 5.49927 10.0001 5.49927Z"
                  fill="#D8DEF5"
                />
                <path
                  d="M13.6663 14.9592C13.2905 14.9592 12.9788 14.6476 12.9788 14.2717C12.9788 13.0067 11.6405 11.9709 9.99967 11.9709C8.35884 11.9709 7.02051 13.0067 7.02051 14.2717C7.02051 14.6476 6.70884 14.9592 6.33301 14.9592C5.95717 14.9592 5.64551 14.6476 5.64551 14.2717C5.64551 12.2459 7.59801 10.5959 9.99967 10.5959C12.4013 10.5959 14.3538 12.2459 14.3538 14.2717C14.3538 14.6476 14.0422 14.9592 13.6663 14.9592Z"
                  fill="#D8DEF5"
                />
              </svg>

              <span>Clients</span>
            </NavLink>

            <NavLink
              className={url === `/users` ? " active" : ""}
              as={Link}
              to={`/users`}
            >
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path
                  d="M11.1464 10.6516C11.1189 10.6516 11.1005 10.6516 11.073 10.6516C11.0272 10.6424 10.963 10.6424 10.908 10.6516C8.24969 10.5691 6.24219 8.47909 6.24219 5.90325C6.24219 3.28159 8.37802 1.14575 10.9997 1.14575C13.6214 1.14575 15.7572 3.28159 15.7572 5.90325C15.748 8.47909 13.7314 10.5691 11.1739 10.6516C11.1647 10.6516 11.1555 10.6516 11.1464 10.6516ZM10.9997 2.52075C9.13885 2.52075 7.61719 4.04242 7.61719 5.90325C7.61719 7.73659 9.04719 9.21242 10.8714 9.27659C10.9172 9.26742 11.0455 9.26742 11.1647 9.27659C12.9614 9.19409 14.373 7.71825 14.3822 5.90325C14.3822 4.04242 12.8605 2.52075 10.9997 2.52075Z"
                  fill="#D8DEF5"
                />
                <path
                  d="M11.156 20.6709C9.3593 20.6709 7.55346 20.2126 6.18763 19.2959C4.91346 18.4526 4.2168 17.2976 4.2168 16.0417C4.2168 14.7859 4.91346 13.6217 6.18763 12.7692C8.93763 10.9451 13.3926 10.9451 16.1243 12.7692C17.3893 13.6126 18.0951 14.7676 18.0951 16.0234C18.0951 17.2792 17.3985 18.4434 16.1243 19.2959C14.7493 20.2126 12.9526 20.6709 11.156 20.6709ZM6.94846 13.9242C6.06846 14.5109 5.5918 15.2626 5.5918 16.0509C5.5918 16.8301 6.07763 17.5817 6.94846 18.1592C9.23096 19.6901 13.081 19.6901 15.3635 18.1592C16.2435 17.5726 16.7201 16.8209 16.7201 16.0326C16.7201 15.2534 16.2343 14.5017 15.3635 13.9242C13.081 12.4026 9.23096 12.4026 6.94846 13.9242Z"
                  fill="#D8DEF5"
                />
              </svg>

              <span>Users</span>
            </NavLink>
            {isRoleAdmin && <h5 className="labelHeading mt-4">BUSINESS</h5>}
            {isRoleAdmin && (
              <NavLink
                className={url === `/pricing` ? " active" : ""}
                as={Link}
                to={`/pricing`}
              >
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path
                    d="M9.93634 12.4118H8.56134C7.50717 12.4118 6.65467 11.5318 6.65467 10.4409C6.65467 10.0651 6.96634 9.75343 7.34217 9.75343C7.71801 9.75343 8.02967 10.0651 8.02967 10.4409C8.02967 10.7709 8.26801 11.0368 8.56134 11.0368H9.93634C10.1472 11.0368 10.3122 10.8443 10.3122 10.6059C10.3122 10.2851 10.2572 10.2668 10.0463 10.1934L7.83717 9.42343C7.03967 9.14843 6.64551 8.58926 6.64551 7.71843C6.64551 6.72843 7.43384 5.9126 8.39634 5.9126H9.77134C10.8255 5.9126 11.678 6.7926 11.678 7.88343C11.678 8.25926 11.3663 8.57093 10.9905 8.57093C10.6147 8.57093 10.303 8.25926 10.303 7.88343C10.303 7.55343 10.0647 7.2876 9.77134 7.2876H8.39634C8.18551 7.2876 8.02051 7.4801 8.02051 7.71843C8.02051 8.03926 8.07551 8.0576 8.28634 8.13093L10.4955 8.90093C11.293 9.1851 11.678 9.74426 11.678 10.6059C11.6872 11.6051 10.8988 12.4118 9.93634 12.4118Z"
                    fill="#D8DEF5"
                  />
                  <path
                    d="M9.16699 13.1449C8.79116 13.1449 8.47949 12.8332 8.47949 12.4574V11.7791C8.47949 11.4032 8.79116 11.0916 9.16699 11.0916C9.54283 11.0916 9.85449 11.4032 9.85449 11.7791V12.4574C9.85449 12.8424 9.54283 13.1449 9.16699 13.1449Z"
                    fill="#D8DEF5"
                  />
                  <path
                    d="M9.16699 7.27823C8.79116 7.27823 8.47949 6.96657 8.47949 6.59073V5.87573C8.47949 5.4999 8.79116 5.18823 9.16699 5.18823C9.54283 5.18823 9.85449 5.4999 9.85449 5.87573V6.59073C9.85449 6.96657 9.54283 7.27823 9.16699 7.27823Z"
                    fill="#D8DEF5"
                  />
                  <path
                    d="M9.15717 17.1601C4.73884 17.1601 1.14551 13.5667 1.14551 9.14838C1.14551 4.73005 4.73884 1.13672 9.15717 1.13672C13.5755 1.13672 17.1688 4.73005 17.1688 9.14838C17.1688 13.5667 13.5663 17.1601 9.15717 17.1601ZM9.15717 2.52089C5.49967 2.52089 2.52051 5.50005 2.52051 9.15755C2.52051 12.8151 5.49967 15.7851 9.15717 15.7851C12.8147 15.7851 15.7938 12.8059 15.7938 9.14838C15.7938 5.49089 12.8147 2.52089 9.15717 2.52089Z"
                    fill="#D8DEF5"
                  />
                  <path
                    d="M15.6016 20.8267C13.9058 20.8267 12.3108 20.0017 11.3299 18.6083C11.1099 18.2967 11.1833 17.8658 11.4949 17.6458C11.8066 17.4258 12.2374 17.4992 12.4574 17.8108C13.1816 18.8375 14.3549 19.4425 15.6016 19.4425C17.7283 19.4425 19.4516 17.7192 19.4516 15.5925C19.4516 14.355 18.8466 13.1817 17.8383 12.4575C17.5266 12.2375 17.4624 11.8067 17.6824 11.495C17.9024 11.1833 18.3333 11.1192 18.6449 11.3392C20.0108 12.32 20.8266 13.9058 20.8266 15.5925C20.8266 18.4892 18.4891 20.8267 15.6016 20.8267Z"
                    fill="#D8DEF5"
                  />
                </svg>

                <span>Pricing</span>
              </NavLink>
            )}
            {isRoleAdmin && <h5 className="labelHeading mt-4">OTHER</h5>}
            {isRoleAdmin && (
              <NavLink
                className={url === `/settings` ? " active" : ""}
                as={Link}
                to={`/settings`}
              >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M18.333 6.0415H13.333C12.9913 6.0415 12.708 5.75817 12.708 5.4165C12.708 5.07484 12.9913 4.7915 13.333 4.7915H18.333C18.6747 4.7915 18.958 5.07484 18.958 5.4165C18.958 5.75817 18.6747 6.0415 18.333 6.0415Z"
                    fill="#D8DEF5"
                  />
                  <path
                    d="M5.00033 6.0415H1.66699C1.32533 6.0415 1.04199 5.75817 1.04199 5.4165C1.04199 5.07484 1.32533 4.7915 1.66699 4.7915H5.00033C5.34199 4.7915 5.62533 5.07484 5.62533 5.4165C5.62533 5.75817 5.34199 6.0415 5.00033 6.0415Z"
                    fill="#D8DEF5"
                  />
                  <path
                    d="M8.33366 8.95833C6.38366 8.95833 4.79199 7.36667 4.79199 5.41667C4.79199 3.46667 6.38366 1.875 8.33366 1.875C10.2837 1.875 11.8753 3.46667 11.8753 5.41667C11.8753 7.36667 10.2837 8.95833 8.33366 8.95833ZM8.33366 3.125C7.06699 3.125 6.04199 4.15 6.04199 5.41667C6.04199 6.68333 7.06699 7.70833 8.33366 7.70833C9.60033 7.70833 10.6253 6.68333 10.6253 5.41667C10.6253 4.15 9.60033 3.125 8.33366 3.125Z"
                    fill="#D8DEF5"
                  />
                  <path
                    d="M18.3333 15.2085H15C14.6583 15.2085 14.375 14.9252 14.375 14.5835C14.375 14.2418 14.6583 13.9585 15 13.9585H18.3333C18.675 13.9585 18.9583 14.2418 18.9583 14.5835C18.9583 14.9252 18.675 15.2085 18.3333 15.2085Z"
                    fill="#D8DEF5"
                  />
                  <path
                    d="M6.66699 15.2085H1.66699C1.32533 15.2085 1.04199 14.9252 1.04199 14.5835C1.04199 14.2418 1.32533 13.9585 1.66699 13.9585H6.66699C7.00866 13.9585 7.29199 14.2418 7.29199 14.5835C7.29199 14.9252 7.00866 15.2085 6.66699 15.2085Z"
                    fill="#D8DEF5"
                  />
                  <path
                    d="M11.6667 18.1248C9.71667 18.1248 8.125 16.5332 8.125 14.5832C8.125 12.6332 9.71667 11.0415 11.6667 11.0415C13.6167 11.0415 15.2083 12.6332 15.2083 14.5832C15.2083 16.5332 13.6167 18.1248 11.6667 18.1248ZM11.6667 12.2915C10.4 12.2915 9.375 13.3165 9.375 14.5832C9.375 15.8498 10.4 16.8748 11.6667 16.8748C12.9333 16.8748 13.9583 15.8498 13.9583 14.5832C13.9583 13.3165 12.9333 12.2915 11.6667 12.2915Z"
                    fill="#D8DEF5"
                  />
                </svg>

                <span>Settings</span>
              </NavLink>
            )}
          </>
        )}
      </div>

      <Link
        to={logoutUrl}
        className="logOut_link"
        onClick={() => handleLogoutClick()}
      >
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M12.7 18.5583H12.5916C8.89164 18.5583 7.10831 17.1 6.79997 13.8333C6.76664 13.4917 7.01664 13.1833 7.36664 13.15C7.70831 13.1166 8.01664 13.375 8.04997 13.7167C8.29164 16.3333 9.52497 17.3083 12.6 17.3083H12.7083C16.1 17.3083 17.3 16.1083 17.3 12.7167V7.28332C17.3 3.89165 16.1 2.69165 12.7083 2.69165H12.6C9.50831 2.69165 8.27497 3.68332 8.04997 6.34998C8.00831 6.69165 7.72497 6.94998 7.36664 6.91665C7.01664 6.89165 6.76664 6.58332 6.79164 6.24165C7.07497 2.92498 8.86664 1.44165 12.5916 1.44165H12.7C16.7916 1.44165 18.5416 3.19165 18.5416 7.28332V12.7167C18.5416 16.8083 16.7916 18.5583 12.7 18.5583Z"
            fill="#D8DEF5"
          />
          <path
            d="M12.4 10.625H1.66663C1.32496 10.625 1.04163 10.3417 1.04163 10C1.04163 9.65833 1.32496 9.375 1.66663 9.375H12.4C12.7416 9.375 13.025 9.65833 13.025 10C13.025 10.3417 12.75 10.625 12.4 10.625Z"
            fill="#D8DEF5"
          />
          <path
            d="M10.5416 13.4167C10.3833 13.4167 10.225 13.3584 10.1 13.2334C9.85828 12.9917 9.85828 12.5917 10.1 12.35L12.45 10L10.1 7.65003C9.85828 7.40837 9.85828 7.00837 10.1 6.7667C10.3416 6.52503 10.7416 6.52503 10.9833 6.7667L13.775 9.55837C14.0166 9.80003 14.0166 10.2 13.775 10.4417L10.9833 13.2334C10.8583 13.3584 10.7 13.4167 10.5416 13.4167Z"
            fill="#D8DEF5"
          />
        </svg>
        Logout
      </Link>
    </div>
  );
};

export default SideBar;
