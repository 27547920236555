import React, { useState, memo, useCallback, useMemo, useRef } from "react";

import SideBar from "../inc/SideBar";
import NavBar from "../inc/NavBar";
import { Col, Container, Row, Form, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import trackicon1 from "../images/starting_mark.svg";
import trackicon2 from "../images/current_location_marker.svg";
import trackicon3 from "../images/delivery_marker.svg";
import Map from "../../common/component/Map";
import { get_tracking_details } from "../services/ApiCalls";
import consoleService from "../../utility/services/consoleService";
import Loader from "../../common/component/Loader";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { getLatLng } from "react-places-autocomplete";
import { setKey, fromLatLng, geocode } from "react-geocode";
setKey("AIzaSyB3cLetAkii900cvYPIrvxILEJ6qUhpG4I");
const Tracking = ({ isUserLogin }) => {
  const [tracking_details, setTrackingDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const isTracking_details = Object.keys(tracking_details).length > 0;

  const [num, setNum] = useState("");

  const isTransitLocation =
    tracking_details.transit_current_address &&
    tracking_details.transit_current_address.length > 0;

  const limit = 7;
  const handleNumChange = (event) => {
    setNum(event.target.value.slice(0, limit));
  };

  const fetch_tracking_details = async (tracking_number) => {
    try {
      if (!tracking_number.trim()) return;
      const payload = {
        tracking_number: tracking_number,
      };
      setLoading(true);
      const res = await get_tracking_details(payload);
      if (res.status === 1) {
        const fetched_data = res.data.data_to_send;
        const isDetailsFound = Object.keys(fetched_data).length > 0;
        if (!isDetailsFound) {
          setLoading(false);
          return toast("Shipment number not found.");
        }

        const lat = get_transit_data(fetched_data.transit_locations).lat;
        const lng = get_transit_data(fetched_data.transit_locations).lng;
        if ((lat, lng)) {
          const address_result = await fromLatLng(lat, lng);
          fetched_data.transit_current_address =
            address_result.plus_code.compound_code;
        }
        fetched_data.trackingData = res.data.trackingData;
        console.log("fetched_data", fetched_data);
        setTrackingDetails({ ...fetched_data });
        // setNum("");
        setLoading(false);
      }
    } catch (err) {
      consoleService("error in fetch_tracking_details = ", err);
    }
  };

  const replace_time = (time) => {
    return time ? time.replace(":", "h") : "";
  };

  function get_transit_data(data) {
    if (data && data.length > 0) {
      const index = data.length - 1;
      return data[index];
    } else {
      return {};
    }
  }

  const modifie_distance = (distance) => {
    var distanceNumber = Number(distance);
    if (!isNaN(distanceNumber)) {
      var distanceWithTwoDecimalPlaces = distanceNumber.toFixed(0) + " km";
      return distanceWithTwoDecimalPlaces;
    } else {
      return "";
    }
  };

  const total_weight_of_material = (materialInfo) => {
    const total_weight_in_kg = materialInfo.reduce((total, item) => {
      const weight = item.totalWeightOfPallets
        ? Number(item.totalWeightOfPallets)
        : 0;
      return (total = total + weight);
    }, 0);

    const convert_into_ton = total_weight_in_kg / 1000;
    const isInKg = convert_into_ton < 1;
    if (isInKg) {
      return `${total_weight_in_kg} Kg`;
    } else {
      return `${convert_into_ton.toFixed(1)} T`;
    }
  };

  return (
    <React.Fragment>
      <div className={isUserLogin && "d-flex"} id={isUserLogin && "wrapper"}>
        {isUserLogin && <SideBar />}
        <div id={isUserLogin && "page-content-wrapper"}>
          {isUserLogin && <NavBar />}
          <div className={isUserLogin && "container-fluid"}>
            {!isTracking_details ? (
              <section className="trackorder_div px120 height100vh">
                <Container>
                  <Row>
                    <Col lg={12}>
                      <div className="trackorder_search">
                        <h4 className="trackorder_subhead text-center">
                          track your order
                        </h4>
                        <h4 className="trackorder_head text-center">
                          Enter your shipment number
                        </h4>
                        <div className="tracksearch_field">
                          <Form.Control
                            type="number"
                            value={num}
                            onChange={handleNumChange}
                            placeholder="Ex : 2558899"
                          />
                          <Button
                            as={Link}
                            onClick={() => {
                              fetch_tracking_details(num);
                            }}
                            variant="primary"
                            className="login_btn ms-3"
                          >
                            {loading ? (
                              <Loader />
                            ) : (
                              <React.Fragment>
                                <BiSearch className="search_icon" /> Research
                              </React.Fragment>
                            )}
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            ) : (
              <section className="trackorder_div px120">
                <Container>
                  <Row>
                    <Col lg={5}>
                      <div className="trackorder_search">
                        <h4 className="orderresult_subhead">
                          Enter your shipment number
                        </h4>
                        <div className="tracksearch_field">
                          <Form.Control
                            type="number"
                            value={num}
                            onChange={handleNumChange}
                            placeholder="Ex : 2558899"
                            // defaultValue="6558451"
                          />
                          <Button
                            as={Link}
                            variant="primary"
                            className="login_btn ms-3"
                            style={{ width: 50 }}
                            onClick={() => {
                              fetch_tracking_details(num);
                            }}
                          >
                            <React.Fragment>
                              <BiSearch className="search_icon" /> Research
                            </React.Fragment>
                          </Button>
                        </div>
                        <div
                          className={`resultlist_div mt_50 ${
                            tracking_details.launch_status != "delivered"
                              ? "removeLast"
                              : ""
                          }`}
                        >
                          <div
                            className={`resultinfo_box ${
                              tracking_details.launch_status === "delivered"
                                ? "active"
                                : ""
                            } ${
                              tracking_details.trackingData.length > 0
                                ? "done"
                                : ""
                            }`}
                          >
                            <span className="resultlist_icon ">
                              <Image src={trackicon1} alt="" />
                            </span>
                            <div className="result_location">
                              <h5 className="tracktime_text">
                                Loading Point{" "}
                                <span>
                                  {" "}
                                  {moment(
                                    tracking_details.loading_details.date
                                  ).format("DD.MM.YYYY")}{" "}
                                  {"- "}
                                  {replace_time(
                                    tracking_details.loading_details.hours
                                  )}
                                </span>
                              </h5>
                              <h5 className="country_text">
                                {tracking_details.loading_details.loadingCity
                                  ? tracking_details.loading_details.loadingCity
                                  : tracking_details.loading_details
                                      .loadingCountry}
                              </h5>
                              <h5 className="address_text">
                                {
                                  tracking_details.loading_details
                                    .loading_address
                                }
                              </h5>
                            </div>
                          </div>

                          {tracking_details.trackingData.map((val, index) => (
                            <div className="resultinfo_box active minheight250">
                              <span className="resultlist_icon ">
                                <Image src={trackicon2} alt="" />
                              </span>
                              <div className="result_location">
                                <h5 className="tracktime_text">
                                  Transit Point{" "}
                                  <span>
                                    {" "}
                                    {moment(val.createdAt).format(
                                      "DD.MM.YYYY"
                                    )}{" "}
                                  </span>
                                  <h5 className="country_text"> {val.city}</h5>
                                  <h5 className="address_text">
                                    {val.location_name}
                                  </h5>
                                </h5>
                              </div>
                            </div>
                          ))}
                          <div className="resultinfo_box">
                            <span className="resultlist_icon">
                              <Image src={trackicon3} alt="" />
                            </span>
                            <div className="result_location">
                              <h5 className="tracktime_text">
                                Delivery Point
                                <span>
                                  {" "}
                                  {moment(
                                    tracking_details.delivery_details.date
                                  ).format("DD.MM.YYYY")}{" "}
                                </span>
                              </h5>
                              <h5 className="country_text">
                                {" "}
                                {tracking_details.delivery_details.delivery_City
                                  ? tracking_details.delivery_details
                                      .delivery_City
                                  : tracking_details.delivery_details
                                      .delivery_Country}
                              </h5>
                              <h5 className="address_text">
                                {
                                  tracking_details.delivery_details
                                    .delivery_address
                                }
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={7}>
                      <div className="showdistance_div">
                        <div className="address_distance">
                          <h5>
                            {" "}
                            {tracking_details.loading_details &&
                            tracking_details.loading_details.loadingCity
                              ? tracking_details.loading_details.loadingCity
                              : tracking_details.loading_details.loadingState}
                            {"-"}
                            {tracking_details.loading_details &&
                              tracking_details.loading_details.loadingCountry &&
                              tracking_details.loading_details.loadingCountry}
                          </h5>
                          <div className="distance_show">
                            <p>{modifie_distance(tracking_details.distance)}</p>
                            <span className="distance_line"></span>
                          </div>
                          <h5>
                            {" "}
                            {tracking_details.delivery_details &&
                            tracking_details.delivery_details.delivery_City
                              ? tracking_details.delivery_details.delivery_City
                              : tracking_details.delivery_details
                                  .delivery_State}
                            {"-"}
                            {tracking_details.delivery_details &&
                              tracking_details.delivery_details
                                .delivery_Country &&
                              tracking_details.delivery_details
                                .delivery_Country}
                          </h5>
                        </div>
                        <div className="IDdate_distance">
                          <h5>
                            <span>Shipment number </span>{" "}
                            {tracking_details.driver_trackingNumber}
                          </h5>
                          <h5>
                            <span>ETA </span>{" "}
                            {moment(tracking_details.eta_date).format(
                              "DD.MM.YYYY"
                            )}{" "}
                            {"- "}
                            {replace_time(tracking_details.eta_time)}
                          </h5>
                          <h5>
                            <span>
                              {total_weight_of_material(
                                tracking_details.material_info
                              )}
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="trackmap_div">
                        {!loading ? (
                          <Map
                            mapContainerStyle={{
                              width: "46.5em",
                              height: "30.5em",
                            }}
                            location_details={{
                              loading_point: {
                                lat: tracking_details.loading_details.lat_lng
                                  .coordinates[1],
                                lng: tracking_details.loading_details.lat_lng
                                  .coordinates[0],
                              },
                              // transit_point: {
                              //   lat: "44.840930",
                              //   lng: "10.334290",
                              // },
                              transit_point: {
                                lat: get_transit_data(
                                  tracking_details.trackingData
                                ).latitude,
                                lng: get_transit_data(
                                  tracking_details.trackingData
                                ).longitude,
                              },
                              delivery_point: {
                                lat: tracking_details.delivery_details.lat_lng
                                  .coordinates[1],
                                lng: tracking_details.delivery_details.lat_lng
                                  .coordinates[0],
                              },
                            }}
                          />
                        ) : (
                          <view className="map_loader_container">
                            <Loader size={80} color={"#F7CE6C"} />
                          </view>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default Tracking;
