import React, { useEffect, useState, useContext } from "react";
import { Col, Container, Row, Form, Button, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import loginimg from "../images/login_img2.jpg";
import { user_Login } from "../../component/services/UserApiServices/UserApiCalls";
import { ToastContainer, toast } from "react-toastify";
import * as SERVICE from "../../component/services/index";
import Loader from "../../common/component/Loader";
import { AppStoreContext } from "../../store/AppStore";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
  const navigate = useNavigate();
  const { setAccess, setUserDetails } = useContext(AppStoreContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [emailError, setemailError] = useState(0);
  const [passwordError, setpasswordError] = useState(0);

  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const onProfileValidation = async () => {
    let errorCount = 0;

    let emailError = SERVICE.validateEmail(email);
    if (emailError !== undefined) {
      errorCount++;
      let msg =
        emailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setemailError(msg);
    } else {
      setemailError(null);
    }

    if (!password) {
      errorCount++;
      let msg = "Please enter your password.";
      setpasswordError(msg);
    } else {
      setpasswordError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
    console.log("errorCount", errorCount);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = () => {
    setSubmit(true);
    onProfileValidation();
    if (errorCount > 0) {
      console.log("check if");
      console.log("errorCount", errorCount);
      return;
    } else {
      userLogin();
      console.log("call api");
    }
  };

  useEffect(() => {
    onProfileValidation();
  }, [email, password]);

  const userLogin = async () => {
    const payload = {
      userEmail: email,
      userPassword: password,
      type: "User",
    };

    setIsLoading(true);
    await user_Login(payload)
      .then(async (res) => {
        if (res.status === true) {
          const userDetails = res.data;
          const role = userDetails.profile;
          const isRoleUser = role == "User";
          const isRoleCarrier = role == "Carrier";
          const isRoleClient = role == "Client";
          let access;
          if (isRoleUser) {
            access = "userAccess";
          } else if (isRoleCarrier) {
            access = "carrierAccess";
          } else if (isRoleClient) {
            access = "clientAccess";
          }

          localStorage.setItem("userDetail", JSON.stringify(res.data));
          localStorage.setItem("token", JSON.stringify(res.token));
          localStorage.setItem("access", JSON.stringify(access));
          setAccess(access);
          setUserDetails(res.data);
          setIsLoading(false);
          setTimeout(() => {
            toast("Login Successfully");
          }, 2000);
          navigate(`/account`, { replace: true });
        } else toast(res.msg);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        toast("Please check you email and password");
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <React.Fragment>
      <section className="loginmain_div loginmain_div1">
        <Container fluid>
          <Row>
            <Col md={12} lg={7} className="align-self-center">
              <div className="loginform_div py_200">
                <h4 className="login_head">Login</h4>
                <Form>
                  <Form.Group className="form_group" controlId="formGroupEmail">
                    <Form.Label>Email or account number</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter an email or account number"
                      defaultValue=""
                      onChange={(text) => setEmail(text.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") onSubmit();
                      }}
                    />
                    {emailError && Submit ? (
                      <div
                        style={{
                          fontFamily: "AvenirLTProLight",
                          color: "#FF0000",
                          opacity: 0.6,
                        }}
                      >
                        {emailError}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group
                    className="form_group"
                    controlId="formGroupPassword"
                  >
                    <Form.Label>Password</Form.Label>
                    <div
                      className="password-input-container"
                      style={{ position: "relative" }}
                    >
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter a password"
                        defaultValue=""
                        onChange={(text) => setPassword(text.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") onSubmit();
                        }}
                      />
                      <span
                        onClick={togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          right: "20px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                        />
                      </span>
                    </div>
                    {passwordError && Submit ? (
                      <div
                        style={{
                          fontFamily: "AvenirLTProLight",
                          color: "#FF0000",
                          opacity: 0.6,
                        }}
                      >
                        {passwordError}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="form_group text-end">
                    <Link
                      className="forgotpassword_link"
                      to={`/forget_password`}
                    >
                      Forgot password ?
                    </Link>
                  </Form.Group>
                  <div className="text-center">
                    <Button
                      // to="/carrier_quotes"
                      variant="primary"
                      className="login_btn mt-2"
                      onClick={() => onSubmit()}
                    >
                      {isLoading ? <Loader /> : "Connexion"}
                    </Button>
                  </div>
                  <div className="loginbottom_div">
                    <p>
                      You don't have an account ?{" "}
                      <Link to={"/usersignup"}>Register here</Link>
                    </p>
                  </div>
                </Form>
              </div>
            </Col>
            <Col md={12} lg={5} className="px-0">
              <Image className="loginimg" src={loginimg} alt="" />
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Login;
