import React, { useContext, useEffect, useState } from "react";
import SideBar from "../inc/SideBar";
import NavBar from "../inc/NavBar";
import {
  Col,
  Container,
  Image,
  Row,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import Logo1 from "../images/logo1.png";
import Logo from "../images/logo.svg";
import FlagIcon from "../images/aus.png";
import FlagIcon1 from "../images/french.svg";
import MessageIcon2 from "../images/message-2.svg";
import EditIcon2 from "../images/edit-2.svg";
import BuildingIcon2 from "../images/building-4.svg";
import PaymentIcon2 from "../images/payment.svg";
import UserSquare from "../images/user-square.svg";
import Select from "react-select";
import { Link } from "react-router-dom";
import {
  country_PhoneNumberCode,
  edit_AdminProfile,
  getAdminDetail,
  staff_EditAccount,
  staff_PaticularDetail,
  uploadImage,
} from "../services/ApiCalls";
import TextInputWithLabel from "../customcomponents/TextInputWithLabel";
import { ToastContainer, toast } from "react-toastify";
import LoadingContext from "../services/LoadingContext";
import { setNumberValidation } from "../services";
import * as SERVICE from "../services/index";
import { QuotesDataContext } from "../services/QuotesData";
import {
  user_Detail,
  user_EditDetail,
} from "../services/UserApiServices/UserApiCalls";
import { BASE_URL } from "../services/env";
import { HiUser } from "react-icons/hi2";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import { FaLocationDot } from "react-icons/fa6";
import { AppStoreContext } from "../../store/AppStore";
import Loader from "../../common/component/Loader";
import consoleService from "../../utility/services/consoleService";
import CustomHookFunctions from "../services/CustomHookFunctions";
const options = [
  {
    id: 1,
    value: "English",
    label: (
      <div>
        <Image src={FlagIcon} alt="country-image" /> English
      </div>
    ),
  },
  {
    id: 2,
    value: "French",
    label: (
      <div>
        <Image src={FlagIcon1} alt="country-image" /> Français
      </div>
    ),
  },
];

const Account = () => {
  const { userDetail, setUserDetails } = useContext(AppStoreContext);

  const {
    accountNumber,
    contactemail,
    contactPhone,
    companyName,
    accountOnwerLastName,
    accountOnwerFirstName,
    CountryCode,
    profile,
    vat,
    paymentBIC,
    paymentIBAN,
    address,
    siret,
    pic,
    contactCode,
    accountOwnerFirstName,
    accountOwnerLastName,
    accountOwnerPhoneNumber,
    accountOwnerEmail,
    DUNS,
    IBAN,
    contactEmail,
    BIC,
    phoneNumber,
    firstName,
    lastName,
    countryCode,
    email,
    phone_Number,
    duns,
    rcs,
    vat_number,
    accountOnwerName,
    accountPhoneNumber,
    accountEmail,
    LoginId,
    accountOwnerCode,
    mangerEmail,
    Position,
    contact_first_name,
    contact_last_name,
  } = userDetail;

  const userRole = profile;
  const [editContactLoading, setEditContactLoading] = useState(false);
  const [editCompanyLoading, setEditCompanyLoading] = useState(false);
  const [editPaymentLoading, setEditPaymentLoading] = useState(false);
  const [editManagerLoading, setEditManagerLoading] = useState(false);

  const isRoleUser = userRole == "User";
  const isRoleCarrier = userRole == "Carrier";
  const isRoleClient = userRole == "Client";
  const isRoleAdmin = userRole == "Admin";
  const isRoleStaff = userRole == "Staff";
  ////////////////////////////////////////// OLD VARIABLES ///////////////////////////////////////////////////////

  const { accountDetail, setAccountDetail } = useContext(QuotesDataContext);
  const [selOption, setSelectedOption] = useState(false);

  const [imageSrc, setImageSrc] = useState(null);

  const [isImage, setIsImage] = useState(false);
  const [profilePreview, setProfilePreview] = useState(null);
  const [contactModal, setContactModal] = useState(false);
  const [companyModal, setCompanyModal] = useState(false);
  const [accountModal, setAccountModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [ownerFirstName, setOwnerFirstName] = useState("");
  const [ownerLastName, setOwnerLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [ContactEmail, setContactEmail] = useState("");
  const [conTactCompanyName, setContactCompanyName] = useState("");
  const [compnayDuns, setCompanyDuns] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountOwnerPhone, setAccountOwnerPhone] = useState("");
  const [AccountOwnerEmail, setAccountOwnerEmail] = useState("");
  const [iban, setIban] = useState("");
  const [bic, setBic] = useState("");
  const [user_rcs, setRcs] = useState("");

  const [modalData, setModalData] = useState({});

  const { setIsLoading } = useContext(LoadingContext);

  // error
  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);
  const [ownerFirstNameError, setOwnerFirstNameError] = useState("");
  const [ownerLastNameError, setOwnerLastNameError] = useState("");
  const [userFirstNameErr, setUserFirstNameErr] = useState("");
  const [userLastNameErr, setUserLastNameErr] = useState("");
  const [userSiretError, setUserSiretError] = useState("");
  const [userVatError, setUserVatError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [contactEmailError, setContactEmailError] = useState("");
  const [contactFirstNameError, setContactFirstNameError] = useState("");
  const [contactLastNameError, setContactLastNameError] = useState("");
  const [conTactCompanyNameError, setContactCompanyNameError] = useState("");
  const [compnayDunsError, setCompanyDunsError] = useState("");
  const [companyAddressError, setCompanyAddressError] = useState("");
  const [companyEmailError, setCompanyEmailError] = useState("");
  const [accountNameError, setAccountNameError] = useState("");
  const [accountOwnerPhoneError, setAccountOwnerPhoneError] = useState("");
  const [accountOwnerEmailError, setAccountOwnerEmailError] = useState("");
  const [ibanError, setIbanError] = useState("");
  const [bicError, setBicError] = useState("");

  // MODIFIED STATES
  const [user_profile_pic, setUserProfilePic] = useState("");
  const [user_first_name, setUserFirstName] = useState("");
  const [user_account_number, setUserAccountNumber] = useState("");
  const [user_last_name, setUserLastName] = useState("");
  const [user_phone, setUserPhone] = useState("");
  const [user_email, setUserEmail] = useState("");
  const [manager_first_name, setManageFirstrName] = useState("");
  const [manager_last_name, setManagerLastName] = useState("");
  const [manager_email, setManagerEmail] = useState("");
  const [manager_phone, setManagerPhone] = useState("");
  const [manager_postion, setManagerPosition] = useState("");
  const [manager_country_code, setManagerCountryCode] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [user_duns, setUserDuns] = useState("");
  const [user_company_address, setUserCompanyAddress] = useState("");
  const [user_iban, setUserIban] = useState("");
  const [user_vat, setUserVat] = useState("");
  const [user_siret, setUserSiret] = useState("");
  const [user_bic, setUserBic] = useState("");
  const [user_country_code, setUserCountryCode] = useState("");

  // EDITED STATES

  const [edit_user_profile_pic, setEditUserProfilePic] =
    useState(user_profile_pic);
  const [edit_user_first_name, setEditUserFirstName] =
    useState(user_first_name);
  const [edit_user_last_name, setEditUserLastName] = useState(user_last_name);

  const [edit_user_phone, setEditUserPhone] = useState(user_phone);
  const [edit_user_email, setEditUserEmail] = useState(user_email);
  const [contactFirstName, setContactFirstName] = useState(contact_first_name);
  const [contactLastName, setContactLastName] = useState(contact_last_name);
  const [edit_company_name, setEditCompanyName] = useState(company_name);
  const [edit_user_duns, setEditUserDuns] = useState(user_duns);
  const [edit_user_company_address, setEditUserCompanyAddress] =
    useState(user_company_address);
  const [edit_user_iban, setEditUserIban] = useState(user_iban);
  const [edit_user_vat, setEditUserVat] = useState(vat_number);
  const [edit_user_siret, setEditUserSiret] = useState(user_siret);
  const [edit_user_bic, setEditUserBic] = useState(user_bic);
  const [edit_user_country_code, setEditUserCountryCode] =
    useState(user_country_code);
  const [edit_manager_first_name, setEditManageFirstrName] = useState("");
  const [edit_manager_last_name, setEditManagerLastName] = useState("");
  const [edit_manager_email, setEditManagerEmail] = useState("");
  const [edit_manager_phone, setEditManagerPhone] = useState("");
  const [edit_manager_country_code, setEditManagerCountryCode] = useState("");
  const [edit_rcs, setEditRcs] = useState("");

  const { isValidString } = CustomHookFunctions();

  // USE EFFECT TO MODIFIE STATES

  useEffect(() => {
    if (isRoleUser) {
      isValidString(accountOnwerFirstName) &&
        setUserFirstName(accountOnwerFirstName);
      isValidString(accountOnwerLastName) &&
        setUserLastName(accountOnwerLastName);
      isValidString(accountNumber) && setUserAccountNumber(accountNumber);
      isValidString(contactPhone) && setUserPhone(contactPhone);
      isValidString(contactemail) && setUserEmail(contactemail);
      isValidString(companyName) && setCompanyName(companyName);
      isValidString(address) && setUserCompanyAddress(address);
      isValidString(paymentIBAN) && setUserIban(paymentIBAN);
      isValidString(vat) && setUserVat(vat);
      isValidString(siret) && setUserSiret(siret);
      isValidString(paymentBIC) && setUserBic(paymentBIC);
      isValidString(CountryCode) && setUserCountryCode(CountryCode);
      isValidString(accountOnwerFirstName) &&
        setManageFirstrName(accountOnwerFirstName);
      isValidString(accountOnwerLastName) &&
        setManagerLastName(accountOnwerLastName);
      isValidString(accountEmail) && setManagerEmail(accountEmail);
      isValidString(accountPhoneNumber) && setManagerPhone(accountPhoneNumber);
      isValidString(contactCode) && setManagerCountryCode(contactCode);

      if (pic.length > 0) {
        setUserProfilePic(pic[0]);
      }

      // SET EDITED STATES
      isValidString(accountOnwerFirstName) &&
        setEditUserFirstName(accountOnwerFirstName);
      isValidString(accountOnwerLastName) &&
        setEditUserLastName(accountOnwerLastName);

      isValidString(contactPhone) && setEditUserPhone(contactPhone);
      isValidString(contactemail) && setEditUserEmail(contactemail);
      isValidString(companyName) && setEditCompanyName(companyName);
      isValidString(address) && setEditUserCompanyAddress(address);
      isValidString(paymentIBAN) && setEditUserIban(paymentIBAN);
      isValidString(vat) && setEditUserVat(vat);
      isValidString(siret) && setEditUserSiret(siret);
      isValidString(paymentBIC) && setEditUserBic(paymentBIC);
      isValidString(CountryCode) && setEditUserCountryCode(CountryCode);
      isValidString(accountOnwerFirstName) &&
        setEditManageFirstrName(accountOnwerFirstName);
      isValidString(accountOnwerLastName) &&
        setEditManagerLastName(accountOnwerLastName);
      isValidString(accountEmail) && setEditManagerEmail(accountEmail);
      isValidString(accountPhoneNumber) &&
        setEditManagerPhone(accountPhoneNumber);
      isValidString(contactCode) && setEditManagerCountryCode(contactCode);

      if (pic.length > 0) {
        setEditUserProfilePic(BASE_URL + "server/" + pic[0]);
      }
    } else if (isRoleCarrier) {
      isValidString(contactPhone) && setUserPhone(contactPhone);
      isValidString(contactEmail) && setUserEmail(contactEmail);
      isValidString(companyName) && setCompanyName(companyName);
      isValidString(address) && setUserCompanyAddress(address);
      isValidString(DUNS) && setUserDuns(DUNS);
      isValidString(IBAN) && setUserIban(IBAN);
      isValidString(vat) && setUserVat(vat);
      isValidString(siret) && setUserSiret(siret);
      isValidString(BIC) && setUserBic(BIC);
      isValidString(CountryCode) && setUserCountryCode(CountryCode);
      isValidString(accountOwnerFirstName) &&
        setManageFirstrName(accountOwnerFirstName);
      isValidString(accountOwnerLastName) &&
        setManagerLastName(accountOwnerLastName);
      isValidString(accountOwnerEmail) && setManagerEmail(accountOwnerEmail);
      isValidString(accountNumber) && setUserAccountNumber(accountNumber);
      isValidString(accountOwnerPhoneNumber) &&
        setManagerPhone(accountOwnerPhoneNumber);
      isValidString(contactCode) && setManagerCountryCode(contactCode);
      if (pic.length > 0) {
        setUserProfilePic(pic[0]);
      }

      // SET EDITED STATES
      isValidString(accountOnwerFirstName) &&
        setEditUserFirstName(accountOnwerFirstName);
      isValidString(accountOnwerLastName) &&
        setEditUserLastName(accountOnwerLastName);

      isValidString(contactPhone) && setEditUserPhone(contactPhone);
      isValidString(contactEmail) && setEditUserEmail(contactEmail);
      isValidString(companyName) && setEditCompanyName(companyName);
      isValidString(address) && setEditUserCompanyAddress(address);
      isValidString(DUNS) && setEditUserDuns(DUNS);
      isValidString(IBAN) && setEditUserIban(IBAN);
      isValidString(vat) && setEditUserVat(vat);
      isValidString(siret) && setEditUserSiret(siret);
      isValidString(BIC) && setEditUserBic(BIC);
      isValidString(CountryCode) && setEditUserCountryCode(CountryCode);
      isValidString(accountOwnerFirstName) &&
        setEditManageFirstrName(accountOwnerFirstName);
      isValidString(accountOwnerLastName) &&
        setEditManagerLastName(accountOwnerLastName);
      isValidString(accountOwnerEmail) &&
        setEditManagerEmail(accountOwnerEmail);
      isValidString(accountOwnerPhoneNumber) &&
        setEditManagerPhone(accountOwnerPhoneNumber);
      isValidString(contactCode) && setEditManagerCountryCode(contactCode);
      if (pic.length > 0) {
        setEditUserProfilePic(BASE_URL + "server/" + pic[0]);
      }
    } else if (isRoleAdmin) {
      const accountOwnerFirstName = accountOnwerName
        ? accountOnwerName.split(" ")
        : ["N/A", ""];

      isValidString(phoneNumber) && setUserPhone(phoneNumber);
      isValidString(email) && setUserEmail(email);
      isValidString(companyName) && setCompanyName(companyName);
      isValidString(address) && setUserCompanyAddress(address);
      isValidString(paymentIBAN) && setUserIban(paymentIBAN);
      isValidString(duns) && setUserSiret(duns);
      isValidString(paymentBIC) && setUserBic(paymentBIC);
      isValidString(countryCode) && setUserCountryCode(countryCode);
      isValidString(accountOwnerFirstName[0]) &&
        setManageFirstrName(accountOwnerFirstName[0]);
      isValidString(accountOwnerFirstName[1]) &&
        setManagerLastName(accountOwnerFirstName[1]);
      isValidString(accountEmail) && setManagerEmail(accountEmail);
      isValidString(LoginId) && setUserAccountNumber(LoginId);
      isValidString(accountPhoneNumber) && setManagerPhone(accountPhoneNumber);
      isValidString(contactCode) && setManagerCountryCode(contactCode);
      isValidString(rcs) && setRcs(rcs);
      if (pic.length > 0) {
        setUserProfilePic(pic[0]);
      }

      // SET EDITED STATES

      isValidString(phoneNumber) && setEditUserPhone(phoneNumber);
      isValidString(email) && setEditUserEmail(email);
      isValidString(companyName) && setEditCompanyName(companyName);
      isValidString(address) && setEditUserCompanyAddress(address);
      isValidString(paymentIBAN) && setEditUserIban(paymentIBAN);
      isValidString(duns) && setEditUserSiret(duns);
      isValidString(paymentBIC) && setEditUserBic(paymentBIC);
      isValidString(countryCode) && setEditUserCountryCode(countryCode);
      isValidString(accountOwnerFirstName[0]) &&
        setEditManageFirstrName(accountOwnerFirstName[0]);
      isValidString(accountOwnerFirstName[1]) &&
        setEditManagerLastName(accountOwnerFirstName[1]);
      isValidString(accountEmail) && setEditManagerEmail(accountEmail);
      isValidString(accountPhoneNumber) &&
        setEditManagerPhone(accountPhoneNumber);
      isValidString(contactCode) && setEditManagerCountryCode(contactCode);
      isValidString(rcs) && setEditRcs(rcs);
      if (pic.length > 0) {
        setEditUserProfilePic(BASE_URL + "server/" + pic[0]);
      }
    } else if (isRoleClient) {
      isValidString(contactPhone) && setUserPhone(contactPhone);
      isValidString(contactEmail) && setUserEmail(contactEmail);
      isValidString(companyName) && setCompanyName(companyName);
      isValidString(address) && setUserCompanyAddress(address);
      isValidString(IBAN) && setUserIban(IBAN);
      isValidString(DUNS) && setUserSiret(DUNS);
      isValidString(BIC) && setUserBic(BIC);
      isValidString(accountOwnerCode) && setUserCountryCode(accountOwnerCode);
      isValidString(accountOwnerFirstName) &&
        setManageFirstrName(accountOwnerFirstName);
      isValidString(accountOwnerLastName) &&
        setManagerLastName(accountOwnerLastName);
      isValidString(accountOwnerEmail) && setManagerEmail(accountOwnerEmail);
      isValidString(LoginId) && setUserAccountNumber(LoginId);
      isValidString(accountOwnerPhoneNumber) &&
        setManagerPhone(accountOwnerPhoneNumber);
      isValidString(contactCode) && setManagerCountryCode(contactCode);
      if (pic.length > 0) {
        setUserProfilePic(pic[0]);
      }

      // SET EDITED STATES

      isValidString(contactPhone) && setEditUserPhone(contactPhone);
      isValidString(contactEmail) && setEditUserEmail(contactEmail);
      isValidString(companyName) && setEditCompanyName(companyName);
      isValidString(address) && setEditUserCompanyAddress(address);
      isValidString(IBAN) && setEditUserIban(IBAN);
      isValidString(DUNS) && setEditUserSiret(DUNS);
      isValidString(BIC) && setEditUserBic(BIC);
      isValidString(accountOwnerCode) &&
        setEditUserCountryCode(accountOwnerCode);
      isValidString(accountOwnerFirstName) &&
        setEditManageFirstrName(accountOwnerFirstName);
      isValidString(accountOwnerLastName) &&
        setEditManagerLastName(accountOwnerLastName);
      isValidString(accountOwnerEmail) &&
        setEditManagerEmail(accountOwnerEmail);
      isValidString(accountOwnerPhoneNumber) &&
        setEditManagerPhone(accountOwnerPhoneNumber);
      isValidString(contactCode) && setEditManagerCountryCode(contactCode);
      if (pic.length > 0) {
        setEditUserProfilePic(BASE_URL + "server/" + pic[0]);
      }
    } else if (isRoleStaff) {
      isValidString(firstName) && setUserFirstName(firstName);
      isValidString(lastName) && setUserLastName(lastName);
      isValidString(phoneNumber) && setUserPhone(phoneNumber);
      isValidString(email) && setUserEmail(email);
      isValidString(companyName) && setCompanyName(companyName);
      isValidString(address) && setUserCompanyAddress(address);
      isValidString(duns) && setUserDuns(duns);
      isValidString(paymentIBAN) && setUserIban(paymentIBAN);
      isValidString(duns) && setUserSiret(duns);
      isValidString(paymentBIC) && setUserBic(paymentBIC);
      isValidString(contactCode) && setUserCountryCode(contactCode);
      isValidString(accountOnwerName) && setManageFirstrName(accountOnwerName);
      isValidString(accountOnwerLastName) &&
        setManagerLastName(accountOnwerLastName);
      isValidString(mangerEmail) && setManagerEmail(mangerEmail);
      isValidString(accountNumber) && setUserAccountNumber(accountNumber);
      isValidString(accountPhoneNumber) && setManagerPhone(accountPhoneNumber);
      isValidString(accountOwnerCode) &&
        isValidString(Position) &&
        setManagerPosition(Position);
      isValidString(accountOwnerCode) &&
        setManagerCountryCode(accountOwnerCode);
      if (pic.length > 0) {
        setUserProfilePic(pic[0]);
      }

      // SET EDITED STATES
      isValidString(firstName) && setEditUserFirstName(firstName);
      isValidString(lastName) && setEditUserLastName(lastName);

      isValidString(phoneNumber) && setEditUserPhone(phoneNumber);
      isValidString(email) && setEditUserEmail(email);
      isValidString(companyName) && setEditCompanyName(companyName);
      isValidString(address) && setEditUserCompanyAddress(address);
      isValidString(duns) && setEditUserDuns(duns);
      isValidString(paymentIBAN) && setEditUserIban(paymentIBAN);
      isValidString(duns) && setEditUserSiret(duns);
      isValidString(paymentBIC) && setEditUserBic(paymentBIC);
      isValidString(contactCode) && setEditUserCountryCode(contactCode);
      isValidString(accountOnwerName) &&
        setEditManageFirstrName(accountOnwerName);
      isValidString(accountOnwerLastName) &&
        setEditManagerLastName(accountOnwerLastName);
      isValidString(mangerEmail) && setEditManagerEmail(mangerEmail);
      isValidString(accountPhoneNumber) &&
        setEditManagerPhone(accountPhoneNumber);
      isValidString(accountOwnerCode) &&
        setEditManagerCountryCode(accountOwnerCode);
      if (pic.length > 0) {
        setEditUserProfilePic(BASE_URL + "server/" + pic[0]);
      }
    }
  }, [userDetail]);

  const handlesetSelectedOption = () => {
    setSelectedOption(!selOption);
  };

  useEffect(() => {
    if (isRoleAdmin) {
      fetchAdminDetail();
    } else if (isRoleStaff) {
      fetchStaffDetail();
    } else {
      fetchUserDetail();
    }
  }, []);

  // Get Admin Data
  const fetchAdminDetail = () => {
    const payload = {
      adminId: userDetail._id,
    };

    setIsLoading(true);
    getAdminDetail(payload)
      .then(async (res) => {
        if (res.status === true) {
          setAccountDetail(res.data);
          setImageSrc(res.data.pic[0]);
          if (res.data.pic && res.data.pic.length > 0) {
            setProfilePreview(BASE_URL + "server/" + res.data.pic[0]);
          }
          // setProfileImage(res.data);
          // await localStorage.setItem("IMAGEPROFILE", res.data.pic);
          await localStorage.setItem("userDetail", JSON.stringify(res.data));
        } else {
          toast(res.msg);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  // Get Staff Data
  const fetchStaffDetail = () => {
    const payload = {
      email: userDetail.email,
    };

    setIsLoading(true);
    staff_PaticularDetail(payload)
      .then(async (res) => {
        if (res.status === true) {
          setAccountDetail(res.staffData);
          setImageSrc(res.staffData.pic[0]);
          if (res.staffData.pic && res.staffData.pic.length > 0) {
            setProfilePreview(BASE_URL + "server/" + res.staffData.pic[0]);
          }
          // setProfilePreview(res.staffData.pic[0]);
          // setProfileImage(res.staffData);
          await localStorage.setItem(
            "userDetail",
            JSON.stringify(res.staffData)
          );
        } else {
          toast(res.msg);
        }

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  // Get User Data
  const fetchUserDetail = () => {
    const payload = {
      userId: userDetail._id,
      role: userRole,
    };

    setIsLoading(true);
    user_Detail(payload)
      .then((res) => {
        setAccountDetail(res.data);
        setUserDetails({ ...res.data });
        const pic_array = res.data.pic;
        if (pic_array.length > 0) {
          setEditUserProfilePic(BASE_URL + "server/" + res.data.pic[0]);
          setUserProfilePic(BASE_URL + "server/" + res.data.pic[0]);
        }

        localStorage.setItem("userDetail", JSON.stringify(res.data));

        // setProfileImage(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const setProfileImage = (data) => {
    setProfilePreview(
      data.pic && data.pic.length > 0
        ? BASE_URL + "server/" + data.pic[0]
        : Logo
    );

    setImageSrc(
      data.pic && data.pic.length > 0
        ? BASE_URL + "server/" + data.pic[0]
        : imageSrc
    );
  };

  useEffect(() => {
    if (accountDetail) {
      setOwnerLastName(ownerLastName ? ownerLastName : lastName);
      setOwnerFirstName(ownerFirstName ? ownerFirstName : firstName);
      setPhone(phone_Number ? phone_Number : contactPhone);
      setContactEmail(email ? email : contactemail);
      setContactCompanyName(companyName);
      setCompanyDuns(duns);
      setCompanyAddress(address);
      setCompanyEmail(companyEmail);
      setAccountName(accountName ? accountName : accountOnwerName);
      setAccountOwnerPhone(accountPhoneNumber);
      setAccountOwnerEmail(accountEmail);
      setIban(paymentIBAN);
      setBic(paymentBIC);
      setSelectedCountryCode(contactCode);
      setUnLoadingCountryCode(accountOwnerCode);
      // getImage(pic)
    }
  }, [accountDetail, modalData]);

  const onSubmit = async (item) => {
    setSubmit(true);

    const isValid = onProfileValidation(item);

    if (!isValid) {
      if (errorCount > 0) {
        return;
      } else {
        setSubmit(false);
        handleEditButton(item);
      }
    }
  };

  const handleEditButton = (edit_type) => {
    editUserProfile(edit_type);
  };

  function setLoadingFun(loading_type, value) {
    if (loading_type == "CONTACT") {
      setEditContactLoading(value);
    } else if (loading_type == "COMPANY") {
      setEditCompanyLoading(value);
    } else if (loading_type == "PAYMENT") {
      setEditPaymentLoading(value);
    } else if (loading_type == "MANAGER") {
      setEditManagerLoading(value);
    }
  }

  const editUserProfile = (edit_type) => {
    const first_country_code = phoneNumberCode[0].code;
    const formData = new FormData();
    if (imageSrc) {
      formData.append("file", imageSrc);
    } else {
      formData.append("pic", user_profile_pic);
    }
    // if (imageSrc) formData.append("pic", imageSrc);
    formData.append("addid", userDetail._id);
    formData.append("firstName", edit_user_first_name);
    formData.append("lastName", edit_user_last_name);
    formData.append("Level", edit_company_name);
    formData.append("address", edit_user_company_address);
    formData.append("contactemail", edit_user_email);
    formData.append("contactPhone", edit_user_phone);
    formData.append("paymentIBAN", edit_user_iban);
    formData.append("paymentBIC", edit_user_bic);
    formData.append("vat", edit_user_vat);
    formData.append("siret", edit_user_siret);
    formData.append(
      "countryCode",
      edit_user_country_code ? edit_user_country_code : first_country_code
    );
    formData.append("accountOwnerFirstName", edit_manager_first_name);
    formData.append("accountOwnerLastName", edit_manager_last_name);
    formData.append("accountOwnerPhoneNumber", edit_manager_phone);
    formData.append("accountOwnerEmail", edit_manager_email);
    formData.append(
      "contactCode",
      edit_manager_country_code ? edit_manager_country_code : first_country_code
    );
    formData.append("duns", edit_user_siret);
    formData.append("role", userRole);
    formData.append("rcs", edit_rcs);
    formData.append("vat_number", edit_user_vat);

    formData.append("contact_first_name", contactFirstName);
    formData.append("contact_last_name", contactLastName);

    setLoadingFun(edit_type, true);

    // setIsLoading(true);
    user_EditDetail(formData)
      .then((res) => {
        if (res.status === true) {
          toast("Account updated successfully!");
          fetchUserDetail();
          setLoadingFun(edit_type, false);
        } else toast(res.msg);
        setContactModal(false);
        setCompanyModal(false);
        setAccountModal(false);
        setPaymentModal(false);
        setLoadingFun(edit_type, false);
      })
      .catch((err) => {
        consoleService("error in user_edit_details_fun = ", err);
        setLoadingFun(edit_type, false);
      });
  };

  const onProfileValidation = (item) => {
    let errorCount = 0;

    if (isRoleUser) {
      if (item === "CONTACT" || item === undefined) {
        if (!contactFirstName) {
          errorCount++;
          let msg = "Please enter first name.";
          setContactFirstNameError(msg);
        } else {
          setContactFirstNameError(null);
        }

        if (!contactLastName) {
          errorCount++;
          let msg = "Please enter last name.";
          setContactLastNameError(msg);
        } else {
          setContactLastNameError(null);
        }

        if (!edit_user_phone) {
          errorCount++;
          let msg = "Please enter a valid number.";
          setPhoneError(msg);
        } else {
          setPhoneError(null);
        }

        let contactEmailError = SERVICE.validateEmail(edit_user_email);
        if (contactEmailError !== undefined) {
          errorCount++;
          let msg =
            contactEmailError === "required"
              ? "Please enter your email address."
              : "Please enter a valid email address.";

          setContactEmailError(msg);
        } else {
          setContactEmailError(null);
        }

        if (errorCount > 0) {
          return true;
        } else {
          return false;
        }
      }

      if (item === "COMPANY" || item === undefined) {
        if (!edit_company_name) {
          errorCount++;
          let msg = "Please enter name.";
          setContactCompanyNameError(msg);
        } else {
          setContactCompanyNameError(null);
        }

        if (!edit_user_company_address) {
          errorCount++;
          let msg = "Please enter address.";
          setCompanyAddressError(msg);
        } else {
          setCompanyAddressError(null);
        }

        if (!edit_user_siret) {
          errorCount++;
          let msg = `Please Enter ${selOption ? "SIRET" : "DUNS"}.`;
          setUserSiretError(msg);
        } else {
          setUserSiretError(null);
        }

        // if (!edit_user_vat) {
        //   errorCount++;
        //   let msg = "Please enter VAT.";
        //   setUserVatError(msg);
        // } else {
        //   setUserVatError(null);
        // }

        if (errorCount > 0) {
          return true;
        } else {
          return false;
        }
      }

      if (item === "ACCOUNT" || item === undefined) {
        if (!accountName) {
          errorCount++;
          let msg = "Please enter name.";
          setAccountNameError(msg);
        } else {
          setAccountNameError(null);
        }

        if (!accountOwnerPhone) {
          errorCount++;
          let msg = "Please enter a valid number.";
          setAccountOwnerPhoneError(msg);
        } else {
          setAccountOwnerPhoneError(null);
        }

        let accountOwnerEmailError = SERVICE.validateEmail(AccountOwnerEmail);
        if (accountOwnerEmailError !== undefined) {
          errorCount++;
          let msg =
            AccountOwnerEmail === "required"
              ? "Please enter your email address."
              : "Please enter a valid email address.";
          setAccountOwnerEmailError(msg);
        } else {
          setAccountOwnerEmailError(null);
        }

        if (errorCount > 0) {
          return true;
        } else {
          return false;
        }
      }

      if (item === "PAYMENT" || item === undefined) {
        if (!edit_user_iban) {
          errorCount++;
          let msg = "Please enter IBAN.";
          setIbanError(msg);
        } else {
          setIbanError(null);
        }
        if (!edit_user_bic) {
          errorCount++;
          let msg = "Please enter BIC.";
          setBicError(msg);
        } else {
          setBicError(null);
        }

        if (errorCount > 0) {
          return true;
        } else {
          return false;
        }
      }
    } else if (isRoleCarrier) {
      if (item === "MANAGER" || item === undefined) {
        if (!edit_manager_first_name) {
          errorCount++;
          let msg = "Please enter first name.";
          setUserFirstNameErr(msg);
        } else {
          setUserFirstNameErr(null);
        }

        if (!edit_manager_last_name) {
          errorCount++;
          let msg = "Please enter last name.";
          setUserLastNameErr(msg);
        } else {
          setUserLastNameErr(null);
        }

        if (!edit_manager_phone) {
          errorCount++;
          let msg = "Please enter a valid number.";
          setPhoneError(msg);
        } else {
          setPhoneError(null);
        }

        let contactEmailError = SERVICE.validateEmail(edit_manager_email);
        if (contactEmailError !== undefined) {
          errorCount++;
          let msg =
            contactEmailError === "required"
              ? "Please enter your email address."
              : "Please enter a valid email address.";

          setContactEmailError(msg);
        } else {
          setContactEmailError(null);
        }

        if (errorCount > 0) {
          return true;
        } else {
          return false;
        }
      }
      if (item === "CONTACT" || item === undefined) {
        if (!edit_user_phone) {
          errorCount++;
          let msg = "Please enter a valid number.";
          setPhoneError(msg);
        } else {
          setPhoneError(null);
        }

        let contactEmailError = SERVICE.validateEmail(edit_user_email);
        if (contactEmailError !== undefined) {
          errorCount++;
          let msg =
            contactEmailError === "required"
              ? "Please enter your email address."
              : "Please enter a valid email address.";

          setContactEmailError(msg);
        } else {
          setContactEmailError(null);
        }

        if (errorCount > 0) {
          return true;
        } else {
          return false;
        }
      }

      if (item === "COMPANY" || item === undefined) {
        if (!edit_company_name) {
          errorCount++;
          let msg = "Please enter name.";
          setContactCompanyNameError(msg);
        } else {
          setContactCompanyNameError(null);
        }

        if (!edit_user_company_address) {
          errorCount++;
          let msg = "Please enter address.";
          setCompanyAddressError(msg);
        } else {
          setCompanyAddressError(null);
        }

        if (!edit_user_siret) {
          errorCount++;
          let msg = `Please Enter ${selOption ? "SIRET" : "DUNS"}.`;
          setUserSiretError(msg);
        } else {
          setUserSiretError(null);
        }

        if (errorCount > 0) {
          return true;
        } else {
          return false;
        }
      }

      if (item === "PAYMENT" || item === undefined) {
        if (!edit_user_iban) {
          errorCount++;
          let msg = "Please enter IBAN.";
          setIbanError(msg);
        } else {
          setIbanError(null);
        }
        if (!edit_user_bic) {
          errorCount++;
          let msg = "Please enter BIC.";
          setBicError(msg);
        } else {
          setBicError(null);
        }

        if (errorCount > 0) {
          return true;
        } else {
          return false;
        }
      }
    } else if (isRoleStaff) {
      if (item === "MANAGER" || item === undefined) {
        if (!edit_manager_first_name) {
          errorCount++;
          let msg = "Please enter first name.";
          setUserFirstNameErr(msg);
        } else {
          setUserFirstNameErr(null);
        }

        if (!edit_manager_last_name) {
          errorCount++;
          let msg = "Please enter last name.";
          setUserLastNameErr(msg);
        } else {
          setUserLastNameErr(null);
        }

        if (!edit_manager_phone) {
          errorCount++;
          let msg = "Please enter a valid number.";
          setPhoneError(msg);
        } else {
          setPhoneError(null);
        }

        let contactEmailError = SERVICE.validateEmail(edit_manager_email);
        if (contactEmailError !== undefined) {
          errorCount++;
          let msg =
            contactEmailError === "required"
              ? "Please enter your email address."
              : "Please enter a valid email address.";

          setContactEmailError(msg);
        } else {
          setContactEmailError(null);
        }

        if (errorCount > 0) {
          return true;
        } else {
          return false;
        }
      }
      if (item === "CONTACT" || item === undefined) {
        if (!edit_user_phone) {
          errorCount++;
          let msg = "Please enter a valid number.";
          setPhoneError(msg);
        } else {
          setPhoneError(null);
        }

        let contactEmailError = SERVICE.validateEmail(edit_user_email);
        if (contactEmailError !== undefined) {
          errorCount++;
          let msg =
            contactEmailError === "required"
              ? "Please enter your email address."
              : "Please enter a valid email address.";

          setContactEmailError(msg);
        } else {
          setContactEmailError(null);
        }

        if (errorCount > 0) {
          return true;
        } else {
          return false;
        }
      }

      if (item === "COMPANY" || item === undefined) {
        if (!edit_company_name) {
          errorCount++;
          let msg = "Please enter name.";
          setContactCompanyNameError(msg);
        } else {
          setContactCompanyNameError(null);
        }

        if (!edit_user_company_address) {
          errorCount++;
          let msg = "Please enter address.";
          setCompanyAddressError(msg);
        } else {
          setCompanyAddressError(null);
        }

        if (!edit_user_siret) {
          errorCount++;
          let msg = `Please Enter ${selOption ? "SIRET" : "DUNS"}.`;
          setUserSiretError(msg);
        } else {
          setUserSiretError(null);
        }

        if (errorCount > 0) {
          return true;
        } else {
          return false;
        }
      }

      if (item === "PAYMENT" || item === undefined) {
        if (!edit_user_iban) {
          errorCount++;
          let msg = "Please enter IBAN.";
          setIbanError(msg);
        } else {
          setIbanError(null);
        }
        if (!edit_user_bic) {
          errorCount++;
          let msg = "Please enter BIC.";
          setBicError(msg);
        } else {
          setBicError(null);
        }

        if (errorCount > 0) {
          return true;
        } else {
          return false;
        }
      }
    } else if (isRoleAdmin) {
      if (item === "MANAGER" || item === undefined) {
        if (!edit_manager_first_name) {
          errorCount++;
          let msg = "Please enter first name.";
          setUserFirstNameErr(msg);
        } else {
          setUserFirstNameErr(null);
        }

        if (!edit_manager_last_name) {
          errorCount++;
          let msg = "Please enter last name.";
          setUserLastNameErr(msg);
        } else {
          setUserLastNameErr(null);
        }

        if (!edit_manager_phone) {
          errorCount++;
          let msg = "Please enter a valid number.";
          setPhoneError(msg);
        } else {
          setPhoneError(null);
        }

        let contactEmailError = SERVICE.validateEmail(edit_manager_email);
        if (contactEmailError !== undefined) {
          errorCount++;
          let msg =
            contactEmailError === "required"
              ? "Please enter your email address."
              : "Please enter a valid email address.";

          setContactEmailError(msg);
        } else {
          setContactEmailError(null);
        }

        if (errorCount > 0) {
          return true;
        } else {
          return false;
        }
      }
      if (item === "CONTACT" || item === undefined) {
        if (!edit_user_phone) {
          errorCount++;
          let msg = "Please enter a valid number.";
          setPhoneError(msg);
        } else {
          setPhoneError(null);
        }

        let contactEmailError = SERVICE.validateEmail(edit_user_email);
        if (contactEmailError !== undefined) {
          errorCount++;
          let msg =
            contactEmailError === "required"
              ? "Please enter your email address."
              : "Please enter a valid email address.";

          setContactEmailError(msg);
        } else {
          setContactEmailError(null);
        }

        if (errorCount > 0) {
          return true;
        } else {
          return false;
        }
      }

      if (item === "COMPANY" || item === undefined) {
        if (!edit_company_name) {
          errorCount++;
          let msg = "Please enter name.";
          setContactCompanyNameError(msg);
        } else {
          setContactCompanyNameError(null);
        }

        if (!edit_user_company_address) {
          errorCount++;
          let msg = "Please enter address.";
          setCompanyAddressError(msg);
        } else {
          setCompanyAddressError(null);
        }

        if (!edit_user_siret) {
          errorCount++;
          let msg = `Please Enter ${selOption ? "SIRET" : "DUNS"}.`;
          setUserSiretError(msg);
        } else {
          setUserSiretError(null);
        }

        if (errorCount > 0) {
          return true;
        } else {
          return false;
        }
      }

      if (item === "PAYMENT" || item === undefined) {
        if (!edit_user_iban) {
          errorCount++;
          let msg = "Please enter IBAN.";
          setIbanError(msg);
        } else {
          setIbanError(null);
        }
        if (!edit_user_bic) {
          errorCount++;
          let msg = "Please enter BIC.";
          setBicError(msg);
        } else {
          setBicError(null);
        }

        if (errorCount > 0) {
          return true;
        } else {
          return false;
        }
      }
    } else if (isRoleClient) {
      if (item === "MANAGER" || item === undefined) {
        if (!edit_manager_first_name) {
          errorCount++;
          let msg = "Please enter first name.";
          setUserFirstNameErr(msg);
        } else {
          setUserFirstNameErr(null);
        }

        if (!edit_manager_last_name) {
          errorCount++;
          let msg = "Please enter last name.";
          setUserLastNameErr(msg);
        } else {
          setUserLastNameErr(null);
        }

        if (!edit_manager_phone) {
          errorCount++;
          let msg = "Please enter a valid number.";
          setPhoneError(msg);
        } else {
          setPhoneError(null);
        }

        let contactEmailError = SERVICE.validateEmail(edit_manager_email);
        if (contactEmailError !== undefined) {
          errorCount++;
          let msg =
            contactEmailError === "required"
              ? "Please enter your email address."
              : "Please enter a valid email address.";

          setContactEmailError(msg);
        } else {
          setContactEmailError(null);
        }

        if (errorCount > 0) {
          return true;
        } else {
          return false;
        }
      }
      if (item === "CONTACT" || item === undefined) {
        if (!edit_user_phone) {
          errorCount++;
          let msg = "Please enter a valid number.";
          setPhoneError(msg);
        } else {
          setPhoneError(null);
        }

        let contactEmailError = SERVICE.validateEmail(edit_user_email);
        if (contactEmailError !== undefined) {
          errorCount++;
          let msg =
            contactEmailError === "required"
              ? "Please enter your email address."
              : "Please enter a valid email address.";

          setContactEmailError(msg);
        } else {
          setContactEmailError(null);
        }

        if (errorCount > 0) {
          return true;
        } else {
          return false;
        }
      }

      if (item === "COMPANY" || item === undefined) {
        if (!edit_company_name) {
          errorCount++;
          let msg = "Please enter name.";
          setContactCompanyNameError(msg);
        } else {
          setContactCompanyNameError(null);
        }

        if (!edit_user_company_address) {
          errorCount++;
          let msg = "Please enter address.";
          setCompanyAddressError(msg);
        } else {
          setCompanyAddressError(null);
        }

        if (!edit_user_siret) {
          errorCount++;
          let msg = `Please Enter ${selOption ? "SIRET" : "DUNS"}.`;
          setUserSiretError(msg);
        } else {
          setUserSiretError(null);
        }

        if (errorCount > 0) {
          return true;
        } else {
          return false;
        }
      }

      if (item === "PAYMENT" || item === undefined) {
        if (!edit_user_iban) {
          errorCount++;
          let msg = "Please enter IBAN.";
          setIbanError(msg);
        } else {
          setIbanError(null);
        }
        if (!edit_user_bic) {
          errorCount++;
          let msg = "Please enter BIC.";
          setBicError(msg);
        } else {
          setBicError(null);
        }

        if (errorCount > 0) {
          return true;
        } else {
          return false;
        }
      }
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB3cLetAkii900cvYPIrvxILEJ6qUhpG4I&libraries=places`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setEditUserProfilePic(reader.result);
      setImageSrc(event.target.files[0]);
      setIsImage(true);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // Phone code
  useEffect(() => {
    getCounrtyCodeList();
  }, []);

  const [phoneNumberCode, setPhoneNumberCode] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+33");
  const [unLoadingCountryCode, setUnLoadingCountryCode] = useState("+33");

  const getCounrtyCodeList = () => {
    const payload = {};
    country_PhoneNumberCode(payload).then((res) => {
      setPhoneNumberCode(res.data);
    });
  };

  function clear_edited_states() {
    setEditUserFirstName(user_first_name);
    setEditUserLastName(user_last_name);
    setEditUserPhone(user_phone);
    setEditUserEmail(user_email);
    setEditCompanyName(company_name);
    setEditUserDuns(user_duns);
    setEditUserCompanyAddress(user_company_address);
    setEditUserIban(user_iban);
    setEditUserVat(user_vat);
    setEditUserSiret(user_siret);
    setEditUserBic(user_bic);
    setEditUserCountryCode(user_country_code);

    if (userRole == "User") {
      setEditManageFirstrName(accountOnwerFirstName);
      setEditManagerLastName(accountOnwerLastName);
      setEditManagerEmail(accountEmail);
      setEditManagerPhone(accountPhoneNumber);
      setEditManagerCountryCode(contactCode);
    } else {
      setEditManageFirstrName(accountOwnerFirstName);
      setEditManagerLastName(accountOwnerLastName);
      setEditManagerEmail(accountOwnerEmail);
      setEditManagerPhone(accountOwnerPhoneNumber);
      setEditManagerCountryCode(contactCode);
    }
    if (user_profile_pic) {
      setEditUserProfilePic(BASE_URL + "server/" + user_profile_pic);
    }

    setImageSrc("");
  }

  return (
    <React.Fragment>
      <div className="d-flex" id="wrapper">
        <SideBar />
        <div id="page-content-wrapper">
          <NavBar />

          {/* CONTAINER FOR ADMIN ROLE  */}

          {isRoleAdmin && (
            <Container fluid className="pt-120">
              <div className="quotes_div accountBox">
                <Row>
                  <Col lg={5} md={6}>
                    <div className="account_box ">
                      <div className="userInfo">
                        {user_profile_pic && user_profile_pic.length > 0 ? (
                          <Image
                            className="logo ms-3"
                            src={BASE_URL + "server/" + user_profile_pic}
                            alt=""
                          />
                        ) : (
                          <HiUser size={100} />
                        )}

                        <div className="uspad">
                          <h1>{company_name}</h1>

                          <h3>{user_account_number}</h3>
                        </div>
                        <div className="langDiv">
                          <Select
                            defaultValue={options[0]}
                            onChange={handlesetSelectedOption}
                            options={options}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={7} md={6}>
                    <div className="braedContact ">
                      <Image className="tIcon" src={MessageIcon2} alt="" />
                      <h3>CONTACT</h3>

                      <Link
                        onClick={() => {
                          setContactModal(true);
                          setModalData({
                            phone: user_phone,
                            code: user_country_code,
                            email: user_email,
                            selectedCountryCode: selectedCountryCode,
                          });
                        }}
                      >
                        <Image className="tIcon" src={EditIcon2} alt="" />
                      </Link>
                    </div>
                    <div class="account_body braedContactFull braedContactFull1">
                      <div>
                        <h3>Phone</h3>
                        <h4>
                          {" "}
                          {user_phone && user_country_code
                            ? `${user_country_code} ${user_phone}`
                            : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Email</h3>
                        <h4>{user_email ? user_email : "N/A"}</h4>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12}>
                    <div className="braedContact mt-4">
                      <Image className="tIcon" src={BuildingIcon2} alt="" />
                      <h3>COMPANY</h3>

                      <Link
                        onClick={() => {
                          setCompanyModal(true);
                          setModalData({
                            Name: company_name,
                            DUNS: duns,
                            Address: user_company_address,
                            SIRET: user_siret,
                          });
                        }}
                      >
                        <Image className="tIcon" src={EditIcon2} alt="" />
                      </Link>
                    </div>
                    <div class="account_body braedContactFull1">
                      <div>
                        <h3>Name</h3>
                        <h4>{company_name ? company_name : "N/A"}</h4>
                      </div>

                      <div className="account_mleft">
                        {/* <h3>{selOption ? "SIRET" : "DUNS"}</h3> */}
                        <h3>{"DUNS"}</h3>

                        {/* <h4>{user_siret ? user_siret : "N/A"}</h4> */}
                        <h4>{duns ? duns : "N/A"}</h4>
                      </div>
                      <div>
                        <h3>RCS</h3>
                        <h4>{rcs ? rcs : "N/A"}</h4>
                      </div>
                      <div>
                        <h3>VAT number</h3>
                        <h4>{vat_number ? vat_number : "N/A"}</h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Address</h3>
                        <h4>
                          {user_company_address ? user_company_address : "N/A"}
                        </h4>
                      </div>

                      {/* <div className="account_mleft">
                  <h3>VAT</h3>
                  <h4>{user_vat ? user_vat : "N/A"}</h4>
                </div> */}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <div className="braedContact mt-4">
                      <Image className="tIcon" src={UserSquare} alt="" />
                      <h3>Account Owner</h3>
                      <Link
                        onClick={() => {
                          setAccountModal(true);
                          setModalData({
                            Name: accountName ? accountName : accountOnwerName,
                            Phone: accountPhoneNumber,
                            Email: accountEmail,
                          });
                        }}
                      >
                        <Image className="tIcon" src={EditIcon2} alt="" />
                      </Link>
                    </div>
                    <div class="account_body account_bodyPad  braedContactFull1">
                      <div>
                        <h3>First Name</h3>
                        <h4>
                          {manager_first_name ? `${manager_first_name}` : "N/A"}
                        </h4>
                      </div>

                      <div className="account_mleft" style={{ paddingLeft: 0 }}>
                        <h3>Last Name</h3>
                        <h4>
                          {manager_last_name ? `${manager_last_name}` : "N/A"}
                        </h4>
                      </div>
                      <div>
                        <h3>Phone</h3>
                        <h4 style={{ minWidth: "115px" }}>
                          {manager_phone && manager_country_code
                            ? `${manager_country_code} ${manager_phone}`
                            : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Email</h3>
                        <h4>{manager_email ? manager_email : "N/A"}</h4>
                      </div>
                    </div>
                    {/* <div class="account_body align-items-start">
                      <div>
                        <h3>Name</h3>
                        <h4>
                          {manager_last_name
                            ? `${manager_last_name} ${manager_first_name}`
                            : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Phone</h3>
                        <h4>
                          {manager_phone && manager_country_code
                            ? `${manager_country_code} ${manager_phone}`
                            : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Email</h3>
                        <h4>{manager_email ? manager_email : "N/A"}</h4>
                      </div>
                    </div> */}
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="braedContact mt-4">
                      <Image className="tIcon" src={PaymentIcon2} alt="" />
                      <h3>PAYMENT </h3>
                      <Link
                        onClick={() => {
                          setPaymentModal(true);
                          setModalData({
                            iban: user_iban,
                            bic: user_bic,
                          });
                        }}
                      >
                        <Image className="tIcon" src={EditIcon2} alt="" />
                      </Link>
                    </div>

                    <div class="account_body braedContactFull1">
                      <div>
                        <h3>IBAN</h3>
                        <h4 className="mb-1">
                          {user_iban ? user_iban : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>BIC</h3>
                        <h4> {user_bic ? user_bic : "N/A"}</h4>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          )}

          {/* CONTAINER FOR STAFF ROLE */}
          {isRoleStaff && (
            <Container fluid className="pt-120">
              <div className="quotes_div accountBox">
                <Row>
                  <Col lg={5} md={6}>
                    <div className="account_box">
                      <div className="userInfo">
                        {user_profile_pic && user_profile_pic.length > 0 ? (
                          <Image
                            className="logo ms-3"
                            src={BASE_URL + "server/" + user_profile_pic}
                            alt=""
                          />
                        ) : (
                          <HiUser size={100} />
                        )}

                        <div className="uspad">
                          <h1>{`${user_first_name} ${user_last_name}`}</h1>
                          <h3>{`${Position}`}</h3>

                          <h3>{user_account_number}</h3>
                        </div>
                        <div className="langDiv">
                          <Select
                            defaultValue={options[0]}
                            onChange={handlesetSelectedOption}
                            options={options}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={7} md={6}>
                    <div className="braedContact">
                      <Image className="tIcon" src={MessageIcon2} alt="" />
                      <h3>CONTACT</h3>

                      {/* <Link
                        onClick={() => {
                          setContactModal(true);
                          setModalData({
                            phone: user_phone,
                            code: user_country_code,
                            email: user_email,
                            selectedCountryCode: selectedCountryCode,
                          });
                        }}
                      >
                        <Image className="tIcon" src={EditIcon2} alt="" />
                      </Link> */}
                    </div>
                    <div class="account_body braedContactFull braedContactFull1">
                      <div>
                        <h3>Phone</h3>
                        <h4>
                          {" "}
                          {user_phone && user_country_code
                            ? `${user_country_code} ${user_phone}`
                            : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Email</h3>
                        <h4>{user_email ? user_email : "N/A"}</h4>
                      </div>
                    </div>
                  </Col>
                </Row>
                {userRole != "Staff" && (
                  <Row>
                    <Col lg={12} md={12}>
                      <div className="braedContact mt-4">
                        <Image className="tIcon" src={BuildingIcon2} alt="" />
                        <h3>COMPANY</h3>

                        <Link
                          onClick={() => {
                            setCompanyModal(true);
                            setModalData({
                              Name: company_name,
                              DUNS: duns,
                              Address: user_company_address,
                              SIRET: user_siret,
                            });
                          }}
                        >
                          <Image className="tIcon" src={EditIcon2} alt="" />
                        </Link>
                      </div>
                      <div class="account_body">
                        <div>
                          <h3>Name</h3>
                          <h4>{company_name}</h4>
                        </div>

                        <div className="account_mleft">
                          {/* <h3>{selOption ? "SIRET" : "DUNS"}</h3> */}
                          <h3>{"DUNS"}</h3>

                          {/* <h4>{user_siret ? user_siret : "N/A"}</h4> */}
                          <h4>{duns ? duns : "N/A"}</h4>
                        </div>
                        <div className="account_mleft">
                          <h3>Address</h3>
                          <h4>
                            {user_company_address
                              ? user_company_address
                              : "N/A"}
                          </h4>
                        </div>

                        {/* <div className="account_mleft">
                  <h3>VAT</h3>
                  <h4>{user_vat ? user_vat : "N/A"}</h4>
                </div> */}
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col lg={12} md={12}>
                    <div className="braedContact mt-4">
                      <Image className="tIcon" src={UserSquare} alt="" />
                      <h3>MANAGER</h3>
                      {/* <Link
                        onClick={() => {
                          setAccountModal(true);
                          setModalData({
                            Name: accountName ? accountName : accountOnwerName,
                            Phone: accountPhoneNumber,
                            Email: accountEmail,
                          });
                        }}
                      >
                        <Image className="tIcon" src={EditIcon2} alt="" />
                      </Link> */}
                    </div>
                    <div class="account_body account_bodyPad braedContactFull2">
                      <div>
                        <h3>First Name</h3>
                        <h4>
                          {manager_first_name ? `${manager_first_name}` : "N/A"}
                        </h4>
                      </div>

                      <div className="account_mleft">
                        <h3>Last Name</h3>
                        <h4>
                          {manager_last_name ? `${manager_last_name}` : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Phone</h3>
                        <h4>
                          {manager_phone && manager_country_code
                            ? `${manager_country_code} ${manager_phone}`
                            : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Email</h3>
                        <h4>{manager_email ? manager_email : "N/A"}</h4>
                      </div>
                    </div>
                    {/* <div class="account_body braedContactFull">
                      <div>
                        <h3>Name</h3>
                        <h4>
                          {manager_last_name
                            ? `${manager_last_name} ${manager_first_name}`
                            : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Phone</h3>
                        <h4>
                          {manager_phone && manager_country_code
                            ? `${manager_country_code} ${manager_phone}`
                            : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Email</h3>
                        <h4>{manager_email ? manager_email : "N/A"}</h4>
                      </div>
                    </div> */}
                  </Col>
                  {userRole != "Staff" && (
                    <Col lg={6} md={6}>
                      <div className="braedContact mt-4">
                        <Image className="tIcon" src={PaymentIcon2} alt="" />
                        <h3>PAYMENT </h3>
                        {/* <Link
                        onClick={() => {
                          setPaymentModal(true);
                          setModalData({
                            iban: user_iban,
                            bic: user_bic,
                          });
                        }}
                      >
                        <Image className="tIcon" src={EditIcon2} alt="" />
                      </Link> */}
                      </div>

                      <div class="account_body">
                        <div>
                          <h3>IBAN</h3>
                          <h4 className="mb-1">
                            {user_iban ? user_iban : "N/A"}
                          </h4>
                        </div>
                        <div className="account_mleft">
                          <h3>BIC</h3>
                          <h4> {user_bic ? user_bic : "N/A"}</h4>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </Container>
          )}

          {/* CONTAINER FOR USER ROLE */}
          {isRoleUser && (
            <Container fluid className="pt-120">
              <div className="quotes_div accountBox">
                <Row>
                  <Col lg={5} md={6}>
                    <div className="account_box">
                      <div className="userInfo">
                        {user_profile_pic && user_profile_pic.length > 0 ? (
                          <Image
                            className="logo ms-3"
                            src={BASE_URL + "server/" + user_profile_pic}
                            alt=""
                          />
                        ) : (
                          <HiUser size={100} />
                        )}

                        <div className="uspad">
                          <h1>
                            {[contact_first_name, " ", contact_last_name]}
                          </h1>

                          <h3>{user_account_number}</h3>
                        </div>
                        <div className="langDiv">
                          <Select
                            defaultValue={options[0]}
                            onChange={handlesetSelectedOption}
                            options={options}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={7} md={6}>
                    <div className="braedContact ">
                      <Image className="tIcon" src={MessageIcon2} alt="" />
                      <h3>CONTACT</h3>

                      <Link
                        onClick={() => {
                          setContactModal(true);
                          setModalData({
                            phone: user_phone,
                            code: user_country_code,
                            email: user_email,
                            selectedCountryCode: selectedCountryCode,
                          });
                        }}
                      >
                        <Image className="tIcon" src={EditIcon2} alt="" />
                      </Link>
                    </div>
                    <div class="account_body braedContactFull braedContactFull1">
                      <div>
                        <h3>Phone</h3>
                        <h4>{`${user_country_code} ${user_phone}`}</h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Email</h3>
                        <h4>{user_email}</h4>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12}>
                    <div className="braedContact mt-4">
                      <Image className="tIcon" src={BuildingIcon2} alt="" />
                      <h3>COMPANY</h3>

                      {/* <Link
                        onClick={() => {
                          setCompanyModal(true);
                          setModalData({
                            Name: company_name,
                            DUNS: duns,
                            Address: user_company_address,
                            SIRET: user_siret,
                          });
                        }}
                      >
                        <Image className="tIcon" src={EditIcon2} alt="" />
                      </Link> */}
                    </div>
                    <div class="account_body braedContactFull">
                      <div>
                        <h3>Name</h3>
                        <h4>{company_name}</h4>
                      </div>

                      <div className="account_mleft">
                        {/* <h3>{selOption ? "SIRET" : "DUNS"}</h3> */}
                        <h3>{"DUNS"}</h3>

                        <h4>{duns ? duns : "N/A"}</h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Address</h3>
                        <h4>
                          {user_company_address ? user_company_address : "N/A"}
                        </h4>
                      </div>

                      {/* <div className="account_mleft">
                     <h3>VAT</h3>
                     <h4>{user_vat ? user_vat : "N/A"}</h4>
                   </div> */}
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="braedContact mt-4">
                      <Image className="tIcon" src={UserSquare} alt="" />
                      <h3 className="text-uppercase">Account Owner</h3>
                      {/* <Link>
                        <Image className="tIcon" src={EditIcon2} alt="" />
                      </Link> */}
                      <Link
                        onClick={() => {
                          setAccountModal(true);
                          setModalData({
                            Name: accountName ? accountName : accountOnwerName,
                            Phone: accountPhoneNumber,
                            Email: accountEmail,
                          });
                        }}
                      >
                        <Image className="tIcon" src={EditIcon2} alt="" />
                      </Link>
                    </div>
                    <div class="account_body account_bodyPad">
                      <div>
                        <h3>First Name</h3>
                        <h4>
                          {manager_first_name ? `${manager_first_name}` : "N/A"}
                        </h4>
                      </div>

                      <div className="account_mleft">
                        <h3>Last Name</h3>
                        <h4>
                          {manager_last_name ? `${manager_last_name}` : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Phone</h3>
                        {console.log(
                          "manager_country_code",
                          `${manager_country_code} ${manager_phone}`
                        )}
                        <h4>
                          {manager_phone && manager_country_code
                            ? `${manager_country_code} ${manager_phone}`
                            : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Email</h3>
                        <h4>{manager_email ? manager_email : "N/A"}</h4>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="braedContact mt-4">
                      <Image className="tIcon" src={PaymentIcon2} alt="" />
                      <h3>PAYMENT </h3>
                      {/* <Link
                        onClick={() => {
                          setPaymentModal(true);
                          setModalData({
                            iban: user_iban,
                            bic: user_bic,
                          });
                        }}
                      >
                        <Image className="tIcon" src={EditIcon2} alt="" />
                      </Link> */}
                    </div>

                    <div class="account_body">
                      <div>
                        <h3>IBAN</h3>
                        <h4 className="mb-1">
                          {user_iban ? user_iban : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>BIC</h3>
                        <h4> {user_bic ? user_bic : "N/A"}</h4>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          )}
          {/* CONTAINER FOR CARRIER ROLE */}
          {isRoleCarrier && (
            <Container fluid className="pt-120">
              <div className="quotes_div accountBox">
                <Row>
                  <Col lg={5} md={6}>
                    <div className="account_box">
                      <div className="userInfo">
                        {user_profile_pic && user_profile_pic.length > 0 ? (
                          <Image
                            className="logo ms-3"
                            src={BASE_URL + "server/" + user_profile_pic}
                            alt=""
                          />
                        ) : (
                          <HiUser size={100} />
                        )}

                        <div className="uspad">
                          <h1>
                            {[contact_first_name, " ", contact_last_name]}
                          </h1>

                          <h3>{user_account_number}</h3>
                        </div>
                        <div className="langDiv">
                          <Select
                            defaultValue={options[0]}
                            onChange={handlesetSelectedOption}
                            options={options}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={7} md={6}>
                    <div className="braedContact">
                      <Image className="tIcon" src={MessageIcon2} alt="" />
                      <h3>CONTACT</h3>

                      <Link
                        onClick={() => {
                          setContactModal(true);
                          setModalData({
                            phone: user_phone,
                            code: user_country_code,
                            email: user_email,
                            selectedCountryCode: selectedCountryCode,
                          });
                        }}
                      >
                        <Image className="tIcon" src={EditIcon2} alt="" />
                      </Link>
                    </div>
                    <div class="account_body braedContactFull braedContactFull1">
                      <div>
                        <h3>Phone</h3>
                        <h4>
                          {" "}
                          {user_phone && user_country_code
                            ? `${user_country_code} ${user_phone}`
                            : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Email</h3>
                        <h4>{user_email ? user_email : "N/A"}</h4>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12}>
                    <div className="braedContact  mt-4">
                      <Image className="tIcon" src={BuildingIcon2} alt="" />
                      <h3>COMPANY</h3>

                      {/* <Link
                        onClick={() => {
                          setCompanyModal(true);
                          setModalData({
                            Name: company_name,
                            DUNS: duns,
                            Address: user_company_address,
                            SIRET: user_siret,
                          });
                        }}
                      >
                        <Image className="tIcon" src={EditIcon2} alt="" />
                      </Link> */}
                    </div>
                    <div class="account_body braedContactFull">
                      <div>
                        <h3>Name</h3>
                        <h4>{company_name}</h4>
                      </div>

                      <div className="account_mleft">
                        {/* <h3>{selOption ? "SIRET" : "DUNS"}</h3> */}
                        <h3>{"DUNS"}</h3>

                        {/* <h4>{user_siret ? user_siret : "N/A"}</h4> */}
                        <h4>{DUNS ? DUNS : "N/A"}</h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Address</h3>
                        <h4>
                          {user_company_address ? user_company_address : "N/A"}
                        </h4>
                      </div>

                      {/* <div className="account_mleft">
                  <h3>VAT</h3>
                  <h4>{user_vat ? user_vat : "N/A"}</h4>
                </div> */}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <div className="braedContact mt-4">
                      <Image className="tIcon" src={UserSquare} alt="" />
                      <h3>Account Owner</h3>
                      <Link
                        onClick={() => {
                          setAccountModal(true);
                          setModalData({
                            Name: accountName ? accountName : accountOnwerName,
                            Phone: accountPhoneNumber,
                            Email: accountEmail,
                          });
                        }}
                      >
                        <Image className="tIcon" src={EditIcon2} alt="" />
                      </Link>
                    </div>
                    <div class="account_body account_bodyPad">
                      <div>
                        <h3>First Name</h3>
                        <h4>
                          {manager_first_name ? `${manager_first_name}` : "N/A"}
                        </h4>
                      </div>

                      <div className="account_mleft">
                        <h3>Last Name</h3>
                        <h4>
                          {manager_last_name ? `${manager_last_name}` : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Phone</h3>
                        <h4>
                          {manager_phone && manager_country_code
                            ? `${manager_country_code} ${manager_phone}`
                            : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Email</h3>
                        <h4>{manager_email ? manager_email : "N/A"}</h4>
                      </div>
                    </div>
                    {/* <div class="account_body align-items-start">
                      <div>
                        <h3>Name</h3>
                        <h4>
                          {manager_last_name
                            ? `${manager_last_name} ${manager_first_name}`
                            : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Phone</h3>
                        <h4>
                          {manager_phone && manager_country_code
                            ? `${manager_country_code} ${manager_phone}`
                            : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Email</h3>
                        <h4>{manager_email ? manager_email : "N/A"}</h4>
                      </div>
                    </div> */}
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="braedContact mt-4">
                      <Image className="tIcon" src={PaymentIcon2} alt="" />
                      <h3>PAYMENT </h3>
                      {/* <Link
                        onClick={() => {
                          setPaymentModal(true);
                          setModalData({
                            iban: user_iban,
                            bic: user_bic,
                          });
                        }}
                      >
                        <Image className="tIcon" src={EditIcon2} alt="" />
                      </Link> */}
                    </div>

                    <div class="account_body">
                      <div>
                        <h3>IBAN</h3>
                        <h4 className="mb-1">
                          {user_iban ? user_iban : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>BIC</h3>
                        <h4> {user_bic ? user_bic : "N/A"}</h4>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          )}

          {/* CONTAINER FOR CLIENT ROLE */}
          {isRoleClient && (
            <Container fluid className="pt-120">
              <div className="quotes_div accountBox">
                <Row>
                  <Col lg={5} md={6}>
                    <div className="account_box">
                      <div className="userInfo">
                        {user_profile_pic && user_profile_pic.length > 0 ? (
                          <Image
                            className="logo ms-3"
                            src={BASE_URL + "server/" + user_profile_pic}
                            alt=""
                          />
                        ) : (
                          <HiUser size={100} />
                        )}

                        <div className="uspad">
                          {/* <h1>{company_name}</h1> */}
                          <h1>{`${manager_first_name} ${manager_last_name}`}</h1>

                          <h3>{user_account_number}</h3>
                        </div>
                        <div className="langDiv">
                          <Select
                            defaultValue={options[0]}
                            onChange={handlesetSelectedOption}
                            options={options}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={7} md={6}>
                    <div className="braedContact">
                      <Image className="tIcon" src={MessageIcon2} alt="" />
                      <h3>CONTACT</h3>

                      <Link
                        onClick={() => {
                          setContactModal(true);
                          setModalData({
                            phone: user_phone,
                            code: user_country_code,
                            email: user_email,
                            selectedCountryCode: selectedCountryCode,
                          });
                        }}
                      >
                        <Image className="tIcon" src={EditIcon2} alt="" />
                      </Link>
                    </div>
                    <div class="account_body braedContactFull braedContactFull1">
                      <div>
                        <h3>Phone</h3>
                        <h4>
                          {" "}
                          {user_phone && user_country_code
                            ? `${user_country_code} ${user_phone}`
                            : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Email</h3>
                        <h4>{user_email ? user_email : "N/A"}</h4>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12}>
                    <div className="braedContact mt-4">
                      <Image className="tIcon" src={BuildingIcon2} alt="" />
                      <h3>COMPANY</h3>

                      {/* <Link
                        onClick={() => {
                          setCompanyModal(true);
                          setModalData({
                            Name: company_name,
                            DUNS: duns,
                            Address: user_company_address,
                            SIRET: user_siret,
                          });
                        }}
                      >
                        <Image className="tIcon" src={EditIcon2} alt="" />
                      </Link> */}
                    </div>
                    <div class="account_body braedContactFull">
                      <div>
                        <h3>Name</h3>
                        <h4>{company_name}</h4>
                      </div>

                      <div className="account_mleft">
                        {/* <h3>{selOption ? "SIRET" : "DUNS"}</h3> */}
                        <h3>{"DUNS"}</h3>

                        {/* <h4>{user_siret ? user_siret : "N/A"}</h4> */}
                        <h4>{DUNS ? DUNS : "N/A"}</h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Address</h3>
                        <h4>
                          {user_company_address ? user_company_address : "N/A"}
                        </h4>
                      </div>

                      {/* <div className="account_mleft">
                  <h3>VAT</h3>
                  <h4>{user_vat ? user_vat : "N/A"}</h4>
                </div> */}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <div className="braedContact mt-4">
                      <Image className="tIcon" src={UserSquare} alt="" />
                      <h3>Account Owner </h3>
                      <Link
                        onClick={() => {
                          setAccountModal(true);
                          setModalData({
                            Name: accountName ? accountName : accountOnwerName,
                            Phone: accountPhoneNumber,
                            Email: accountEmail,
                          });
                        }}
                      >
                        <Image className="tIcon" src={EditIcon2} alt="" />
                      </Link>
                    </div>
                    <div class="account_body account_bodyPad">
                      <div>
                        <h3>First Name</h3>
                        <h4>
                          {manager_first_name ? `${manager_first_name}` : "N/A"}
                        </h4>
                      </div>

                      <div className="account_mleft">
                        <h3>Last Name</h3>
                        <h4>
                          {manager_last_name ? `${manager_last_name}` : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Phone</h3>
                        <h4>
                          {manager_phone && manager_country_code
                            ? `${manager_country_code} ${manager_phone}`
                            : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Email</h3>
                        <h4>{manager_email ? manager_email : "N/A"}</h4>
                      </div>
                    </div>
                    {/* <div class="account_body align-items-start">
                      <div>
                        <h3>Name</h3>
                        <h4>
                          {manager_last_name
                            ? `${manager_last_name} ${manager_first_name}`
                            : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Phone</h3>
                        <h4>
                          {manager_phone && manager_country_code
                            ? `${manager_country_code} ${manager_phone}`
                            : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>Email</h3>
                        <h4>{manager_email ? manager_email : "N/A"}</h4>
                      </div>
                    </div> */}
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="braedContact mt-4">
                      <Image className="tIcon" src={PaymentIcon2} alt="" />
                      <h3>PAYMENT </h3>
                      {/* <Link
                        onClick={() => {
                          setPaymentModal(true);
                          setModalData({
                            iban: user_iban,
                            bic: user_bic,
                          });
                        }}
                      >
                        <Image className="tIcon" src={EditIcon2} alt="" />
                      </Link> */}
                    </div>

                    <div class="account_body">
                      <div>
                        <h3>IBAN</h3>
                        <h4 className="mb-1">
                          {user_iban ? user_iban : "N/A"}
                        </h4>
                      </div>
                      <div className="account_mleft">
                        <h3>BIC</h3>
                        <h4> {user_bic ? user_bic : "N/A"}</h4>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          )}
        </div>

        <Modal
          show={contactModal}
          onHide={() => {
            // clear_edited_states();
            setContactModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>EDIT CONTACT</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="uploadBtn uploadBtn1">
                <input
                  id="upload"
                  type="file"
                  name="myfile"
                  accept=".png,.jpg"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
              <div className="image-container text-center">
                {edit_user_profile_pic ? (
                  <img
                    src={edit_user_profile_pic}
                    style={{
                      width: 100,
                      height: 100,
                      borderRadius: 60,
                      border: "1px solid #ccc",
                    }}
                    alt="Uploaded"
                    className="logo"
                  />
                ) : (
                  <HiUser size={100} />
                )}
                <label
                  style={{ display: "block" }}
                  htmlFor="upload"
                  className="uploadBtnLabel"
                >
                  <Image className="tIcon" src={EditIcon2} alt="" />
                  {/* Upload Image */}
                </label>
              </div>
            </div>
            {/* 
            {isRoleUser && (
              <TextInputWithLabel
                label="Last Name"
                placeholder="Please Enter Last Name"
                type="text"
                reqstart="*"
                value={edit_user_last_name}
                onChange={(text) => setEditUserLastName(text.target.value)}
              />
            )}

            {userLastNameErr && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {userLastNameErr}
              </div>
            ) : null}
            {isRoleUser && (
              <TextInputWithLabel
                label="First Name"
                placeholder="Please Enter First Name"
                type="text"
                reqstart="*"
                value={edit_user_first_name}
                onChange={(text) => setEditUserFirstName(text.target.value)}
              />
            )}
            {userFirstNameErr && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {userFirstNameErr}
              </div>
            ) : null} */}

            <div className="counCode countFLEX">
              <Form.Select
                size="lg"
                value={edit_user_country_code}
                onChange={(event) => {
                  setEditUserCountryCode(event.target.value);
                }}
              >
                {phoneNumberCode.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option && option.abbreviation} ({option.code})
                  </option>
                ))}
              </Form.Select>
              <TextInputWithLabel
                label="Contact phone"
                placeholder="Enter a phone number"
                type="text"
                reqstart="*"
                value={edit_user_phone}
                onChange={(text) => {
                  setEditUserPhone(text.target.value);
                }}
              />
            </div>

            {phoneError && Submit && edit_user_phone ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {phoneError}
              </div>
            ) : null}

            {Submit && !edit_user_phone ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {"This field is required"}
              </div>
            ) : null}
            <TextInputWithLabel
              label="Contact Email"
              placeholder="Please Enter Email"
              type="text"
              value={edit_user_email}
              reqstart="*"
              onChange={(text) => setEditUserEmail(text.target.value)}
            />
            {contactEmailError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {contactEmailError}
              </div>
            ) : null}
            {(isRoleCarrier || isRoleUser) && (
              <>
                <TextInputWithLabel
                  label="Contact First Name"
                  placeholder="Please Enter Contact First Name"
                  type="text"
                  value={contactFirstName}
                  reqstart="*"
                  onChange={(text) => setContactFirstName(text.target.value)}
                />
                {contactFirstNameError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: -13,
                    }}
                  >
                    {contactFirstNameError}
                  </div>
                ) : null}
                <TextInputWithLabel
                  label="Contact Last Name"
                  placeholder="Please Enter Contact Last Name"
                  type="text"
                  value={contactLastName}
                  reqstart="*"
                  onChange={(text) => setContactLastName(text.target.value)}
                />
                {contactLastNameError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: -13,
                    }}
                  >
                    {contactLastNameError}
                  </div>
                ) : null}
              </>
            )}
            {/* <TextInputWithLabel
              label="Address"
              placeholder="Please Enter Address"
              type="text"
              reqstart="*"
              value={edit_user_company_address}
              onChange={(text) => setEditUserCompanyAddress(text.target.value)}
            />

            {companyAddressError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {companyAddressError}
              </div>
            ) : null} */}
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ width: 154 }}
              variant="primary"
              onClick={() => onSubmit("CONTACT")}
              disabled={editContactLoading}
              className="btn-ok"
            >
              {editContactLoading ? <Loader /> : "Save Changes"}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={companyModal}
          onHide={() => {
            // clear_edited_states();
            setCompanyModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>EDIT COMPANY</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TextInputWithLabel
              label="Name"
              placeholder="Enter a name"
              type="text"
              reqstart="*"
              value={edit_company_name}
              onChange={(text) => setEditCompanyName(text.target.value)}
              readOnly={isRoleUser ? true : false}
            />
            {conTactCompanyNameError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {conTactCompanyNameError}
              </div>
            ) : null}

            <TextInputWithLabel
              label={selOption ? "SIRET" : "DUNS"}
              placeholder={`Please Enter ${selOption ? "SIRET" : "DUNS"}`}
              type="text"
              reqstart="*"
              value={edit_user_siret}
              onChange={(text) => {
                setEditUserSiret(text.target.value);
              }}
            />
            {userSiretError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {userSiretError}
              </div>
            ) : null}
            <TextInputWithLabel
              label="Address"
              placeholder="Please Enter Address"
              type="text"
              reqstart="*"
              value={edit_user_company_address}
              onChange={(text) => setEditUserCompanyAddress(text.target.value)}
            />

            {companyAddressError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {companyAddressError}
              </div>
            ) : null}

            <TextInputWithLabel
              label="RCS"
              placeholder="Please Enter RCS"
              type="text"
              reqstart="*"
              value={edit_rcs}
              onChange={(text) => setEditRcs(text.target.value)}
            />
            <TextInputWithLabel
              label="VAT NUMBER"
              placeholder="Please Enter VAT NUMBER"
              type="text"
              reqstart="*"
              value={edit_user_vat}
              onChange={(text) => setEditUserVat(text.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ width: 154 }}
              variant="primary"
              onClick={() => onSubmit("COMPANY")}
              disabled={editCompanyLoading}
              className="btn-ok"
            >
              {editCompanyLoading ? <Loader /> : "Save Changes"}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={accountModal}
          onHide={() => {
            // clear_edited_states();
            setAccountModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {isRoleStaff ? "EDIT MANAGER" : "EDIT ACCOUNT OWNER"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TextInputWithLabel
              label="First Name"
              placeholder="Enter a name"
              type="text"
              reqstart="*"
              value={edit_manager_first_name}
              onChange={(text) => setEditManageFirstrName(text.target.value)}
            />
            {userFirstNameErr && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {userFirstNameErr}
              </div>
            ) : null}

            <TextInputWithLabel
              label="Last Name"
              placeholder="Enter a name"
              type="text"
              reqstart="*"
              value={edit_manager_last_name}
              onChange={(text) => setEditManagerLastName(text.target.value)}
            />
            {userLastNameErr && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {userLastNameErr}
              </div>
            ) : null}

            <div className="counCode countFLEX">
              <Form.Select
                size="lg"
                value={edit_manager_country_code}
                onChange={(e) => {
                  setEditManagerCountryCode(e.target.value);
                }}
              >
                {phoneNumberCode.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option && option.abbreviation} ({option.code})
                  </option>
                ))}
              </Form.Select>
              <TextInputWithLabel
                label="Phone"
                placeholder="Enter a phone number"
                type="text"
                reqstart="*"
                value={edit_manager_phone}
                onChange={(text) => {
                  setEditManagerPhone(text.target.value);
                  // SERVICE.setNumberValidation(text, setAccountOwnerPhone);
                }}
              />
            </div>
            {phoneError && Submit && edit_manager_phone ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {phoneError}
              </div>
            ) : null}
            {Submit && !edit_manager_phone ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {"This field is required"}
              </div>
            ) : null}
            <TextInputWithLabel
              label="Email"
              placeholder="Please Enter Email"
              type="text"
              reqstart="*"
              value={edit_manager_email}
              onChange={(text) => setEditManagerEmail(text.target.value)}
            />
            {contactEmailError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {contactEmailError}
              </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="btn-ok"
              onClick={() => onSubmit("MANAGER")}
              style={{ width: 154 }}
              disabled={editManagerLoading}
            >
              {editManagerLoading ? <Loader /> : "Save Changes"}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={paymentModal}
          onHide={() => {
            // clear_edited_states();
            setPaymentModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>EDIT PAYMENT</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TextInputWithLabel
              label="IBAN"
              placeholder="Please Enter IBAN"
              type="text"
              reqstart="*"
              value={edit_user_iban}
              onChange={(text) => {
                if (text.target.value.length <= 25) {
                  setEditUserIban(text.target.value);
                }
              }}
            />
            {ibanError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {ibanError}
              </div>
            ) : null}
            <TextInputWithLabel
              label="BIC"
              placeholder="Please Enter BIC"
              type="text"
              reqstart="*"
              value={edit_user_bic}
              onChange={(text) => {
                if (text.target.value.length <= 25) {
                  setEditUserBic(text.target.value);
                }
              }}
            />
            {bicError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {bicError}
              </div>
            ) : null}
            {/* <TextInputWithLabel
              label="VAT"
              placeholder="Please Enter VAT"
              type="text"
              reqstart="*"
              value={edit_user_vat}
              onChange={(text) => {
                if (text.target.value.length <= 25) {
                  setEditUserVat(text.target.value);
                }
              }}
            /> */}
            {userVatError && Submit ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                  marginTop: -13,
                }}
              >
                {userVatError}
              </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ width: 154 }}
              disabled={editPaymentLoading}
              variant="primary"
              onClick={() => onSubmit("PAYMENT")}
              className="btn-ok"
            >
              {editPaymentLoading ? <Loader /> : "Save Changes"}
            </Button>
          </Modal.Footer>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  );
};

export default Account;
