export default {
  filter_permission_keys: {
    loading_contact_name: "loading_contact_name",
    loading_contact_phone: "loading_contact_phone",
    loading_contact_email: "loading_contact_email",
    delivery_contact_name: "delivery_contact_name",
    delivery_contact_phone: "delivery_contact_phone",
    delivery_contact_email: "delivery_contact_email",
  },
  hideHeader: [
    "/admin_forgotPassword",
    "/staff_forgotPassword",
    "/resetPassword",
    "/dashboard",
    "/account",
    "/team",
    "/carriers",
    "/clients",
    "/users",
    "/pricing",
    "/settings",
    "/staffLogin",
    "/quotes",
    "/orders",
    "/archives",
    "/tracking",
    "/carrier_quotes",
    "/carrier_home",
    "/availabilities",
  ],

  show_header_footer_paths: [
    "",
    "/",
    "/user_login",
    "/carriersignup",
    "/usersignup",
    "/solutions",
    "/contact",
    "/web_tracking",
    "/forget_password",
    "/terms",
    "/privacy",
    //dfdsfsd
  ],
};
