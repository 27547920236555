import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'; // Optional if using Bootstrap for styling

const TermsAndConditions = () => {
  return (
    <Container>
      <Row>
        <Col>
        <div className="privacyBox">
          <h1>Terms and Conditions</h1>
          <p>
            Welcome to mottruck!
          </p>
          <p>
            These terms and conditions outline the rules and regulations for the use of mottruck’s Website, located at https://mottruck.com.
          </p>
          <p>
            By accessing this website, we assume you accept these terms and conditions. Do not continue to use mottruck if you do not agree to take all of the terms and conditions stated on this page.
          </p>
          
          <h2>License</h2>
          <p>
            Unless otherwise stated, [Your Company Name] and/or its licensors own the intellectual property rights for all material on mottruck. All intellectual property rights are reserved. You may access this from mottruck for your own personal use subjected to restrictions set in these terms and conditions.
          </p>

          <h3>You must not:</h3>
          <ul>
            <li>Republish material from mottruck</li>
            <li>Sell, rent or sub-license material from mottruck</li>
            <li>Reproduce, duplicate or copy material from mottruck</li>
            <li>Redistribute content from mottruck</li>
          </ul>

          <h2>Disclaimer</h2>
          <p>
            To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website.
          </p>

          <p>
            This is a dummy Terms and Conditions page. You should replace the content here with real legal terms, and consult with a legal professional.
          </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsAndConditions;
