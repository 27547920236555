import React, { useState, useEffect } from "react";
import SideBar from "../inc/SideBar";
import NavBar from "../inc/NavBar";
import FilterSidebar from "../inc/FilterSidebar";
import { Col, Container, Row, Image, Table } from "react-bootstrap";
import TruckFast from "../images/truck-fast.svg";
import ChartIcon from "../images/chart.svg";
import TextInputWithLabel from "../customcomponents/TextInputWithLabel";
import NewTextInputWithLabel from "../customcomponents/NewTextInputWithLabel";
import BarChart from "./chart/BarChart";
import { LineChart } from "./chart/LineChart";
import { LineChartOne } from "./chart/LineChartOne";
// import { DoughnutChart } from "./chart/DoughnutChart";
import DoughnutChart from "./chart/DoughnutChart";
import { getAdminDetail } from "../services/ApiCalls";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const SALES_COLOR_SCHEME = {
  generalCargo: "#df8244",
  tanker: "#4e73be",
  refrigerated: "#7eab55",
};

const Dashboard = () => {
  const location = useLocation();

  const [toggle, setToggle] = useState(false);
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [ratingData, setRatingData] = useState("");
  const [chartData, setChartData] = useState({
    userquotes: [],
    staffquotes: [],
  });

  useEffect(() => {
    if (toggle) {
      document.body.classList.add("filter_sidebar");
    } else {
      document.body.classList.remove("filter_sidebar");
    }
  }, [toggle]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://mottruck.com:3001/order-graph"
        );
        const data = response.data.data;
        console.log("data", data.staff);
        setUserData(data.user);
        setStaffData(data.staff);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleKeyPress = async (e) => {
    if (e.key === "Enter") {
      try {
        const response = await axios.get(
          "https://mottruck.com:3001/carrier-rating-graph?search=" +
            e.target.value
        );
        console.log("API Response:", response.data);
        if (response.data.status == 0) {
          toast(response.data.msg);
        }

        const data = response.data.data;
        if (
          !data ||
          !Array.isArray(data.averageRatings) ||
          !Array.isArray(data.averageOTIFRatings) ||
          !Array.isArray(data.averagePlatformObligationRatings) ||
          !Array.isArray(data.averageCorrectionRatings)
        ) {
          throw new Error("Invalid data format");
        }

        setRatingData(data);
        // setChartData(data);
        setShow(true);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    }
  };

  const handleClear = () => {
    setInputValue("");
    setShow(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://mottruck.com:3001/profit-graph"
        );
        if (response.data.status === 1) {
          setChartData(response.data.data); // Assuming data.data contains userquotes and staffquotes
        } else {
          // setError(response.data.msg || "Error fetching data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const currentDate = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonth = monthNames[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear();
  return (
    <React.Fragment>
      <div className="d-flex" id="wrapper">
        <SideBar />
        <div id="page-content-wrapper">
          <NavBar />
          <FilterSidebar close={handleToggle} />

          <Container fluid className="pt-100">
            <Row>
              <Col lg={12}>
                <div className="main_div">
                  <h1 className="common_heading">
                    <Image src={TruckFast} alt="" />
                    Carrier performance
                  </h1>
                  <Row>
                    <Col lg={6}>
                      <div className="searchBar">
                        {/* <TextInputWithLabel
                          // onSubmit={() => setShow(!show)}
                          placeholder="Search by name or ID"
                          type="search"
                        /> */}
                        <NewTextInputWithLabel
                          value={inputValue}
                          onChange={(e) => {
                            setShow(false);
                            setInputValue(e.target.value);
                          }}
                          onKeyPress={handleKeyPress}
                          onClear={handleClear}
                          placeholder="Search by name or ID"
                          type="search"
                        />
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <g opacity="0.5">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5.91314 10.8263C8.6266 10.8263 10.8263 8.6266 10.8263 5.91314C10.8263 3.19969 8.6266 1 5.91314 1C3.19969 1 1 3.19969 1 5.91314C1 8.6266 3.19969 10.8263 5.91314 10.8263Z"
                              stroke="#767494"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.71937 9.71886L12.8075 12.807"
                              stroke="#767494"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                        </svg>
                      </div>
                    </Col>
                  </Row>

                  {show && inputValue && (
                    <div className="cardChat_margin cardChat_marginMinus mb-4">
                      <div className="cardChart">
                        <h2>Carrier rate</h2>
                        <LineChartOne ratingData={ratingData} />
                      </div>
                    </div>
                  )}

                  <h1 className="common_heading">
                    <Image src={ChartIcon} alt="" />
                    Management
                  </h1>

                  <Row className="cardChat_margin">
                    <Col lg={6}>
                      <div className="cardChart position-relative">
                        <h4 className="rateGrey rateGreyAbs">K€</h4>
                        <BarChart
                          userquotes={chartData.userquotes}
                          staffquotes={chartData.staffquotes}
                        
                        />
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div className="cardChart">
                        <h2>
                          Orders - {currentMonth} {currentYear}
                        </h2>
                        <Table responsive>
                          <thead>
                            <tr>
                              <th></th>
                              <th>Awaiting delivery</th>
                              <th>On delivery</th>
                              <th>Delivered</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <span className="fmed">User</span>
                              </td>
                              {userData.map((item) => (
                                <td>
                                  <span className="fsemi">{item.count}</span>
                                  <span className="freg">
                                    (
                                    {item.totalPrice != 0
                                      ? item.totalPrice.$numberDecimal
                                      : 0}{" "}
                                    €)
                                  </span>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>
                                <span className="fmed">Client</span>
                              </td>
                              {staffData.map((item) => (
                                <td>
                                  <span
                                    className="fsemi"
                                    style={{ color: "#5A6ACF" }}
                                  >
                                    {item.count}
                                  </span>
                                  <span
                                    className="freg"
                                    style={{ color: "#5A6ACF" }}
                                  >
                                    (
                                    {item.totalPrice != 0
                                      ? item.totalPrice
                                      : 0}{" "}
                                    €)
                                  </span>
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div className="cardChart">
                        <h2>Client satisfaction</h2>

                        <h4 className="rateGrey">Rate</h4>
                        <LineChart />
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div className="cardChart">
                        <h2>
                          Sales - {currentMonth} {currentYear}
                        </h2>
                        <div className="chartFlex">
                          <div className="chartFlex-list">
                            <p
                              className="commonpos generalcargo"
                              style={{ color: SALES_COLOR_SCHEME.generalCargo }}
                            >
                              General cargo
                            </p>
                            <p
                              className="commonpos tanker"
                              style={{ color: SALES_COLOR_SCHEME.tanker }}
                            >
                              Tanker
                            </p>
                            <p
                              className="commonpos refrigerated"
                              style={{ color: SALES_COLOR_SCHEME.refrigerated }}
                            >
                              Refrigerated
                            </p>
                          </div>
                          <DoughnutChart />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <ToastContainer> </ToastContainer>
    </React.Fragment>
  );
};

export default Dashboard;
