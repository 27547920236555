import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Row>
        <Col>
        <div className="privacyBox">
          <h1>Privacy Policy</h1>
          <p>
            At Mottruck, we are committed to protecting the privacy of all users of our platform <a href="https://www.mottruck.com">www.mottruck.com</a> and our mobile application Mottruck App.
          </p>
          <p>
            In the section below, we will explain how we handle and utilize any personal data we collect from you. If you have any queries or requests about how we handle this privacy policy, feel free to contact us at: <a href="mailto:contact@mottruck.com">contact@mottruck.com</a>.
          </p>

          <h2>1. What information do we collect about you</h2>
          <p>When you engage with us, we may collect various types of data related to you. Rest assured that we only use your personal data when permitted by law. Here are the most common situations in which we collect and utilize your personal information:</p>
          <ul>
            <li>First name, last name</li>
            <li>Company</li>
            <li>Phone</li>
            <li>Email</li>
            <li>Address</li>
            <li>Account owner first & last name</li>
            <li>Account owner email & phone</li>
            <li>VAT number</li>
            <li>Countries delivered</li>
            <li>Vehicles category</li>
            <li>Truck number plate</li>
            <li>Material</li>
            <li>Availabilities</li>
            <li>Loading and delivery information</li>
            <li>Prices</li>
          </ul>
          <p>We carefully consider that our interests do not override your interests, fundamental rights and freedoms (as outlined in GDPR, art. 6 (1)(f)).</p>

          <h2>2. How do we collect your information</h2>
          <p>
            We collect your information when you interact with us or when you use our services, such as the website and mobile application. Information may be collected in the following situations:
          </p>
          <ul>
            <li>When you contact us either through our website, phone, or email</li>
            <li>When you create an account with us or change your settings</li>
            <li>When you send a quote request</li>
            <li>When you place an order with us</li>
            <li>From a third party who places an order</li>
            <li>When you use our mobile application to deliver an order</li>
          </ul>

          <h2>3. Retention periods</h2>
          <p>
            We only keep your personal data to fulfill the purposes for which it was collected, including for the purposes of satisfying any legal, regulatory, tax, accounting, or reporting requirements.
          </p>

          <h2>4. Your rights</h2>
          <p>We respect your rights regarding your personal data. At any time, you can contact us at <a href="mailto:contact@mottruck.com">contact@mottruck.com</a> to exercise any of the rights listed below:</p>
          <ul>
            <li><strong>Access to your personal data:</strong> You have the right to receive a copy of the information we hold about you.</li>
            <li><strong>Correction of your personal data:</strong> You can request a correction of any incomplete or inaccurate information we hold about you.</li>
            <li><strong>Deletion of your personal data:</strong> In certain circumstances, you can request the deletion or removal of certain information we hold about you. However, we may not always be able to do so, and in that case, you will be informed.</li>
            <li><strong>Restriction of processing:</strong> You have the right to request that we limit the processing of your personal data. However, there may be legal reasons why we cannot comply, and we will notify you accordingly.</li>
            <li><strong>Data portability:</strong> In certain circumstances, you may have the right to receive your personal data in a structured, commonly used, and machine-readable format.</li>
            <li><strong>Withdrawn consent:</strong> Where we rely on consent to use your information, you have the right to withdraw that consent at any time. Withdrawing consent will not make unlawful our use of your information before you withdraw it. If you withdraw your consent, we may not be able to provide certain services to you.</li>
            <li><strong>Right to object:</strong> You have the right to object to certain types of processing of your information, including processing for direct marketing purposes and profiling.</li>
          </ul>

          <h2>5. Changes to our Privacy Policy</h2>
          <p>
            We review our Privacy Policy from time to time to check its effectiveness and make necessary updates. This Privacy Policy was last published on: <strong>September 23, 2024</strong>.
          </p>

          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, feel free to contact us at: <a href="mailto:contact@mottruck.com">contact@mottruck.com</a>.
          </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
