import React, { useState, useEffect, useContext } from "react";
import {
  Col,
  Row,
  Button,
  Table,
  Image,
  Modal,
  Form,
  Container,
  Overlay,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FiPlusCircle, FiMoreVertical, FiClock } from "react-icons/fi";

import EditIcon from "../images/edit.svg";
import TrashIcon from "../images/trash.svg";
import TruckIcon from "../images/truck.svg";
import LocationIcon from "../images/location.svg";
import DeliveryIcon from "../images/delivery.svg";
import MatIcon from "../images/maticon.svg";
import TaskIcon from "../images/task.svg";
import TruckWhiteIcon from "../images/truckwhite.svg";
import BreadCrumbHeader from "../customcomponents/BreadCrumbHeader";
import TextInputWithLabel from "../customcomponents/TextInputWithLabel";
import TextInputWithoutLabel from "../customcomponents/TextInputWithoutLabel";
import TextInputAreaWithLabel from "../customcomponents/TextInputAreaWithLabel";
import { QuotesDataContext } from "../services/QuotesData";
import * as SERVICE from "../services/index";
import { toast } from "react-toastify";
import {
  country_PhoneNumberCode,
  staff_EditQuote,
  staff_PaticularEditDetail,
} from "../services/ApiCalls";
import LoadingContext from "../services/LoadingContext";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import { FaL, FaLocationDot, FaRegCircleCheck } from "react-icons/fa6";
import Loader from "../../common/component/Loader";
import { AppStoreContext } from "../../store/AppStore";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import dayjs from "dayjs";

const EditQouteModal = (props, editId) => {
  console.log("props.editId", props.editId);

  const [particularEditData, setParticularEditData] = useState();
  const [initial_loading, setInitialLoading] = useState(true);
  const [submit_loading, setSubmitLoading] = useState(false);
  const { userDetail } = useContext(AppStoreContext);
  const { setIsLoading } = useContext(LoadingContext);
  const user_id = userDetail._id;
  const role = userDetail.profile;
  const isRoleStaff = role == "Staff";

  useEffect(() => {
    getParticularDetail();
  }, [props.editId]);

  const getParticularDetail = () => {
    const payload = {
      StaffId: user_id,
      qoutesid: props.editId,
    };
    setInitialLoading(true);
    staff_PaticularEditDetail(payload)
      .then((res) => {
        if (res.status === true) {
          console.log(res.data);
          setParticularEditData(res.data);
          checkActiveTab(res.data);
        }
        setInitialLoading(false);
      })
      .catch((err) => {
        console.log("err in getParticularDetail ==", err);
      });
  };

  const checkActiveTab = (data) => {
    if (data[0].category === "General cargo") {
      setMaterials(data && data[0].MaterialInformation);
      setSaveMaterialModal(true);
    }
    if (data[0].category === "Tanker") {
      setSavedMaterials(data && data[0].MaterialInformation);
      setTankerSaveMaterailModal(true);
    }
    if (data[0].category === "Refrigerated") {
      setRefrigeratedMaterials(data && data[0].MaterialInformation);
      setRefrigeratedSaveMaterialModal(true);
    }

    setSelectedCountryCode(
      data && data[0].LOADING_INFORMATIONS[0].Contact_code
    );

    setUnLoadingCountryCode(
      data && data[0].DELIVERY_INFORMATIONS[0].delver_code
    );
  };

  useEffect(() => {
    if (particularEditData) {
      setActiveTab(particularEditData && particularEditData[0].category);

      setOtherInformation(
        particularEditData && particularEditData[0].OtherInformation
      );

      setReference(
        particularEditData &&
          particularEditData[0].LOADING_INFORMATIONS[0].Reference
      );
      setCompanyName(
        particularEditData &&
          particularEditData[0].LOADING_INFORMATIONS[0].Company_name
      );
      setAddress(
        particularEditData && particularEditData[0].LOADING_INFORMATIONS[0].city
      );
      setDate(
        particularEditData && particularEditData[0].LOADING_INFORMATIONS[0].Date
      );
      setTime(
        particularEditData &&
          particularEditData[0].LOADING_INFORMATIONS[0].Hours
      );
      setContactName(
        particularEditData &&
          particularEditData[0].LOADING_INFORMATIONS[0].Contact_name
      );

      setContactPhone(
        particularEditData &&
          particularEditData[0].LOADING_INFORMATIONS[0].Contact_phone
      );
      setContactEmail(
        particularEditData &&
          particularEditData[0].LOADING_INFORMATIONS[0].Contact_email
      );

      setPickupLocation(
        particularEditData &&
          particularEditData[0].LOADING_INFORMATIONS[0].appoitment_location
      );

      setLoadingState(
        particularEditData &&
          particularEditData[0].LOADING_INFORMATIONS[0].loadingState
      );
      setLoadingCity(
        particularEditData &&
          particularEditData[0].LOADING_INFORMATIONS[0].loadingCity
      );
      setLoadingCountry(
        particularEditData &&
          particularEditData[0].LOADING_INFORMATIONS[0].loadingCountry
      );

      // appoitment_location
      setDeliveryType(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].Delivery_type
      );
      setDeliveryRefernce(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].Reference
      );
      setDeliveryCompanyName(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].Company_name
      );
      setDeliveryAddress(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].delivery_city
      );
      setDeliveryLoadingState(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].deliveryState
      );
      setDeliveryLoadingCity(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].deliveryCity
      );
      setDeliveryLoadingCountry(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].deliveryCountry
      );

      setDeliveryDate(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].delivery_Date
      );
      setDeliveryTime(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].delivery_Hour
      );
      setDeliveryContactName(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].deliver_Contact_name
      );

      setDeliveryContactPhone(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].deliver_contact_phone
      );
      setDeliveryContactEmail(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0].delivery_conntact_email
      );

      setDeliveryLocation(
        particularEditData &&
          particularEditData[0].DELIVERY_INFORMATIONS[0]
            .appoitment_location_delivery
      );
    }

    if (activeTab === "General cargo") {
      setMaterials(
        particularEditData && particularEditData[0].MaterialInformation
      );
      setSaveMaterialModal(true);
    }
    if (activeTab === "Tanker") {
      setSavedMaterials(
        particularEditData && particularEditData[0].MaterialInformation
      );
      setTankerSaveMaterailModal(true);
    }
  }, [particularEditData]);

  const [activeTab, setActiveTab] = useState("");
  const [reference, setReference] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [pickupLocation, setPickupLocation] = useState();
  const [city, setCity] = useState("");
  const [loadingLatLong, setLoadingLatLong] = useState({});

  const [deliveryRefernce, setDeliveryRefernce] = useState("");
  const [deliveryCompanyName, setDeliveryCompanyName] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryTime, setDeliveryTime] = useState("");
  const [deliveryContactName, setDeliveryContactName] = useState("");
  const [deliveryContactPhone, setDeliveryContactPhone] = useState("");
  const [deliveryContactEmail, setDeliveryContactEmail] = useState("");
  const [deliveryLocation, setDeliveryLocation] = useState();
  const [deliveryType, setDeliveryType] = useState(null);
  const [deliveryCity, setDeliveryCity] = useState("");
  const [deliveryLatLong, setDeliveryLatLong] = useState({});

  const [materialName, setMaterialName] = useState("");
  const [otherInformation, setOtherInformation] = useState("");
  //address
  const [loadingState, setLoadingState] = useState("");
  const [loadingCity, setLoadingCity] = useState("");
  const [loadingCountry, setLoadingCountry] = useState("");

  const [deliveryLoadingState, setDeliveryLoadingState] = useState("");
  const [deliveryLoadingCity, setDeliveryLoadingCity] = useState("");
  const [deliveryLoadingCountry, setDeliveryLoadingCountry] = useState("");

  // general
  const [numberOfPallets, setNumberOfPallets] = useState("");
  const [sizeOfPallets, setSizeOfPallets] = useState("");
  const [heightOfPallets, setHeightOfPallets] = useState("");
  const [totalWeightOfPallets, setTotalWeightOfPallets] = useState("");
  // tanker
  const [adrReference, setAdrReference] = useState("");
  const [quantity, setQuantity] = useState("");
  const [radio, setAdrRadio] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);
  // refigrater
  const [temprature, setTemperature] = useState("");
  const [selectMsds, setSelectMsds] = useState(false);
  // carriers
  const [selectCarriers, setSelectCarriers] = useState([]);
  const [selectedCarriers, setSelectedCarriers] = useState([]);
  const [selectedCarrierIds, setSelectedCarrierIds] = useState([]);
  // ADD Material
  const [materials, setMaterials] = useState([]);
  // Tanker
  const [savedMaterials, setSavedMaterials] = useState([]);
  // Ref
  const [refrigeratedMaterials, setRefrigeratedMaterials] = useState([]);
  const [numberValidationError, setNumberValidationError] = useState("");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleRadio = (type) => {
    setAdrRadio(type);
  };

  const handleDeliveryType = (option) => {
    setDeliveryType(option);
  };

  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);

  const [referenceError, setReferenceError] = useState(0);
  const [companyNameError, setCompanyNameError] = useState(0);
  const [addressError, setAddressError] = useState(0);
  const [dateError, setDateError] = useState(0);
  const [timeError, setTimeError] = useState(0);
  const [contactNameError, setContactNameError] = useState(0);
  const [contactPhoneError, setContactPhoneError] = useState(0);
  const [contactEmailError, setContactEmailError] = useState(0);

  const [deliveryRefernceError, setDeliveryRefernceError] = useState(0);
  const [deliveryCompanyNameError, setDeliveryCompanyNameError] = useState(0);
  const [deliveryAddressError, setDeliveryAddressError] = useState(0);
  const [deliveryDateError, setDeliveryDateError] = useState(0);
  const [deliveryTimeError, setDeliveryTimeError] = useState(0);
  const [deliveryContactNameError, setDeliveryContactNameError] = useState(0);
  const [deliveryContactPhoneError, setDeliveryContactPhoneError] = useState(0);
  const [deliveryContactEmailError, setDeliveryContactEmailError] = useState(0);
  const [deliveryTypeError, setDeliveryTypeError] = useState(0);

  // general
  const [numberOfPalletsError, setNumberOfPalletsError] = useState(0);
  const [sizeOfPalletsError, setSizeOfPalletsError] = useState(0);
  const [heightOfPalletsError, setHeightOfPalletsError] = useState(0);
  const [totalWeightOfPalletsError, setTotalWeightOfPalletsError] = useState(0);
  const [activeTabError, setActiveTabError] = useState("");

  let count = 0;

  const updateQuote = () => {
    let materialInformation;
    if (activeTab === "General cargo") {
      materialInformation = materials;
    } else if (activeTab === "Tanker") {
      materialInformation = savedMaterials;
    } else if (activeTab === "Refrigerated") {
      materialInformation = refrigeratedMaterials;
    }

    const payload = {
      qoutesId: props.editId,
      StaffId: user_id,
      category: activeTab ? activeTab : particularEditData[0].category,
      MaterialInformation: materialInformation
        ? materialInformation
        : particularEditData[0].MaterialInformation,
      OtherInformation: otherInformation,
      LOADING_INFORMATIONS: [
        {
          Reference: reference,
          Company_name: companyName,
          Address: address,
          Date: date,
          Hours: time,
          Contact_name: contactName,
          Contact_phone: contactPhone,
          Contact_email: contactEmail,
          appoitment_location: pickupLocation,
          city: address,
          loadingcode: selectedCountryCode,
          loadingState: loadingState,
          loadingCity: loadingCity,
          loadingCountry: loadingCountry,
        },
      ],
      DELIVERY_INFORMATIONS: [
        {
          appoitment_location_delivery: deliveryLocation,
          Delivery_type: deliveryType
            ? deliveryType
            : particularEditData && particularEditData[0].Delivery_type,
          Reference: deliveryRefernce,
          Company_name: deliveryCompanyName,
          Address: deliveryAddress,
          delivery_Date: deliveryDate,
          delivery_Hour: deliveryTime,
          deliver_Contact_name: deliveryContactName,
          deliver_contact_phone: deliveryContactPhone,
          delivery_conntact_email: deliveryContactEmail,
          delivery_city: deliveryAddress,
          delvieryCode: unLoadingCountryCode,
          deliveryState: deliveryLoadingState,
          deliveryCity: deliveryLoadingCity,
          deliveryCountry: deliveryLoadingCountry,
        },
      ],
      ChooseCarrier: particularEditData && particularEditData[0].ChooseCarrier,
      carrier_email: particularEditData && particularEditData[0].carrier_email,
      carrier: particularEditData && particularEditData[0].carrier,
      directRequests:
        particularEditData && particularEditData[0].directRequests,
      Latitude: "31.2980035714754",
      Longitudes: "75.56183075264784",
      loading_Latitude:
        particularEditData && particularEditData
          ? particularEditData[0].LOADING_INFORMATIONS[0].Address.coordinates[0]
          : loadingLatLong.lat,
      loading_Longitudes:
        particularEditData && particularEditData
          ? particularEditData[0].LOADING_INFORMATIONS[0].Address.coordinates[0]
          : loadingLatLong.lng,
      delevry_Latitude:
        particularEditData && particularEditData
          ? particularEditData[0].LOADING_INFORMATIONS[0].Address.coordinates[0]
          : deliveryLatLong.lat,
      delevry_Longitudes:
        particularEditData && particularEditData
          ? particularEditData[0].LOADING_INFORMATIONS[0].Address.coordinates[0]
          : deliveryLatLong.lng,
      prize: particularEditData && particularEditData[0].prize,
      type: isRoleStaff ? "staff" : "user",
    };

    setSubmitLoading(true);
    staff_EditQuote(payload)
      .then((res) => {
        if (res.status === true) {
          toast("Quote updated successfully!");
          props.onHide();
          setSubmitLoading(false);
        } else {
          toast(res.msg);
          setSubmitLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    onProfileValidation();
  }, [
    reference,
    companyName,
    address,
    date,
    time,
    contactName,
    contactPhone,
    contactEmail,
    deliveryRefernce,
    deliveryCompanyName,
    deliveryAddress,
    deliveryDate,
    deliveryTime,
    deliveryContactName,
    deliveryContactPhone,
    deliveryContactEmail,
    numberOfPallets,
    sizeOfPallets,
    heightOfPallets,
    totalWeightOfPallets,
    adrReference,
    quantity,
    temprature,
    otherInformation,
    selectMsds,
  ]);

  const onSubmit = (props) => {
    setSubmit(true);
    onProfileValidation();

    if (errorCount > 0) {
      console.log("errorCount if  ", errorCount);
      return;
    } else {
      updateQuote(props);
    }
  };

  const onProfileValidation = async () => {
    let errorCount = 0;

    // if (!reference) {
    //   errorCount++;
    //   let msg = "Please enter reference.";
    //   setReferenceError(msg);
    // } else {
    //   setReferenceError(null);
    // }

    // if (!companyName) {
    //   errorCount++;
    //   let msg = "Please enter company name.";
    //   setCompanyNameError(msg);
    // } else {
    //   setCompanyNameError(null);
    // }

    if (!address) {
      errorCount++;
      let msg = "Please enter address.";
      setAddressError(msg);
    } else {
      setAddressError(null);
    }
    if (!date) {
      errorCount++;
      let msg = "Please enter date.";
      setDateError(msg);
    } else {
      setDateError(null);
    }
    // if (!time) {
    //   errorCount++;
    //   let msg = "Please enter time.";
    //   setTimeError(msg);
    // } else {
    //   setTimeError(null);
    // }
    if (!contactName) {
      errorCount++;
      let msg = "Please enter contact name.";
      setContactNameError(msg);
    } else {
      setContactNameError(null);
    }
    if (!contactPhone) {
      errorCount++;
      let msg = "Please enter a valid number.";
      setContactPhoneError(msg);
    } else {
      setContactPhoneError(null);
    }

    let contactEmailError = SERVICE.validateEmail(contactEmail);
    if (contactEmailError !== undefined) {
      errorCount++;
      let msg =
        contactEmailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setContactEmailError(msg);
    } else {
      setContactEmailError(null);
    }

    if (!deliveryType) {
      errorCount++;
      let msg = "Please select delivery type.";
      setDeliveryTypeError(msg);
    } else {
      setDeliveryTypeError(null);
    }

    // if (!deliveryRefernce) {
    //   errorCount++;
    //   let msg = "Please enter delivery reference.";
    //   setDeliveryRefernceError(msg);
    // } else {
    //   setDeliveryRefernceError(null);
    // }

    // if (!deliveryCompanyName) {
    //   errorCount++;
    //   let msg = "Please enter delivery company name.";
    //   setDeliveryCompanyNameError(msg);
    // } else {
    //   setDeliveryCompanyNameError(null);
    // }

    if (!deliveryAddress) {
      errorCount++;
      let msg = "Please enter delivery address.";
      setDeliveryAddressError(msg);
    } else {
      setDeliveryAddressError(null);
    }
    if (!deliveryDate) {
      errorCount++;
      let msg = "Please enter delivery date.";
      setDeliveryDateError(msg);
    } else {
      setDeliveryDateError(null);
    }

    // if (!deliveryTime) {
    //   errorCount++;
    //   let msg = "Please enter delivery time.";
    //   setDeliveryTimeError(msg);
    // } else {
    //   setDeliveryTimeError(null);
    // }

    if (!deliveryContactName) {
      errorCount++;
      let msg = "Please enter delivery contact name.";
      setDeliveryContactNameError(msg);
    } else {
      setDeliveryContactNameError(null);
    }

    if (!deliveryContactPhone) {
      errorCount++;
      let msg = "Please enter a valid number.";
      setDeliveryContactPhoneError(msg);
    } else {
      setDeliveryContactPhoneError(null);
    }

    let deliveryContactEmailError = SERVICE.validateEmail(deliveryContactEmail);
    if (deliveryContactEmailError !== undefined) {
      errorCount++;
      let msg =
        deliveryContactEmailError === "required"
          ? "Please enter your email address."
          : "Please enter a valid email address.";
      setDeliveryContactEmailError(msg);
    } else {
      setDeliveryContactEmailError(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
    console.log("errorCount", errorCount);
  };

  // General Cargo Function

  const [addMaterialModal, setAddMaterialModal] = useState(false);
  const [saveMaterailModal, setSaveMaterialModal] = useState(false);
  // const [materials, setMaterials] = useState([]);
  const [editMaterialId, setEditMaterialId] = useState(null);

  const handleSave = () => {
    const upDateData = materials.map((item, key) => {
      if (key == editMaterialId) {
        return {
          ...item,
          materialName: materialName,
          numberOfPallets: numberOfPallets,
          sizeOfPallets: sizeOfPallets,
          heightOfPallets: heightOfPallets,
          totalWeightOfPallets: totalWeightOfPallets,
        };
      }
      return item;
    });
    if (
      !materialName ||
      !numberOfPallets ||
      !sizeOfPallets ||
      !totalWeightOfPallets
    ) {
      toast("Please fill all required fields");
      return;
    }
    if (editMaterialId !== null) {
      const updatedMaterials =
        materials &&
        materials.map((material) =>
          material.id === editMaterialId
            ? {
                ...material,
                materialName,
                numberOfPallets,
                sizeOfPallets,
                heightOfPallets,
                totalWeightOfPallets,
              }
            : material
        );
      setMaterials(upDateData);
      setEditMaterialId(null);
    } else {
      const newMaterial = {
        id: ++count,
        materialName,
        numberOfPallets,
        sizeOfPallets,
        heightOfPallets,
        totalWeightOfPallets,
      };
      setMaterials([...materials, newMaterial]);
    }
    setMaterialName("");
    setNumberOfPallets("");
    setSizeOfPallets("");
    setHeightOfPallets("");
    setTotalWeightOfPallets("");
    setAddMaterialModal(false);
    setSaveMaterialModal(true);
  };

  const handleDelete = (id) => {
    const updatedMaterials = materials.filter(
      (material, index) => index !== id
    );
    setMaterials(updatedMaterials);
  };

  const handleEdit = (id) => {
    setEditMaterialId(id);
    const materialToEdit = materials.find((material, index) => index == id);
    if (materialToEdit) {
      setMaterialName(materialToEdit.materialName);
      setNumberOfPallets(materialToEdit.numberOfPallets);
      setSizeOfPallets(materialToEdit.sizeOfPallets);
      setHeightOfPallets(materialToEdit.heightOfPallets);
      setTotalWeightOfPallets(
        materialToEdit.totalWeightOfPallets
          ? materialToEdit.totalWeightOfPallets
          : materialToEdit.totalWeight
      );
    }
    setAddMaterialModal(true);
  };

  // Tanker Function

  const [tankerAddMaterialModal, setTankerAddMaterialModal] = useState(false);
  const [tankerSaveMaterailModal, setTankerSaveMaterailModal] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [tankerEditItemId, setTankerEditItemId] = useState(null);

  const [selected_field_arr, setSelected_arr] = useState([]);
  const [preselected_Item, setpreselectedItem] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isInitial, setIsInitial] = useState(false);

  const handleCheckbox = (option, edit) => {
    if (edit) {
      if (preselected_Item.includes(option)) {
        const filteredArr = preselected_Item.filter((item) => item !== option);
        setpreselectedItem([...filteredArr]);
      } else {
        setpreselectedItem([...preselected_Item, option]);
      }
    } else {
      if (selectedOptions.includes(option)) {
        setSelectedOptions(selectedOptions.filter((item) => item !== option));
      } else {
        setSelected_arr([...selectedOptions, option]);

        setSelectedOptions([...selectedOptions, option]);
      }
    }
  };

  const handleDeleteSavedMaterial = (id) => {
    const updatedSavedMaterials = savedMaterials.filter(
      (material, index) => index !== id
    );
    setSavedMaterials(updatedSavedMaterials);
  };

  const handleEditSavedMaterial = (id, item) => {
    setIsEdit(true);
    setTankerEditItemId(id);
    setSelected_arr(item);
    const materialToEdit = savedMaterials.find(
      (material, index) => index == id
    );
    if (materialToEdit) {
      setpreselectedItem(materialToEdit.selectedOptions);
      setMaterialName(materialToEdit.materialName);
      setAdrRadio(materialToEdit.ADR == "Yes" ? "yes" : "no");
      setAdrReference(materialToEdit.adrReference);
      setQuantity(materialToEdit.quantity);
      setSelectOptions(materialToEdit.selectedOptions);
    }
    setTankerAddMaterialModal(true);
  };

  const handleSaveMaterial = () => {
    setIsInitial(false);
    if (isEdit === false) {
      if (!materialName || !quantity || selectedOptions.length == 0) {
        toast("Please fill all required fields");
        return;
      }
    } else {
      if (!materialName || !quantity || preselected_Item.length == 0) {
        toast("Please fill all required fields");
        return;
      }
    }

    if (tankerEditItemId !== null) {
      const updatedSavedMaterials = savedMaterials.map((material, index) =>
        index === tankerEditItemId
          ? {
              ...material,
              materialName,
              ADR: radio === "yes" ? "Yes" : "No",
              adrReference: radio ? adrReference : "",
              quantity: quantity,
              selectedOptions: preselected_Item,
            }
          : material
      );
      setSavedMaterials(updatedSavedMaterials);
      setIsEdit(false);

      setMaterialName("");
      setAdrRadio("");
      setAdrReference("");
      setQuantity("");
      setSelectedOptions([]);
      setTankerEditItemId(null);
    } else {
      const newMaterial = {
        id: ++count,
        materialName,
        ADR: radio == "yes" ? "Yes" : "No",
        adrReference: radio == "yes" ? adrReference : "",
        quantity: quantity,
        selectedOptions: selectedOptions,
      };

      setSavedMaterials([...savedMaterials, newMaterial]);
      setAdrRadio("");
      setMaterialName("");
      setQuantity("");
      setAdrReference("");
      setSelectedOptions([]);
    }

    setTankerAddMaterialModal(false);
    setTankerSaveMaterailModal(true);
  };

  // REFIG
  const [refrigeratedAddMaterialModal, setRefrigeratedAddMaterialModal] =
    useState(false);
  const [refrigeratedSaveMaterailModal, setRefrigeratedSaveMaterialModal] =
    useState(false);
  const [msds, setMsds] = useState("Yes");

  const handleMSDS = (type) => {
    setMsds(type);
  };

  const handleRefrigeratedSave = () => {
    const upDateData = refrigeratedMaterials.map((item, key) => {
      if (key == editMaterialId) {
        return {
          ...item,
          materialName: materialName,
          numberOfPallets: numberOfPallets,
          sizeOfPallets: sizeOfPallets,
          heightOfPallets: heightOfPallets,
          totalWeightOfPallets: totalWeightOfPallets,
          temprature: temprature,
          msds: msds,
        };
      }
      return item;
    });

    if (
      !materialName ||
      !numberOfPallets ||
      !sizeOfPallets ||
      !totalWeightOfPallets
    ) {
      toast("Please fill all required fields");
      return;
    }
    if (editMaterialId !== null) {
      const updatedMaterials =
        materials &&
        materials.map((material) =>
          material.id === editMaterialId
            ? {
                ...material,
                materialName,
                numberOfPallets,
                sizeOfPallets,
                heightOfPallets,
                totalWeightOfPallets,
                msds,
              }
            : material
        );
      setRefrigeratedMaterials(upDateData);
      setEditMaterialId(null);
    } else {
      const newRefrigeratedMaterial = {
        id: ++count,
        materialName,
        numberOfPallets,
        sizeOfPallets,
        heightOfPallets,
        totalWeightOfPallets,
      };
      setRefrigeratedMaterials([
        ...refrigeratedMaterials,
        newRefrigeratedMaterial,
      ]);
    }
    setMaterialName("");
    setNumberOfPallets("");
    setSizeOfPallets("");
    setHeightOfPallets("");
    setTotalWeightOfPallets("");
    setRefrigeratedAddMaterialModal(false);
    setRefrigeratedSaveMaterialModal(true);
  };

  const handleRefrigeratedDelete = (id) => {
    const updatedRefrigeratedMaterials = refrigeratedMaterials.filter(
      (material, index) => index !== id
    );
    setRefrigeratedMaterials(updatedRefrigeratedMaterials);
  };

  const handleRefrigeratedEdit = (id) => {
    setEditMaterialId(id);
    const materialToEdit = refrigeratedMaterials.find(
      (material, index) => index == id
    );
    if (materialToEdit) {
      setMaterialName(materialToEdit.materialName);
      setTemperature(materialToEdit.temprature);
      setNumberOfPallets(materialToEdit.numberOfPallets);
      setSizeOfPallets(materialToEdit.sizeOfPallets);
      setHeightOfPallets(materialToEdit.heightOfPallets);
      setTotalWeightOfPallets(
        materialToEdit.totalWeightOfPallets
          ? materialToEdit.totalWeightOfPallets
          : materialToEdit.totalWeight
      );
      setMsds(materialToEdit.msds);
    }
    setRefrigeratedAddMaterialModal(true);
  };

  const resetInputStates = () => {
    setMaterialName("");
    setNumberOfPallets("");
    setSizeOfPallets("");
    setHeightOfPallets("");
    setTotalWeightOfPallets("");
  };
  const setInputStates = (material) => {
    setTemperature(material.temprature);
    setMaterialName(material.materialName);
    setNumberOfPallets(material.numberOfPallets);
    setSizeOfPallets(material.sizeOfPallets);
    setHeightOfPallets(material.heightOfPallets);
    setTotalWeightOfPallets(material.totalWeightOfPallets);
    setMsds(material.msds);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB3cLetAkii900cvYPIrvxILEJ6qUhpG4I&libraries=places`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleSelect = async (value, placeId) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: postalCode = "" } =
      place.address_components.find((c) => c.types.includes("postal_code")) ||
      {};
    let state = "";
    let city = "";
    let country = "";
    let countryShort = "";
    for (const component of results[0].address_components) {
      if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      } else if (component.types.includes("locality")) {
        city = component.long_name;
      } else if (component.types.includes("country")) {
        country = component.long_name;
        countryShort = component.short_name;
      }
    }
    setLoadingLatLong(latLng);
    setAddress(value + " " + postalCode);
    setLoadingState(state);
    setLoadingCity(city);
    setLoadingCountry(countryShort);
  };

  const handleSelectDelivery = async (value, placeId) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: postalCode = "" } =
      place.address_components.find((c) => c.types.includes("postal_code")) ||
      {};

    let state = "";
    let city = "";
    let country = "";
    let countryShort = "";

    for (const component of results[0].address_components) {
      if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      } else if (component.types.includes("locality")) {
        city = component.long_name;
      } else if (component.types.includes("country")) {
        country = component.long_name;
        countryShort = component.short_name;
      }
    }

    setDeliveryLatLong(latLng);
    setDeliveryAddress(value + " " + postalCode);

    setDeliveryLoadingState(state);
    setDeliveryLoadingCity(city);
    setDeliveryLoadingCountry(countryShort);
  };

  const [phoneNumberCode, setPhoneNumberCode] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [unLoadingCountryCode, setUnLoadingCountryCode] = useState("");

  useEffect(() => {
    getCounrtyCodeList();
  }, []);

  const getCounrtyCodeList = () => {
    const payload = {};
    country_PhoneNumberCode(payload).then((res) => {
      setPhoneNumberCode(res.data);
    });
  };

  const handleLoadingCountryCode = (event) => {
    setSelectedCountryCode(event.target.value);
  };

  const handleUnloadingCountryCode = (event) => {
    setUnLoadingCountryCode(event.target.value);
  };

  const handleTimeChange = (newTime) => {
    const formattedTime = moment(newTime.$d).format("HH:mm");
    setTime(formattedTime);
    console.log("Selected Time:", newTime.format("HH:mm"));
  };

  const handledeliveryTimeChange = (newValue) => {
    const formattedTime = moment(newValue.$d).format("HH:mm");
    setDeliveryTime(formattedTime);
    console.log("deliveryTime Time:", formattedTime);
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="newqoute_modal"
    >
      <Modal.Body className={initial_loading ? "view_quote" : ""}>
        {initial_loading ? (
          <Loader color={"#F7CE6C"} size={80} />
        ) : (
          <React.Fragment>
            <h2>Edit quote</h2>
            <BreadCrumbHeader image={TruckIcon} title="VEHICLE CATEGORY" />
            <h4 className="modal_label">
              Select a category <span className="requiresLable">*</span>
            </h4>
            <div className="tab-buttons">
              <Button
                onClick={() => handleTabClick("General cargo")}
                className={activeTab === "General cargo" ? "active" : ""}
              >
                General cargo
              </Button>
              <Button
                onClick={() => handleTabClick("Tanker")}
                className={activeTab === "Tanker" ? "active" : ""}
              >
                Tanker
              </Button>
              <Button
                onClick={() => handleTabClick("Refrigerated")}
                className={activeTab === "Refrigerated" ? "active" : ""}
              >
                Refrigerated
              </Button>
              {activeTabError && Submit ? (
                <div
                  style={{
                    fontFamily: "AvenirLTProLight",
                    color: "#FF0000",
                    opacity: 0.6,
                  }}
                >
                  {activeTabError}
                </div>
              ) : null}
            </div>

            <div className="tab-content">
              {activeTab === "General cargo" && (
                <>
                  <BreadCrumbHeader
                    image={MatIcon}
                    title="MATERIAL INFORMATIONS"
                  />
                  {/* General Cargo */}
                  {saveMaterailModal && (
                    <div className="modiFy">
                      {materials &&
                        materials.map((material, index) => (
                          <div key={material.id}>
                            <div className="mod-flex">
                              <span className="numberText">{++count}</span>

                              <Link
                                className="me-3 edlink"
                                onClick={() => handleEdit(index)}
                              >
                                <Image
                                  className="editimg"
                                  src={EditIcon}
                                  alt=""
                                />
                              </Link>
                              <Link
                                className="edlink"
                                onClick={() => handleDelete(index)}
                              >
                                <Image
                                  className="editimgt edlink"
                                  src={TrashIcon}
                                  alt=""
                                />
                              </Link>
                            </div>
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th>Material</th>
                                  <th>Number of pallets</th>
                                  <th>Size of pallets</th>
                                  <th>Height of pallets</th>
                                  <th>Total weight</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{material.materialName}</td>
                                  <td>{material.numberOfPallets}</td>
                                  <td>{material.sizeOfPallets}</td>
                                  <td>{material.heightOfPallets}</td>
                                  <td>{`${material.totalWeightOfPallets} Kg`}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        ))}
                    </div>
                  )}

                  {addMaterialModal && (
                    <div className="modiFy">
                      <div className="mod-flex mb-3">
                        <span className="numberText">{++count}</span>
                      </div>
                      <Row>
                        <Col lg={6} md={6}>
                          <TextInputWithoutLabel
                            name="text"
                            placeholder="Enter material name"
                            value={materialName}
                            onChange={(text) =>
                              setMaterialName(text.target.value)
                            }
                          />
                        </Col>
                      </Row>

                      <div className="inputFlex">
                        <div>
                          <TextInputWithLabel
                            name="text"
                            type="number"
                            placeholder="0"
                            label="Number of pallets"
                            reqstart="*"
                            value={numberOfPallets}
                            onChange={(text) => {
                              SERVICE.setNumberValidation(
                                text.target.value,
                                setNumberOfPallets
                              );
                            }}
                          />
                          {numberOfPalletsError && Submit ? (
                            <div
                              style={{
                                fontFamily: "AvenirLTProLight",
                                color: "#FF0000",
                                opacity: 0.6,
                              }}
                            >
                              {numberOfPalletsError}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <TextInputWithLabel
                            name="text"
                            placeholder="0"
                            label="Size of pallets"
                            reqstart="*"
                            value={sizeOfPallets}
                            onChange={(text) => {
                              SERVICE.setNumberValidation(
                                text.target.value,
                                setSizeOfPallets
                              );
                            }}
                          />
                          {sizeOfPalletsError && Submit ? (
                            <div
                              style={{
                                fontFamily: "AvenirLTProLight",
                                color: "#FF0000",
                                opacity: 0.6,
                              }}
                            >
                              {sizeOfPalletsError}
                            </div>
                          ) : null}
                        </div>

                        <div>
                          <TextInputWithLabel
                            name="text"
                            placeholder="0"
                            label="Height of pallets"
                            value={heightOfPallets}
                            onChange={(text) => {
                              SERVICE.setNumberValidation(
                                text.target.value,
                                setHeightOfPallets
                              );
                            }}
                          />
                          {heightOfPalletsError && Submit ? (
                            <div
                              style={{
                                fontFamily: "AvenirLTProLight",
                                color: "#FF0000",
                                opacity: 0.6,
                              }}
                            >
                              {heightOfPalletsError}
                            </div>
                          ) : null}
                        </div>

                        <div className="kgflex">
                          <TextInputWithLabel
                            name="text"
                            type="number"
                            placeholder="0"
                            label="Total weight"
                            reqstart="*"
                            value={totalWeightOfPallets}
                            onChange={(text) => {
                              SERVICE.setNumberValidation(
                                text.target.value,
                                setTotalWeightOfPallets
                              );
                            }}
                          />
                          <span>Kg</span>

                          {totalWeightOfPalletsError && Submit ? (
                            <div
                              style={{
                                fontFamily: "AvenirLTProLight",
                                color: "#FF0000",
                                opacity: 0.6,
                              }}
                            >
                              {totalWeightOfPalletsError}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <Button
                        className="btn-primaryAll btnPrimary px-5"
                        onClick={() => handleSave()}
                      >
                        Save
                      </Button>
                    </div>
                  )}

                  <Button
                    className="btn-primaryAll btnPrimary mb-3"
                    onClick={() => setAddMaterialModal(!addMaterialModal)}
                  >
                    + Add material
                  </Button>

                  <TextInputAreaWithLabel
                    label="Other information"
                    placeholder="Enter your text here"
                    value={otherInformation}
                    onChange={(text) => setOtherInformation(text.target.value)}
                  />
                </>
              )}

              {/* TANKER  */}
              {activeTab === "Tanker" && (
                <>
                  <BreadCrumbHeader
                    image={MatIcon}
                    title="MATERIAL INFORMATIONS"
                  />
                  {tankerSaveMaterailModal && (
                    <div className="modiFy">
                      {savedMaterials.map((material, index) => {
                        return (
                          <div key={material.id}>
                            <div className="mod-flex">
                              <span className="numberText">{++count}</span>
                              <Link
                                className="me-3 edlink"
                                onClick={() =>
                                  handleEditSavedMaterial(index, material)
                                }
                              >
                                <Image
                                  className="editimg"
                                  src={EditIcon}
                                  alt=""
                                />
                              </Link>
                              <Link
                                className="edlink"
                                onClick={() => handleDeleteSavedMaterial(index)}
                              >
                                <Image
                                  className="editimgt edlink"
                                  src={TrashIcon}
                                  alt=""
                                />
                              </Link>
                            </div>
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th style={{ minWidth: 350, maxWidth: 350 }}>
                                    Material
                                  </th>
                                  <th style={{ minWidth: 80 }}>ADR</th>
                                  <th>Quantity</th>
                                  <th style={{ minWidth: 80 }}>
                                    MSDS included
                                  </th>
                                  <th style={{ minWidth: 80 }}>Blacklisted</th>
                                  <th style={{ minWidth: 80 }}>
                                    Cleaning certificate
                                  </th>
                                  <th style={{ minWidth: 80 }}>Pump needed</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      minWidth: 350,
                                      maxWidth: 350,
                                      paddingRight: 5,
                                    }}
                                  >
                                    {material.materialName}
                                  </td>

                                  <td>
                                    {material && material.ADR == "Yes"
                                      ? `Yes - ${material.adrReference}`
                                      : "No"}
                                  </td>
                                  <td>{material.quantity} L </td>
                                  <td>
                                    {material.selectedOptions &&
                                    material.selectedOptions.includes(
                                      "MSDS included"
                                    )
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                  <td>
                                    {material.selectedOptions &&
                                    material.selectedOptions.includes(
                                      "Blacklisted"
                                    )
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                  <td>
                                    {material.selectedOptions &&
                                    material.selectedOptions.includes(
                                      "Cleaning certificate"
                                    )
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                  <td>
                                    {material.selectedOptions &&
                                    material.selectedOptions.includes(
                                      "Pump needed"
                                    )
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {tankerAddMaterialModal && (
                    <div className="modiFy">
                      <div className="mod-flex mb-3">
                        <span className="numberText">
                          {tankerEditItemId ? tankerEditItemId : ++count}
                        </span>
                      </div>
                      <Row>
                        <Col lg={6} md={6}>
                          <TextInputWithoutLabel
                            name="text"
                            placeholder="Enter material name"
                            value={materialName}
                            onChange={(text) =>
                              setMaterialName(text.target.value)
                            }
                          />
                        </Col>
                      </Row>
                      <div className="custom_radio">
                        <h5>
                          ADR <span className="requiresLable">*</span>
                        </h5>
                        <label>
                          <input
                            type="radio"
                            name="ADR"
                            className="card-input-element"
                            checked={radio == "yes" && true}
                            onChange={() => handleRadio("yes")}
                          />
                          <div className="card-input">
                            <div className="panel-heading">Yes</div>
                          </div>
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="ADR"
                            checked={radio == "no" && true}
                            className="card-input-element"
                            onChange={() => handleRadio("no")}
                          />
                          <div className="card-input">
                            <div className="panel-heading">No</div>
                          </div>
                        </label>
                      </div>

                      {radio == "yes" && (
                        <Row>
                          <Col lg={4} md={6}>
                            <TextInputWithLabel
                              name="text"
                              placeholder="Enter ADR Reference"
                              label="ADR Reference"
                              reqstart="*"
                              value={adrReference}
                              onChange={(text) =>
                                setAdrReference(text.target.value)
                              }
                            />
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col lg={4} md={6}>
                          <div className="d-flex align-items-center ">
                            <TextInputWithLabel
                              name="text"
                              placeholder="0"
                              label="Quantity"
                              reqstart="*"
                              value={quantity}
                              onChange={(text) =>
                                setQuantity(text.target.value)
                              }
                            />
                            <span className="ms-2 mt-2">L</span>
                          </div>
                        </Col>
                      </Row>

                      <div className="custom_radio">
                        <h5>
                          Select options{" "}
                          <span className="requiresLable">*</span>
                        </h5>
                        <label>
                          <input
                            type="checkbox"
                            name="selectoption"
                            className="card-input-element"
                            onChange={() =>
                              handleCheckbox("MSDS included", isEdit)
                            }
                          />

                          <div
                            className={`card-input ${
                              isEdit === true
                                ? preselected_Item.some(
                                    (item) => item == "MSDS included"
                                  )
                                  ? "tanker_selection_opt_bg_color"
                                  : "tanker_selection_opt_bg_color_alternate2"
                                : selectOptions.some(
                                    (item) => item == "MSDS included"
                                  ) && "tanker_selection_opt_bg_color"
                            }`}
                          >
                            <div className="panel-heading">MSDS included</div>
                          </div>
                        </label>

                        <label>
                          <input
                            type="checkbox"
                            name="selectoption"
                            className="card-input-element"
                            onChange={() =>
                              handleCheckbox("Blacklisted", isEdit)
                            }
                          />

                          <div
                            className={`card-input ${
                              isEdit === true
                                ? preselected_Item.some(
                                    (item) => item == "Blacklisted"
                                  )
                                  ? "tanker_selection_opt_bg_color"
                                  : "tanker_selection_opt_bg_color_alternate2"
                                : selectOptions.some(
                                    (item) => item == "Blacklisted"
                                  ) && "tanker_selection_opt_bg_color"
                            }`}
                          >
                            <div className="panel-heading">Blacklisted</div>
                          </div>
                        </label>

                        <label>
                          <input
                            type="checkbox"
                            name="selectoption"
                            className="card-input-element"
                            onChange={() =>
                              handleCheckbox("Cleaning certificate", isEdit)
                            }
                          />
                          <div
                            className={`card-input ${
                              isEdit === true
                                ? preselected_Item.some(
                                    (item) => item == "Cleaning certificate"
                                  )
                                  ? "tanker_selection_opt_bg_color"
                                  : "tanker_selection_opt_bg_color_alternate2"
                                : selectOptions.some(
                                    (item) => item == "Cleaning certificate"
                                  ) && "tanker_selection_opt_bg_color"
                            }`}
                          >
                            <div className="panel-heading">
                              Cleaning certificate
                            </div>
                          </div>
                        </label>

                        <label>
                          <input
                            type="checkbox"
                            name="selectoption"
                            className="card-input-element"
                            onChange={() =>
                              handleCheckbox("Pump needed", isEdit)
                            }
                          />
                          <div
                            className={`card-input ${
                              isEdit === true
                                ? preselected_Item.some(
                                    (item) => item == "Pump needed"
                                  )
                                  ? "tanker_selection_opt_bg_color"
                                  : "tanker_selection_opt_bg_color_alternate2"
                                : selectOptions.some(
                                    (item) => item == "Pump needed"
                                  ) && "tanker_selection_opt_bg_color"
                            }`}
                          >
                            <div className="panel-heading">Pump needed</div>
                          </div>
                        </label>
                      </div>

                      <Button
                        className="btn-primaryAll btnPrimary px-5"
                        onClick={() => handleSaveMaterial()}
                      >
                        Save
                      </Button>
                    </div>
                  )}

                  <Button
                    className="btn-primaryAll btnPrimary mb-3"
                    onClick={() =>
                      setTankerAddMaterialModal(!tankerAddMaterialModal)
                    }
                  >
                    + Add material
                  </Button>

                  <TextInputAreaWithLabel
                    label="Other information"
                    placeholder="Enter your text here"
                    value={otherInformation}
                    onChange={(text) => setOtherInformation(text.target.value)}
                  />
                </>
              )}

              {/* Refrigerated */}

              {activeTab === "Refrigerated" && (
                <>
                  <BreadCrumbHeader
                    image={MatIcon}
                    title="MATERIAL INFORMATIONS"
                  />

                  {refrigeratedSaveMaterailModal && (
                    <div className="modiFy">
                      {refrigeratedMaterials &&
                        refrigeratedMaterials.map((material, index) => {
                          return (
                            <div key={material.id}>
                              <div className="mod-flex">
                                <span className="numberText">{++count}</span>
                                <Link
                                  className="me-3 edlink"
                                  onClick={() => handleRefrigeratedEdit(index)}
                                >
                                  <Image
                                    className="editimg"
                                    src={EditIcon}
                                    alt=""
                                  />
                                </Link>
                                <Link
                                  className="edlink"
                                  onClick={() =>
                                    handleRefrigeratedDelete(index)
                                  }
                                >
                                  <Image
                                    className="editimgt edlink"
                                    src={TrashIcon}
                                    alt=""
                                  />
                                </Link>
                              </div>
                              <Table responsive>
                                <thead>
                                  <tr>
                                    <th>Material</th>
                                    <th>Temperature</th>
                                    <th>Number of pallets</th>
                                    <th>Size of pallets</th>
                                    <th>Height of pallets</th>
                                    <th>Total weight</th>
                                    <th>MSDS included </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr>
                                    <td>{material.materialName}</td>
                                    <td>{material.temprature}</td>
                                    <td>{material.numberOfPallets}</td>
                                    <td>{material.sizeOfPallets}</td>
                                    <td>{material.heightOfPallets}</td>
                                    <td>{`${material.totalWeightOfPallets} Kg`}</td>
                                    {material.msds === "Yes" ? "Yes" : "No"}
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          );
                        })}
                    </div>
                  )}

                  {refrigeratedAddMaterialModal && (
                    <div className="modiFy">
                      <div className="mod-flex mb-3">
                        <span className="numberText">{++count}</span>
                      </div>
                      <Row>
                        <Col lg={6} md={6}>
                          <TextInputWithoutLabel
                            name="text"
                            placeholder="Enter material name"
                            value={materialName}
                            onChange={(text) =>
                              setMaterialName(text.target.value)
                            }
                          />
                        </Col>
                      </Row>

                      <div className="inputFlex">
                        <div className="kgflex">
                          <TextInputWithLabel
                            name="text"
                            placeholder="0"
                            label="Temperature"
                            // reqstart="*"
                            value={temprature}
                            onChange={(text) =>
                              setTemperature(text.target.value)
                            }
                          />
                          <span>°C</span>
                        </div>

                        <div>
                          <TextInputWithLabel
                            name="text"
                            type="number"
                            placeholder="0"
                            label="Number of pallets"
                            reqstart="*"
                            value={numberOfPallets}
                            onChange={(text) =>
                              setNumberOfPallets(text.target.value)
                            }
                          />
                        </div>
                        <div>
                          <TextInputWithLabel
                            name="text"
                            placeholder="0"
                            label="Size of pallets"
                            reqstart="*"
                            value={sizeOfPallets}
                            onChange={(text) =>
                              setSizeOfPallets(text.target.value)
                            }
                          />
                        </div>

                        <div>
                          <TextInputWithLabel
                            name="text"
                            placeholder="0"
                            label="Height of pallets"
                            value={heightOfPallets}
                            onChange={(text) =>
                              setHeightOfPallets(text.target.value)
                            }
                          />
                        </div>

                        <div className="kgflex">
                          <TextInputWithLabel
                            name="text"
                            placeholder="0"
                            type="number"
                            label="Total weight"
                            reqstart="*"
                            value={totalWeightOfPallets}
                            onChange={(text) =>
                              setTotalWeightOfPallets(text.target.value)
                            }
                          />
                          <span>Kg</span>
                        </div>
                      </div>

                      <div className="custom_radio">
                        <h5>
                          MSDS included <span className="requiresLable">*</span>
                        </h5>
                        <label>
                          <input
                            type="radio"
                            name="sds"
                            checked={msds == "Yes" && true}
                            className="card-input-element"
                            onClick={() => handleMSDS("Yes")}
                          />
                          <div className="card-input">
                            <div className="panel-heading">Yes</div>
                          </div>
                        </label>

                        <label>
                          <input
                            type="radio"
                            name="sds"
                            checked={msds == "No" && true}
                            className="card-input-element"
                            onClick={() => handleMSDS("No")}
                          />
                          <div className="card-input">
                            <div className="panel-heading">No</div>
                          </div>
                        </label>
                      </div>

                      <Button
                        className="btn-primaryAll btnPrimary px-5"
                        onClick={() => handleRefrigeratedSave()}
                      >
                        Save
                      </Button>
                    </div>
                  )}

                  <Button
                    className="btn-primaryAll btnPrimary mb-3"
                    onClick={() =>
                      setRefrigeratedAddMaterialModal(
                        !refrigeratedAddMaterialModal
                      )
                    }
                  >
                    + Add material
                  </Button>

                  <TextInputAreaWithLabel
                    label="Other information"
                    placeholder="Enter your text here"
                    value={otherInformation}
                    onChange={(text) => setOtherInformation(text.target.value)}
                  />
                </>
              )}
            </div>

            <BreadCrumbHeader
              image={LocationIcon}
              title="LOADING INFORMATIONS"
            />

            {["checkbox"].map((type) => (
              <div key={`default-${type}`} className="mb-3 textColor">
                <Form.Check
                  type={type}
                  id={`default-${type}`}
                  value={pickupLocation}
                  checked={pickupLocation}
                  label={`Appointment required at pick-up location`}
                  onClick={() => setPickupLocation(!pickupLocation)}
                />
              </div>
            ))}

            <Row>
              <Col md={6}>
                <TextInputWithLabel
                  label="Reference"
                  placeholder="Enter a reference"
                  type="text"
                  // reqstart="*"
                  value={reference}
                  onChange={(text) => {
                    setReference(text.target.value);
                  }}
                />
                {referenceError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: -13,
                    }}
                  >
                    {referenceError}
                  </div>
                ) : null}
              </Col>
              <Col md={6}>
                <TextInputWithLabel
                  label="Company name"
                  placeholder="Enter a name"
                  type="text"
                  value={companyName}
                  onChange={(text) => setCompanyName(text.target.value)}
                />
                {companyNameError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: -13,
                    }}
                  >
                    {companyNameError}
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div>
                  <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <TextInputWithLabel
                          {...getInputProps({
                            placeholder: "Enter an Address",
                            className: "location-search-input",
                            label: "Address",
                            reqstart: "*",
                          })}
                        />
                        {suggestions.length > 0 && (
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions &&
                              suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                return (
                                  <li
                                    style={{ cursor: "pointer" }}
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                    })}
                                  >
                                    <FaLocationDot />
                                    <span>{suggestion.description}</span>
                                  </li>
                                );
                              })}
                          </div>
                        )}
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
                {addressError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: -13,
                    }}
                  >
                    {addressError}
                  </div>
                ) : null}
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={6}>
                    <TextInputWithLabel
                      label="Date (dd-mm-yyyy)"
                      placeholder="DD.MM.YYYY"
                      type="date"
                      reqstart="*"
                      value={date}
                      onChange={(text) => setDate(text.target.value)}
                    />
                    {dateError && Submit ? (
                      <div
                        style={{
                          fontFamily: "AvenirLTProLight",
                          color: "#FF0000",
                          opacity: 0.6,
                          marginTop: -13,
                        }}
                      >
                        {dateError}
                      </div>
                    ) : null}
                  </Col>
                  <Col md={6}>
                    {/* <TextInputWithLabel
                      label="Hour"
                      placeholder="HH:MM"
                      type="time"
                      value={time}
                      onChange={(text) => setTime(text.target.value)}
                    /> */}
                    <label class="form-label">Time</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <TimePicker
                          label=""
                          value={dayjs(time, "HH:mm")}
                          onChange={(newValue) => handleTimeChange(newValue)}
                          minutesStep={1} // Set the step to 1 minute
                        />
                      </DemoContainer>
                    </LocalizationProvider>

                    {timeError && Submit ? (
                      <div
                        style={{
                          fontFamily: "AvenirLTProLight",
                          color: "#FF0000",
                          opacity: 0.6,
                          marginTop: -13,
                        }}
                      >
                        {timeError}
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={4}>
                <TextInputWithLabel
                  label="Contact name"
                  placeholder="Enter a name"
                  type="text"
                  reqstart="*"
                  value={contactName}
                  onChange={(text) => setContactName(text.target.value)}
                />
                {contactNameError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: -13,
                    }}
                  >
                    {contactNameError}
                  </div>
                ) : null}
              </Col>
              <Col md={6} lg={4}>
                <div className="counCode countFLEX">
                  <Form.Select
                    size="lg"
                    value={selectedCountryCode}
                    onChange={handleLoadingCountryCode}
                  >
                    {phoneNumberCode.map((option, index) => (
                      <option key={index} value={option.code}>
                        {option && option.abbreviation} ({option.code})
                      </option>
                    ))}
                  </Form.Select>
                  <TextInputWithLabel
                    label="Contact phone"
                    placeholder="Enter a phone number"
                    type="text"
                    reqstart="*"
                    value={contactPhone}
                    onChange={(text) => {
                      setContactPhone(text.target.value);
                    }}
                  />
                </div>

                {contactPhoneError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: -13,
                    }}
                  >
                    {contactPhoneError}
                  </div>
                ) : null}
              </Col>
              <Col md={6} lg={4}>
                <TextInputWithLabel
                  label="Contact email"
                  placeholder="Enter an email"
                  type="email"
                  reqstart="*"
                  value={contactEmail}
                  onChange={(text) => setContactEmail(text.target.value)}
                />
                {contactEmailError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: -13,
                    }}
                  >
                    {contactEmailError}
                  </div>
                ) : null}
              </Col>
            </Row>
            <div className="mb-2"></div>
            <BreadCrumbHeader
              image={DeliveryIcon}
              title="DELIVERY INFORMATIONS"
            />

            {["checkbox"].map((type) => (
              <div key={2} className="mb-3 textColor">
                <Form.Check
                  type={type}
                  id={2}
                  value={deliveryLocation}
                  checked={deliveryLocation}
                  label={`Appointment required at delivery location`}
                  onClick={() => setDeliveryLocation(!deliveryLocation)}
                />
              </div>
            ))}

            <div className="custom_radio">
              <h5>
                Delivery type <span className="requiresLable">*</span>
              </h5>

              <label>
                <input
                  type="radio"
                  name="product"
                  className="card-input-element"
                  checked={deliveryType == "standard" && true}
                />
                <div
                  className="card-input"
                  onClick={() => handleDeliveryType("standard")}
                >
                  <div className="panel-heading">Standard</div>
                </div>
              </label>

              <label>
                <input
                  type="radio"
                  name="product"
                  className="card-input-element"
                  checked={deliveryType === "express" && true}
                />
                <div
                  className="card-input"
                  onClick={() => handleDeliveryType("express")}
                >
                  <div className="panel-heading">Express</div>
                </div>
              </label>

              {deliveryTypeError && Submit ? (
                <div
                  style={{
                    fontFamily: "AvenirLTProLight",
                    color: "#FF0000",
                    opacity: 0.6,
                  }}
                >
                  {deliveryTypeError}
                </div>
              ) : null}
            </div>

            <Row>
              <Col md={6}>
                <TextInputWithLabel
                  label="Reference"
                  placeholder="Enter a reference"
                  type="text"
                  // reqstart="*"
                  value={deliveryRefernce}
                  onChange={(text) => {
                    setDeliveryRefernce(text.target.value);
                  }}
                />
                {deliveryRefernceError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: -13,
                    }}
                  >
                    {deliveryRefernceError}
                  </div>
                ) : null}
              </Col>
              <Col md={6}>
                <TextInputWithLabel
                  label="Company name"
                  placeholder="Enter a name"
                  type="text"
                  value={deliveryCompanyName}
                  onChange={(text) => setDeliveryCompanyName(text.target.value)}
                />
                {deliveryCompanyNameError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: -13,
                    }}
                  >
                    {deliveryCompanyNameError}
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div>
                  <PlacesAutocomplete
                    value={deliveryAddress}
                    onChange={setDeliveryAddress}
                    onSelect={handleSelectDelivery}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <TextInputWithLabel
                          {...getInputProps({
                            placeholder: "Enter an Address",
                            className: "location-search-input",
                            label: "Address",
                            reqstart: "*",
                          })}
                        />
                        {suggestions.length > 0 && (
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              return (
                                <li
                                  style={{ cursor: "pointer" }}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                  })}
                                >
                                  <FaLocationDot />
                                  <span>{suggestion.description}</span>
                                </li>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>

                {deliveryAddressError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: -13,
                    }}
                  >
                    {deliveryAddressError}
                  </div>
                ) : null}
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={6}>
                    <TextInputWithLabel
                      label="Date (dd-mm-yyyy)"
                      placeholder="DD.MM.YYYY"
                      type="date"
                      reqstart="*"
                      value={deliveryDate}
                      onChange={(text) => setDeliveryDate(text.target.value)}
                    />
                    {deliveryDateError && Submit ? (
                      <div
                        style={{
                          fontFamily: "AvenirLTProLight",
                          color: "#FF0000",
                          opacity: 0.6,
                          marginTop: -13,
                        }}
                      >
                        {deliveryDateError}
                      </div>
                    ) : null}
                  </Col>
                  <Col md={6}>
                    {/* <TextInputWithLabel
                      label="Hour"
                      placeholder="HH:MM"
                      type="time"
                      value={deliveryTime}
                      onChange={(text) => setDeliveryTime(text.target.value)}
                    /> */}
                    <label class="form-label">Time</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <TimePicker
                          label=""
                          minutesStep={1}
                          value={dayjs(deliveryTime, "HH:mm")}
                          onChange={handledeliveryTimeChange}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {deliveryTimeError && Submit ? (
                      <div
                        style={{
                          fontFamily: "AvenirLTProLight",
                          color: "#FF0000",
                          opacity: 0.6,
                          marginTop: -13,
                        }}
                      >
                        {deliveryTimeError}
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={4}>
                <TextInputWithLabel
                  label="Contact name"
                  placeholder="Enter a name"
                  type="text"
                  reqstart="*"
                  value={deliveryContactName}
                  onChange={(text) => setDeliveryContactName(text.target.value)}
                />
                {deliveryContactNameError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: -13,
                    }}
                  >
                    {deliveryContactNameError}
                  </div>
                ) : null}
              </Col>
              <Col md={6} lg={4}>
                <div className="counCode countFLEX">
                  <Form.Select
                    size="lg"
                    value={unLoadingCountryCode}
                    onChange={handleUnloadingCountryCode}
                  >
                    {phoneNumberCode.map((option, index) => (
                      <option key={index} value={option.code}>
                        {option && option.abbreviation} ({option.code})
                      </option>
                    ))}
                  </Form.Select>
                  <TextInputWithLabel
                    label="Contact phone"
                    placeholder="Enter a phone number"
                    type="text"
                    reqstart="*"
                    value={deliveryContactPhone}
                    onChange={(text) => {
                      setDeliveryContactPhone(text.target.value);
                    }}
                  />
                </div>

                {deliveryContactPhoneError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: -13,
                    }}
                  >
                    {deliveryContactPhoneError}
                  </div>
                ) : null}
              </Col>
              <Col md={6} lg={4}>
                <TextInputWithLabel
                  label="Contact email"
                  placeholder="Enter an email"
                  type="email"
                  reqstart="*"
                  value={deliveryContactEmail}
                  onChange={(text) =>
                    setDeliveryContactEmail(text.target.value)
                  }
                />
                {deliveryContactEmailError && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                      marginTop: -13,
                    }}
                  >
                    {deliveryContactEmailError}
                  </div>
                ) : null}
              </Col>
            </Row>

            <div className="modal_footer">
              <Button
                className="btn-primaryAll btnPrimaryoutline me-3"
                onClick={props.onHide}
              >
                Cancel
              </Button>

              <Button
                style={{ width: 195 }}
                variant="primary"
                className="btn-primaryAll btnPrimary"
                onClick={() => onSubmit(props)}
                disabled={submit_loading}
              >
                {submit_loading ? <Loader /> : "Save informations"}
              </Button>
            </div>
          </React.Fragment>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default EditQouteModal;
