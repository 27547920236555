import React from "react";
import { Image } from "react-bootstrap";

const BreadCrumbHeader = (props) => {
  return (
    <div className="braedCrumb">
      {
        props.svg && <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path
          d="M11.1464 10.6516C11.1189 10.6516 11.1005 10.6516 11.073 10.6516C11.0272 10.6424 10.963 10.6424 10.908 10.6516C8.24969 10.5691 6.24219 8.47909 6.24219 5.90325C6.24219 3.28159 8.37802 1.14575 10.9997 1.14575C13.6214 1.14575 15.7572 3.28159 15.7572 5.90325C15.748 8.47909 13.7314 10.5691 11.1739 10.6516C11.1647 10.6516 11.1555 10.6516 11.1464 10.6516ZM10.9997 2.52075C9.13885 2.52075 7.61719 4.04242 7.61719 5.90325C7.61719 7.73659 9.04719 9.21242 10.8714 9.27659C10.9172 9.26742 11.0455 9.26742 11.1647 9.27659C12.9614 9.19409 14.373 7.71825 14.3822 5.90325C14.3822 4.04242 12.8605 2.52075 10.9997 2.52075Z"
          fill="#4f5b92"
        />
        <path
          d="M11.156 20.6709C9.3593 20.6709 7.55346 20.2126 6.18763 19.2959C4.91346 18.4526 4.2168 17.2976 4.2168 16.0417C4.2168 14.7859 4.91346 13.6217 6.18763 12.7692C8.93763 10.9451 13.3926 10.9451 16.1243 12.7692C17.3893 13.6126 18.0951 14.7676 18.0951 16.0234C18.0951 17.2792 17.3985 18.4434 16.1243 19.2959C14.7493 20.2126 12.9526 20.6709 11.156 20.6709ZM6.94846 13.9242C6.06846 14.5109 5.5918 15.2626 5.5918 16.0509C5.5918 16.8301 6.07763 17.5817 6.94846 18.1592C9.23096 19.6901 13.081 19.6901 15.3635 18.1592C16.2435 17.5726 16.7201 16.8209 16.7201 16.0326C16.7201 15.2534 16.2343 14.5017 15.3635 13.9242C13.081 12.4026 9.23096 12.4026 6.94846 13.9242Z"
          fill="#4f5b92"
        />
      </svg>
      }
      <Image className="tIcon" src={props.image} alt="" />
      <h3>{props.title}</h3>
    </div>
  );
};

export default BreadCrumbHeader;
