import React, { useState, memo, useCallback, useMemo, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  DirectionsService,
  Polyline,
} from "@react-google-maps/api";

import trackicon1 from "../../component/images/starting_mark.svg";
import trackicon2 from "../../component/images/current_location_marker.svg";
import trackicon3 from "../../component/images/delivery_marker.svg";
import Loader from "./Loader";

const center = {
  lat: 0,
  lng: -180,
};

function MapComponent({ location_details, mapContainerStyle }) {
  const [loading, setLoading] = useState(true);
  const [routeOne, setRouteOne] = useState(null);
  const [routeTwo, setRouteTwo] = useState(null);

  const { loading_point, transit_point, delivery_point } = location_details;

  const directionsServiceOptionsOne = useMemo(
    () => ({
      origin: `${loading_point.lat},${loading_point.lng}`,
      destination:
        transit_point.lat && transit_point.lng
          ? `${transit_point.lat},${transit_point.lng}`
          : `${delivery_point.lat},${delivery_point.lng}`,
      travelMode: "DRIVING",
    }),
    [loading_point, transit_point]
  );

  const directionsServiceOptionsTwo = useMemo(
    () => ({
      origin: `${transit_point.lat},${transit_point.lng}`,
      destination: `${delivery_point.lat},${delivery_point.lng}`,
      travelMode: "DRIVING",
    }),
    [transit_point, delivery_point]
  );

  const directionsCallbackOne = useCallback((result, status) => {
    if (status === "OK") {
      setRouteOne(result.routes[0].overview_path);
    } else {
      console.log("Route 1 response: ", result);
    }
  }, []);

  const directionsCallbackTwo = useCallback((result, status) => {
    if (status === "OK") {
      setRouteTwo(result.routes[0].overview_path);
    } else {
      console.log("Route 2 response: ", result);
    }
  }, []);

  const libraries = ["places"];
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyB3cLetAkii900cvYPIrvxILEJ6qUhpG4I",
    libraries,
  });

  useEffect(() => {
    if (isLoaded) {
      setLoading(false);
    } else if (loadError) {
      setLoading(true);
    } else {
      setLoading(true);
    }
  }, [isLoaded, loadError]);

  if (!isLoaded || loadError) return null;

  return (
    <React.Fragment>
      <GoogleMap
        mapContainerClassName="mapContainer"
        id="direction-example"
        mapContainerStyle={mapContainerStyle}
        zoom={6}
        center={loading_point || center}
      >
        {loading && (
          <div className="map_loader_container">
            <Loader size={80} color={"#F7CE6C"} />
          </div>
        )}

        {/* Directions Services */}
        <DirectionsService
          options={directionsServiceOptionsOne}
          callback={directionsCallbackOne}
        />
        <DirectionsService
          options={directionsServiceOptionsTwo}
          callback={directionsCallbackTwo}
        />
        {/* Drawing the polylines */}
        {routeOne && (
          <Polyline
            path={routeOne}
            options={{
              strokeColor: "#219653", // Red color for the first segment
              strokeOpacity: 1,
              strokeWeight: 4,
            }}
          />
        )}
        {routeTwo && (
          <Polyline
            path={routeTwo}
            options={{
              strokeColor: "#AAA", // Blue color for the second segment
              strokeOpacity: 1,
              strokeWeight: 4,
            }}
          />
        )}

        {/* Markers */}
        {loading_point && (
          <Marker
            key={0}
            title="Loading point"
            position={{
              lat: Number(loading_point.lat),
              lng: Number(loading_point.lng),
            }}
            icon={{
              url: trackicon1,
              scaledSize: new window.google.maps.Size(40, 40),
            }}
          />
        )}

        {transit_point && (
          <Marker
            title="Transit point"
            key={2}
            position={{
              lat: Number(transit_point.lat),
              lng: Number(transit_point.lng),
            }}
            icon={{
              url: trackicon2,
              scaledSize: new window.google.maps.Size(40, 40),
            }}
          />
        )}

        {delivery_point && (
          <Marker
            key={1}
            title="Delivery point"
            position={{
              lat: Number(delivery_point.lat),
              lng: Number(delivery_point.lng),
            }}
            icon={trackicon3}
          />
        )}
      </GoogleMap>
    </React.Fragment>
  );
}

export default memo(MapComponent);

// OLD CODE
// import React, { useState, memo, useCallback, useMemo, useEffect } from "react";

// import {
//   GoogleMap,
//   useLoadScript,
//   Marker,
//   DirectionsRenderer,
//   DirectionsService,
// } from "@react-google-maps/api";

// import trackicon1 from "../../component/images/starting_mark.svg";
// import trackicon2 from "../../component/images/current_location_marker.svg";
// import trackicon3 from "../../component/images/delivery_marker.svg";
// import Loader from "./Loader";

// const center = {
//   lat: 0,
//   lng: -180,
// };

// function MapComponent({ location_details, mapContainerStyle }) {
//   const [loading, setLoading] = useState(true);
//   const [response, setResponse] = useState(null);
//   const { loading_point, transit_point, delivery_point } = location_details;

//   const directionsFormValue = {
//     origin: `${loading_point.lat},${loading_point.lng}`, // LAT ,LONG  ("31.6798,76.1175")
//     destination: `${delivery_point.lat},${delivery_point.lng}`, // LAT ,LONG
//     travelMode: "DRIVING", // DRIVING  or BICYCLING or TRANSIT or WALKING
//     currentLocation: `${transit_point.lat},${transit_point.lng}`, // LAT ,LONG}
//   };

//   const directionsCallback = useCallback((result, status) => {
//     if (result !== null) {
//       if (status === "OK") {
//         setResponse(result);
//       } else {
//         console.log("response: ", result);
//       }
//     }
//   }, []);

//   const directionsServiceOptions = useMemo(() => {
//     const direction_data = {
//       destination: directionsFormValue.destination,
//       origin: directionsFormValue.origin,
//       travelMode: directionsFormValue.travelMode,
//     };
//     const transit_locations = directionsFormValue.currentLocation;
//     const isTransit_locations = transit_locations.lat && transit_locations.lng;
//     if (isTransit_locations) {
//       direction_data.waypoints = [
//         { location: directionsFormValue.currentLocation },
//       ];
//     }
//     return direction_data;
//   }, [
//     directionsFormValue.origin,
//     directionsFormValue.destination,
//     directionsFormValue.travelMode,
//     directionsFormValue.currentLocation,
//   ]);

//   const directionsResult = useMemo(() => {
//     return {
//       directions: response,
//       suppressMarkers: true,
//     };
//   }, [response]);

//   const libraries = ["places"];

//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: "AIzaSyB3cLetAkii900cvYPIrvxILEJ6qUhpG4I",
//     libraries,
//   });
//   useEffect(() => {
//     if (isLoaded) {
//       setLoading(false);
//     } else if (loadError) {
//       setLoading(true);
//     } else {
//       setLoading(true);
//     }
//   }, [isLoaded, loadError]);
//   if (!isLoaded) return;
//   if (loadError) return;

//   return (
//     <React.Fragment>
//       <GoogleMap
//         mapContainerClassName="mapContainer"
//         id="direction-example"
//         mapContainerStyle={mapContainerStyle}
//         zoom={2}
//         center={center}
//       >
//         {loading && (
//           <view className="map_loader_container">
//             <Loader size={80} color={"#F7CE6C"} />
//           </view>
//         )}
//         {directionsFormValue.destination !== "" &&
//           directionsFormValue.origin !== "" && (
//             <DirectionsService
//               options={directionsServiceOptions}
//               callback={directionsCallback}
//             />
//           )}
//         {directionsResult.directions && (
//           <DirectionsRenderer options={directionsResult} />
//         )}

//         {directionsFormValue.origin && (
//           <Marker
//             key={0}
//             title="Loading point"
//             position={{
//               lat: Number(loading_point.lat),
//               lng: Number(loading_point.lng),
//             }}
//             icon={{
//               url: trackicon1,
//               scaledSize: new window.google.maps.Size(40, 40),
//             }}
//           />
//         )}
//         {true && (
//           <Marker
//             title="Transit point"
//             key={2}
//             position={{
//               lat: Number(transit_point.lat),
//               lng: Number(transit_point.lng),
//             }}
//             icon={trackicon2}
//           />
//         )}

//         {directionsFormValue.destination && (
//           <Marker
//             key={1}
//             title="Delivery point"
//             position={{
//               lat: Number(delivery_point.lat),
//               lng: Number(delivery_point.lng),
//             }}
//             icon={trackicon3}
//           />
//         )}
//       </GoogleMap>
//     </React.Fragment>
//   );
// }

// export default memo(MapComponent);
